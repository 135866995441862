import {
  CREATE_TRANSACTION_REQUESTED,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_ERRORED,
  GET_TRANSACTION_REQUESTED,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_ERRORED,
  GET_TRANSACTION_DETAILS_REQUESTED,
  GET_TRANSACTION_DETAILS_SUCCESS,
  GET_TRANSACTION_DETAILS_ERRORED,
  UPDATE_TRANSACTION_REQUESTED,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_ERRORED,
  DELETE_TRANSACTION_REQUESTED,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_ERRORED,
  RESET_TRANSACTION,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_transaction_requested = () => ({
  type: CREATE_TRANSACTION_REQUESTED,
});

export const create_transaction_success = () => ({
  type: CREATE_TRANSACTION_SUCCESS,
});

export const create_transaction_errored = (error) => ({
  type: CREATE_TRANSACTION_ERRORED,
  error,
});

export const get_transaction_requested = () => ({
  type: GET_TRANSACTION_REQUESTED,
});

export const get_transaction_success = (payload) => ({
  type: GET_TRANSACTION_SUCCESS,
  payload,
});

export const get_transaction_errored = (error) => ({
  type: GET_TRANSACTION_ERRORED,
  error,
});

export const get_transaction_details_requested = () => ({
  type: GET_TRANSACTION_DETAILS_REQUESTED,
});

export const get_transaction_details_success = (payload) => ({
  type: GET_TRANSACTION_DETAILS_SUCCESS,
  payload,
});

export const get_transaction_details_errored = (error) => ({
  type: GET_TRANSACTION_DETAILS_ERRORED,
  error,
});

export const update_transaction_requested = () => ({
  type: UPDATE_TRANSACTION_REQUESTED,
});

export const update_transaction_success = (payload) => ({
  type: UPDATE_TRANSACTION_SUCCESS,
  payload,
});

export const update_transaction_errored = (error) => ({
  type: UPDATE_TRANSACTION_ERRORED,
  error,
});
export const delete_transaction_requested = () => ({
  type: DELETE_TRANSACTION_REQUESTED,
});

export const delete_transaction_success = (payload) => ({
  type: DELETE_TRANSACTION_SUCCESS,
  payload,
});

export const delete_transaction_errored = (error) => ({
  type: DELETE_TRANSACTION_ERRORED,
  error,
});

export const reset_transaction = () => ({
  type: RESET_TRANSACTION,
});

export const create_transaction = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_transaction_requested());
  console.log(`Create transaction`);
  console.log("activeOuid", activeOuid);
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/transactions`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("transaction created successfully");
        dispatch(showSnackbar("transaction created successfully", "success"));
        dispatch(create_transaction_success(response));
        console.log("transaction-response", response);
        if (response.data.type === "credit") {
          dispatch(get_transaction("?type=credit", activeOuid));
        } else if (response.data.type === "debit") {
          dispatch(get_transaction("?type=debit", activeOuid));
        }
      })
      .catch((err) => {
        console.log("transaction creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("transaction creation Failed", "error"));
        dispatch(create_transaction_errored(err));
      });
  }
  return null;
};

export const get_transaction = (query, activeOuid) => async (dispatch) => {
  dispatch(get_transaction_requested());
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`accountsApiEndpoint`, accountsApiEndpoint);
  console.log(`credentials`, credentials);

  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/transactions${query}`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get transaction Success", response.data);
        dispatch(get_transaction_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get transaction Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get transaction Failed", "error"));
        }
        dispatch(get_transaction_errored(err));
      });
  }
  return null;
};

export const get_transaction_details =
  (transactionId, activeOuid) => async (dispatch) => {
    dispatch(get_transaction_details_requested());
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    console.log(`accountsApiEndpoint`, accountsApiEndpoint);
    console.log(`credentials`, credentials);

    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/transactions/id/${transactionId}`;

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get transaction details Success", response.data);
          dispatch(get_transaction_details_success(response.data));
        })
        .catch((err) => {
          console.log("Get transaction details Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get transaction details Failed", "error"));
          }
          dispatch(get_transaction_details_errored(err));
        });
    }
    return null;
  };

export const update_transaction =
  (payload, activeOuid, meetingId) => async (dispatch) => {
    dispatch(update_transaction_requested());
    console.log(`update_transaction`);

    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/transactions/${meetingId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("transaction Updation Success");
          dispatch(showSnackbar("transaction Updated Successfully", "success"));
          dispatch(update_transaction_success());
        })
        .catch((err) => {
          console.log("transaction updation Failed");
          dispatch(showSnackbar("transaction updation Failed", "error"));
          dispatch(update_transaction_errored(err));
        });
    }
    return null;
  };

export const delete_transaction =
  (activeOuid, meetingId) => async (dispatch) => {
    dispatch(delete_transaction_requested());
    console.log(`delete_note`);
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/transactions/id/${meetingId}`;
      const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Delete transaction Success");
          dispatch(showSnackbar("transaction deleted successfully", "success"));
          dispatch(delete_transaction_success());
          dispatch(get_transaction("", activeOuid));
        })
        .catch((err) => {
          if (err.response) {
            console.log(
              "Delete transaction Failed :",
              JSON.stringify(err.response.data)
            );
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Delete transaction", "error"));
          }
          dispatch(delete_transaction_errored(err));
        });
    }
    return null;
  };
