import {
  CREATE_SALES_INVOICE_REQUESTED,
  CREATE_SALES_INVOICE_SUCCESS,
  CREATE_SALES_INVOICE_ERRORED,
  GET_SALES_INVOICE_REQUESTED,
  GET_SALES_INVOICE_SUCCESS,
  GET_SALES_INVOICE_ERRORED,
  GET_SALES_INVOICE_DETAILS_REQUESTED,
  GET_SALES_INVOICE_DETAILS_SUCCESS,
  GET_SALES_INVOICE_DETAILS_ERRORED,
  UPDATE_SALES_INVOICE_REQUESTED,
  UPDATE_SALES_INVOICE_SUCCESS,
  UPDATE_SALES_INVOICE_ERRORED,
  DELETE_SALES_INVOICE_REQUESTED,
  DELETE_SALES_INVOICE_SUCCESS,
  DELETE_SALES_INVOICE_ERRORED,
  RESET_SALES_INVOICE,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  salesInvoice: [],
  salesInvoiceDetails: {},
  error: {},
};

export function salesInvoice(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_SALES_INVOICE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_SALES_INVOICE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_SALES_INVOICE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_SALES_INVOICE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_SALES_INVOICE_SUCCESS:
      return {
        ...state,
        salesInvoice: payload,
        showGlobalLoader: false,
      };

    case GET_SALES_INVOICE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_SALES_INVOICE_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_SALES_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        salesInvoiceDetails: payload,
      };

    case GET_SALES_INVOICE_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_SALES_INVOICE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_SALES_INVOICE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        salesInvoiceDetails: payload,
      };

    case UPDATE_SALES_INVOICE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_SALES_INVOICE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_SALES_INVOICE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_SALES_INVOICE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case RESET_SALES_INVOICE:
      return {
        ...state,
        showGlobalLoader: false,
        salesInvoice: [],
      };

    default:
      return state;
  }
}
