import React, { useEffect } from "react";
import InvoiceCard from "./common/InvoiceCard";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { get_purchase_return } from "./actions/action-purchaseReturn";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from "../../../reducers";
import AddIcon from "@material-ui/icons/Add";
import PurchaseReturnCard from "./common/PurchaseReturnCard";

const store = configureStore();

function PurchaseReturnList() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { purchaseReturn, showGlobalLoader } = useSelector(
    (state) => state.purchaseReturn
  );

  function renderList(purchaseReturn) {
    if (purchaseReturn) {
      return purchaseReturn.map((item) => (
        <PurchaseReturnCard key={item.id} item={item} />
      ));
    } else {
      return <>No purchase Return found</>;
    }
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_purchase_return(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Purchase Returns " />
      <div className=" HrmAppPage container--narrow mt FBAppPage">
        {showGlobalLoader ? <Loader /> : renderList(purchaseReturn)}

        <div className="AppFloatingButtonWrapper t-r">
          <button
            className="round_btn bg_blue "
            onClick={() =>
              history.push(`/${activeOuid}/create-purchase-return`)
            }
          >
            <AddIcon />
          </button>
        </div>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}

export default PurchaseReturnList;
