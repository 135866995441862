import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import { Button, Collapse, InputAdornment, MenuItem } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { history } from "../../..//reducers";
import configureStore from "../../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Drawer, TextField } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { get_parties } from "./actions/action-parties";
import { get_items } from "./actions/action-items";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { create_transaction } from "./actions/action-transactions";
import {
  get_purchase_invoice,
  reset_purchase_invoice,
} from "./actions/action-purchaseInvoice";
import { convertToPaisa, convertToRs } from "./common/moneyConverter";
import CurrencyTextField from "@unicef/material-ui-currency-textfield/dist/CurrencyTextField";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  totalInput: {
    padding: "10px 14px",
  },
  Select: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  formControl: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  discountPriceTextField: {
    color: "#31d0aa",
    fontWeight: "600",
    fontSize: "12px",
    width: "70%",
    marginLeft: "auto",
  },
  button: {
    fontWeight: 700,
    fontSize: "16px",
    textAlign: "center",
    color: "#ffffff",
    boxShadow: "0px 6px 2px -4px rgba(14, 14, 44, 0.1)",
    borderRadius: "43px",
  },
  toggleWrap: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "30px",
  },
  toggle: {
    marginRight: "10px",
    padding: "8px 10px",
    border: "2px solid #9D00C6 !important",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "100px !important",
    color: "#9D00C6",
    textAlign: "center",
    background: "#fff",
    minWidth: "80px",
    "&.Mui-selected": {
      backgroundColor: "#9d00c6 !important",
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#9d00c6 !important",
      color: "#fff",
    },
  },
  listItem: {
    alignItems: "flex-start",
  },
  check: {
    color: "#D9D9D9",

    "&.Mui-checked ": {
      color: "#9d00c6",
    },
  },
}));

function CreatePaymentOut() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeOuid, activeOuidChain } = store.getState().permission;
  const [paymentMode, setPaymentMode] = React.useState("cash");

  const { parties, showGlobalLoader } = useSelector((state) => state.parties);
  const { purchaseInvoice } = useSelector((state) => state.purchaseInvoice);
  const [checked, setChecked] = React.useState([]);

  const [state, setState] = React.useState({
    totalAmount: `${0}`,
    mode: paymentMode,
    date: `${new Date()}`,
    type: "debit",
    party: {
      id: "",
      name: "",
      type: "",
      category: "",
      businessInfo: {
        gstNumber: "",
        panNumber: "",
        billingAddress: "",
      },
    },
    related_PRNs: [],
  });

  //Party name select
  const onSelectChange = (event) => {
    console.log("event", event);
    const { myValue } = event.currentTarget.dataset;
    console.log("Menu click event", myValue);

    setState({
      ...state,
      party: {
        ...state.party,
        id: event.target.value,
        name: myValue,
      },
    });

    dispatch(
      get_purchase_invoice(
        `?partyId=${event.target.value}&status=PENDING`,
        activeOuid
      )
    );
    console.log("state", state);
  };

  //onchange for state
  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
    console.log("state", state);
  };

  //payment mode toggle

  const handlePaymentMode = (event, newPaymentMode) => {
    setPaymentMode(newPaymentMode);
    setState({ ...state, mode: newPaymentMode });
  };

  //Rendering parties for the select field

  const renderParties = (parties) => {
    if (parties) {
      return parties.map((item) => (
        <MenuItem
          className={classes.MenuItem}
          key={item.id}
          value={item.id}
          data-my-value={item.name}
        >
          {item.name}
        </MenuItem>
      ));
    }
  };

  //Rendering invoice check box list

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);

    let newArray = [
      ...state.related_PRNs,
      `prn:accounting:purchaseinvoice:${value.id}`,
    ];

    if (
      state.related_PRNs.includes(`prn:accounting:salesinvoice:${value.id}`)
    ) {
      newArray = newArray.filter(
        (day) => day !== `prn:accounting:salesinvoice:${value.id}`
      );
    }
    console.log("filterArray", newArray);

    setState({
      ...state,
      related_PRNs: newArray,
    });

    console.log("state", state);
  };

  //Rendering invoice check box item  list
  //   const labelId = `checkbox-list-label-${value}`;

  function renderInvoiceList(purchaseInvoice) {
    if (purchaseInvoice) {
      return purchaseInvoice.map((value) => (
        <ListItem
          key={value}
          role={undefined}
          dense
          button
          onClick={handleToggle(value)}
          className={classes.listItem}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked.indexOf(value) !== -1}
              tabIndex={-1}
              disableRipple
              //   inputProps={{ "aria-labelledby": labelId }}

              className={classes.check}
            />
          </ListItemIcon>
          <ListItemText>
            <ul className="pay_receive_inv_list_details_wrap">
              <li className="pay_receive_inv_list_details_item">
                <div className="pay_receive_inv_list_details_num ">
                  {value.idNumber}
                </div>
                <div className="pay_receive_inv_list_details_total_amount">
                  ₹{convertToRs(value.totalAmount)}
                </div>
              </li>
              <li className="pay_receive_inv_list_details_item">
                <div className="pay_receive_inv_list_details_date">
                  06 Jul 2022
                </div>
                <div className="pay_receive_inv_list_details_settled">
                  ₹ {value.payments ? renderSettledSum(value.payments) : 0} Paid
                </div>
              </li>
            </ul>
          </ListItemText>
        </ListItem>
      ));
    } else {
      return <>No invoice found</>;
    }
  }
  function renderSettledSum(payments) {
    let settledSum = 0;
    payments.map(
      ({ paidAmount }) =>
        (settledSum = settledSum + parseFloat(paidAmount ? paidAmount : 0))
    );
    console.log("settledSum", settledSum);

    return settledSum;
  }
  //Handle Submit

  const handleSubmit = () => {
    console.log("Handle submit");
    var payload = state;
    payload.totalAmount = convertToPaisa(payload.totalAmount);
    dispatch(create_transaction(state, activeOuid));
    history.push(`/${activeOuid}/payments-out`);
  };

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(reset_purchase_invoice());
      await dispatch(get_parties("", activeOuid));
      await dispatch(get_items("", activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Payment Out" />
      <div className="AppPage container--narrow mt FBAppPage">
        <div className=" w_box mb " style={{ paddingTop: "20px" }}>
          <TextField
            fullWidth
            className={classes.input}
            id=""
            select
            label="Party Name *"
            onChange={onSelectChange}
            variant="outlined"
            placeholder="Aswin Suresh"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div className="input_icon">
                    <AccountCircle />
                  </div>
                </InputAdornment>
              ),
            }}
          >
            {parties && renderParties(parties)}
          </TextField>
        </div>
        <div className="w_box mb" style={{ paddingTop: "20px" }}>
          {/* <TextField
            fullWidth
            className={classes.input}
            id="totalAmount"
            label="Total Amount"
            onChange={handleChange}
            variant="outlined"
            placeholder="0.0"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div className="input_icon">₹</div>
                </InputAdornment>
              ),
            }}
          /> */}
          <CurrencyTextField
            id="totalAmount"
            label="Total Amount"
            variant="outlined"
            fullWidth
            className={classes.input}
            // value={value}
            currencySymbol="₹"
            minimumValue="0"
            outputFormat="string"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={handleChange}
          />
        </div>
        <div className="w_box mb">
          <div>Invoive number</div>
          <span>Settle outstanding Invoices with above payment</span>
          <List>
            {purchaseInvoice ? (
              renderInvoiceList(purchaseInvoice)
            ) : (
              <>No pending invoice for this party</>
            )}
          </List>
        </div>
        <div className="w_box mb">
          <div className="purchase_invoice_payment_mode_wrap mt">
            <div className="purchase_invoice_payment_label">Payment Mode</div>

            <ToggleButtonGroup
              color="primary"
              value={paymentMode}
              exclusive
              onChange={handlePaymentMode}
              className={classes.toggleWrap}
            >
              <ToggleButton
                className={classes.toggle}
                value="cash"
                aria-label="cash"
              >
                cash
              </ToggleButton>
              <ToggleButton
                className={classes.toggle}
                value="cheque"
                aria-label="cheque"
              >
                cheque
              </ToggleButton>
              <ToggleButton
                className={classes.toggle}
                value="Online"
                aria-label="Online"
              >
                Online
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>

        <div className="AppFloatingButtonWrapper t-c">
          <div className="create_btns_wrap" style={{ gap: "5px" }}>
            <Button
              className={classes.button}
              style={{ background: "#4b4ded" }}
              fullWidth
              color="primary"
              onClick={() => handleSubmit()}
              disabled={state.totalAmount < 1}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CreatePaymentOut;
