import {
  CREATE_DEBIT_NOTE_REQUESTED,
  CREATE_DEBIT_NOTE_SUCCESS,
  CREATE_DEBIT_NOTE_ERRORED,
  GET_DEBIT_NOTE_REQUESTED,
  GET_DEBIT_NOTE_SUCCESS,
  GET_DEBIT_NOTE_ERRORED,
  GET_DEBIT_NOTE_DETAILS_REQUESTED,
  GET_DEBIT_NOTE_DETAILS_SUCCESS,
  GET_DEBIT_NOTE_DETAILS_ERRORED,
  UPDATE_DEBIT_NOTE_REQUESTED,
  UPDATE_DEBIT_NOTE_SUCCESS,
  UPDATE_DEBIT_NOTE_ERRORED,
  DELETE_DEBIT_NOTE_REQUESTED,
  DELETE_DEBIT_NOTE_SUCCESS,
  DELETE_DEBIT_NOTE_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  debitNote: [],
  debitNoteDetails: {},
  error: {},
};

export function debitNote(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_DEBIT_NOTE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_DEBIT_NOTE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_DEBIT_NOTE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        debitNote: payload,
        showGlobalLoader: false,
      };

    case GET_DEBIT_NOTE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_DEBIT_NOTE_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_DEBIT_NOTE_DETAILS_SUCCESS:
      return {
        ...state,
        debitNoteDetails: payload,
        showGlobalLoader: false,
      };

    case GET_DEBIT_NOTE_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_DEBIT_NOTE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        debitNoteDetails: payload,
      };

    case UPDATE_DEBIT_NOTE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_DEBIT_NOTE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_DEBIT_NOTE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_DEBIT_NOTE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    default:
      return state;
  }
}
