import {
  CREATE_PURCHASE_ORDER_REQUESTED,
  CREATE_PURCHASE_ORDER_SUCCESS,
  CREATE_PURCHASE_ORDER_ERRORED,
  GET_PURCHASE_ORDER_REQUESTED,
  GET_PURCHASE_ORDER_SUCCESS,
  GET_PURCHASE_ORDER_ERRORED,
  GET_PURCHASE_ORDER_DETAILS_REQUESTED,
  GET_PURCHASE_ORDER_DETAILS_SUCCESS,
  GET_PURCHASE_ORDER_DETAILS_ERRORED,
  UPDATE_PURCHASE_ORDER_REQUESTED,
  UPDATE_PURCHASE_ORDER_SUCCESS,
  UPDATE_PURCHASE_ORDER_ERRORED,
  DELETE_PURCHASE_ORDER_REQUESTED,
  DELETE_PURCHASE_ORDER_SUCCESS,
  DELETE_PURCHASE_ORDER_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_purchase_order_requested = () => ({
  type: CREATE_PURCHASE_ORDER_REQUESTED,
});

export const create_purchase_order_success = () => ({
  type: CREATE_PURCHASE_ORDER_SUCCESS,
});

export const create_purchase_order_errored = (error) => ({
  type: CREATE_PURCHASE_ORDER_ERRORED,
  error,
});

export const get_purchase_order_requested = () => ({
  type: GET_PURCHASE_ORDER_REQUESTED,
});

export const get_purchase_order_success = (payload) => ({
  type: GET_PURCHASE_ORDER_SUCCESS,
  payload,
});

export const get_purchase_order_errored = (error) => ({
  type: GET_PURCHASE_ORDER_ERRORED,
  error,
});

export const get_purchase_order_details_requested = () => ({
  type: GET_PURCHASE_ORDER_DETAILS_REQUESTED,
});

export const get_purchase_order_details_success = (payload) => ({
  type: GET_PURCHASE_ORDER_DETAILS_SUCCESS,
  payload,
});

export const get_purchase_order_details_errored = (error) => ({
  type: GET_PURCHASE_ORDER_DETAILS_ERRORED,
  error,
});

export const update_purchase_order_requested = () => ({
  type: UPDATE_PURCHASE_ORDER_REQUESTED,
});

export const update_purchase_order_success = (payload) => ({
  type: UPDATE_PURCHASE_ORDER_SUCCESS,
  payload,
});

export const update_purchase_order_errored = (error) => ({
  type: UPDATE_PURCHASE_ORDER_ERRORED,
  error,
});
export const delete_purchase_order_requested = () => ({
  type: DELETE_PURCHASE_ORDER_REQUESTED,
});

export const delete_purchase_order_success = (payload) => ({
  type: DELETE_PURCHASE_ORDER_SUCCESS,
  payload,
});

export const delete_purchase_order_errored = (error) => ({
  type: DELETE_PURCHASE_ORDER_ERRORED,
  error,
});

export const create_purchase_order =
  (payload, activeOuid) => async (dispatch) => {
    dispatch(create_purchase_order_requested());
    console.log(`Create purchase order`);
    console.log("activeOuid", activeOuid);
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/purchaseorders`;
      const method = "post";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("purchase order created successfully");
          dispatch(
            showSnackbar("purchase order created successfully", "success")
          );
          dispatch(create_purchase_order_success(response));
          dispatch(get_purchase_order(activeOuid));
        })
        .catch((err) => {
          console.log("purchase order creation Failed");
          // console.error('ErrorLog : ', JSON.stringify(err));
          dispatch(showSnackbar("purchase order creation Failed", "error"));
          dispatch(create_purchase_order_errored(err));
        });
    }
    return null;
  };

export const get_purchase_order = (activeOuid) => async (dispatch) => {
  dispatch(get_purchase_order_requested());
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`accountsApiEndpoint`, accountsApiEndpoint);
  console.log(`credentials`, credentials);

  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/purchaseorders`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get purchase order Success", response.data);
        dispatch(get_purchase_order_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get purchase order Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get purchase order Failed", "error"));
        }
        dispatch(get_purchase_order_errored(err));
      });
  }
  return null;
};

export const get_purchase_order_details =
  (purchaseOrderId, activeOuid) => async (dispatch) => {
    dispatch(get_purchase_order_details_requested());
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    console.log(`accountsApiEndpoint`, accountsApiEndpoint);
    console.log(`credentials`, credentials);

    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/purchaseorders/id/${purchaseOrderId}`;

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get purchase order Success", response.data);
          dispatch(get_purchase_order_details_success(response.data));
        })
        .catch((err) => {
          console.log("Get purchase order Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get purchase order Failed", "error"));
          }
          dispatch(get_purchase_order_details_errored(err));
        });
    }
    return null;
  };

export const update_purchase_order =
  (payload, purchaseOrderId, activeOuid) => async (dispatch) => {
    dispatch(update_purchase_order_requested());
    console.log(`update_purchase_order`);

    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/purchaseorders/id/${purchaseOrderId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("purchase order Updation Success");
          dispatch(
            showSnackbar("purchase order Updated Successfully", "success")
          );
          dispatch(update_purchase_order_success());
        })
        .catch((err) => {
          console.log("purchase order updation Failed");
          dispatch(showSnackbar("purchase order updation Failed", "error"));
          dispatch(update_purchase_order_errored(err));
        });
    }
    return null;
  };

export const delete_purchase_order =
  (activeOuid, meetingId) => async (dispatch) => {
    dispatch(delete_purchase_order_requested());
    console.log(`delete_note`);
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/purchaseorders/id/${meetingId}`;
      const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Delete purchase order Success");
          dispatch(
            showSnackbar("purchase order deleted successfully", "success")
          );
          dispatch(delete_purchase_order_success());
          dispatch(get_purchase_order("", activeOuid));
        })
        .catch((err) => {
          if (err.response) {
            console.log(
              "Delete purchase order Failed :",
              JSON.stringify(err.response.data)
            );
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Delete purchase order", "error"));
          }
          dispatch(delete_purchase_order_errored(err));
        });
    }
    return null;
  };
