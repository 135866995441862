import React, { useEffect } from "react";
import HeaderInner from "./HeaderInner";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import Loader from "../../common/Loader";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import configureStore from "../../../store/configureStore";
import PartiesCard from "./common/PartiesCard";
import Footer from "./Footer";
import { history } from "../../../reducers";
import { get_parties } from "./actions/action-parties";
import { useDispatch, useSelector } from "react-redux";
import CommonSnackBar from "../../common/CommonSnackBar";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
  },
  attendanceButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  tabs: {
    background: "#fff",
    boxShadow: "none",
  },
  tab: {
    borderBottom: "1.98502px solid #4B4DED",
  },
  tabpanel: {
    padding: "0px",
  },
  tabHead: {
    boxShadow: "none",
    borderTop: "1px solid #eee",
  },
}));

const store = configureStore();

export default function Parties() {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;

  const [value, setValue] = React.useState(0);
  const { parties, showGlobalLoader } = useSelector((state) => state.parties);
  console.log("parties", parties);

  function renderAllPartiesList(parties) {
    console.log("partieslist", parties);
    if (parties) {
      return parties.map((item) => <PartiesCard key={item.id} item={item} />);
    } else {
      return <>No parties found</>;
    }
  }

  function renderToCollectPartiesList(parties) {
    if (parties) {
      return parties
        .filter((item) => item.type === "Customer")
        .map((item) => <PartiesCard key={item.id} item={item} />);
    } else {
      return <>No parties found</>;
    }
  }

  function renderToPayPartiesList(parties) {
    if (parties) {
      return parties
        .filter((item) => item.type === "Supplier")
        .map((item) => <PartiesCard key={item.id} item={item} />);
    } else {
      return <>No parties found</>;
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_parties("", activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Parties " />
      <div className=" HrmAppPage container--narrow mt FBAppPage">
        <AppBar position="static" color="default" className={classes.tabHead}>
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab className={classes.tab} label="All" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Customer" {...a11yProps(1)} />
            <Tab className={classes.tab} label="Supplier" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            className={classes.tabpanel}
          >
            {/* {showGlobalLoader ? (
              <Loader />
            ) : (
              <>
                {leads.length !== 0
                  ? renderLeadsList(leads)
                  : "No leads found..."}
              </>
            )} */}
            {renderAllPartiesList(parties)}
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            dir={theme.direction}
            className={classes.tabpanel}
          >
            {/* {showGlobalLoader ? (
              <Loader />
            ) : (
              <>
                {leads.length !== 0
                  ? renderCustomerList(leads)
                  : "No contact found..."}
              </>
            )} */}
            {renderToCollectPartiesList(parties)}
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            dir={theme.direction}
            className={classes.tabpanel}
          >
            {/* {showGlobalLoader ? (
              <Loader />
            ) : (
              <>
                {leads.length !== 0
                  ? renderLostList(leads)
                  : "No contact found..."}
              </>
            )} */}
            {renderToPayPartiesList(parties)}
          </TabPanel>
        </SwipeableViews>
        <div className="AppFloatingButtonWrapper t-c">
          <button
            className="btn bg_light_green"
            style={{ width: "80%", margin: "0 auto" }}
            onClick={() => history.push(`/${activeOuid}/create-party`)}
          >
            add new party
          </button>
        </div>
      </div>

      <Footer />
      <CommonSnackBar />
    </div>
  );
}
