import {
  CREATE_CREDIT_NOTE_REQUESTED,
  CREATE_CREDIT_NOTE_SUCCESS,
  CREATE_CREDIT_NOTE_ERRORED,
  GET_CREDIT_NOTE_REQUESTED,
  GET_CREDIT_NOTE_SUCCESS,
  GET_CREDIT_NOTE_ERRORED,
  GET_CREDIT_NOTE_DETAILS_REQUESTED,
  GET_CREDIT_NOTE_DETAILS_SUCCESS,
  GET_CREDIT_NOTE_DETAILS_ERRORED,
  UPDATE_CREDIT_NOTE_REQUESTED,
  UPDATE_CREDIT_NOTE_SUCCESS,
  UPDATE_CREDIT_NOTE_ERRORED,
  DELETE_CREDIT_NOTE_REQUESTED,
  DELETE_CREDIT_NOTE_SUCCESS,
  DELETE_CREDIT_NOTE_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_credit_note_requested = () => ({
  type: CREATE_CREDIT_NOTE_REQUESTED,
});

export const create_credit_note_success = () => ({
  type: CREATE_CREDIT_NOTE_SUCCESS,
});

export const create_credit_note_errored = (error) => ({
  type: CREATE_CREDIT_NOTE_ERRORED,
  error,
});

export const get_credit_note_requested = () => ({
  type: GET_CREDIT_NOTE_REQUESTED,
});

export const get_credit_note_success = (payload) => ({
  type: GET_CREDIT_NOTE_SUCCESS,
  payload,
});

export const get_credit_note_errored = (error) => ({
  type: GET_CREDIT_NOTE_ERRORED,
  error,
});

export const get_credit_note_details_requested = () => ({
  type: GET_CREDIT_NOTE_DETAILS_REQUESTED,
});

export const get_credit_note_details_success = (payload) => ({
  type: GET_CREDIT_NOTE_DETAILS_SUCCESS,
  payload,
});

export const get_credit_note_details_errored = (error) => ({
  type: GET_CREDIT_NOTE_DETAILS_ERRORED,
  error,
});

export const update_credit_note_requested = () => ({
  type: UPDATE_CREDIT_NOTE_REQUESTED,
});

export const update_credit_note_success = (payload) => ({
  type: UPDATE_CREDIT_NOTE_SUCCESS,
  payload,
});

export const update_credit_note_errored = (error) => ({
  type: UPDATE_CREDIT_NOTE_ERRORED,
  error,
});
export const delete_credit_note_requested = () => ({
  type: DELETE_CREDIT_NOTE_REQUESTED,
});

export const delete_credit_note_success = (payload) => ({
  type: DELETE_CREDIT_NOTE_SUCCESS,
  payload,
});

export const delete_credit_note_errored = (error) => ({
  type: DELETE_CREDIT_NOTE_ERRORED,
  error,
});

export const create_credit_note = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_credit_note_requested());
  console.log(`Create credit note`);
  console.log("activeOuid", activeOuid);
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/creditnotes`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("credit note created successfully");
        dispatch(showSnackbar("credit note created successfully", "success"));
        dispatch(create_credit_note_success(response));
        dispatch(get_credit_note(activeOuid));
      })
      .catch((err) => {
        console.log("credit note creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("credit note creation Failed", "error"));
        dispatch(create_credit_note_errored(err));
      });
  }
  return null;
};

export const get_credit_note = (activeOuid) => async (dispatch) => {
  dispatch(get_credit_note_requested());
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`accountsApiEndpoint`, accountsApiEndpoint);
  console.log(`credentials`, credentials);

  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/creditnotes`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get credit note Success", response.data);
        dispatch(get_credit_note_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get credit note Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get credit note Failed", "error"));
        }
        dispatch(get_credit_note_errored(err));
      });
  }
  return null;
};

export const get_credit_note_details =
  (creditId, activeOuid) => async (dispatch) => {
    dispatch(get_credit_note_details_requested());
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    console.log(`accountsApiEndpoint`, accountsApiEndpoint);
    console.log(`credentials`, credentials);

    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/creditnotes/id/${creditId}`;

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get credit note Success", response.data);
          dispatch(get_credit_note_details_success(response.data));
        })
        .catch((err) => {
          console.log("Get credit note Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get credit note Failed", "error"));
          }
          dispatch(get_credit_note_details_errored(err));
        });
    }
    return null;
  };

export const update_credit_note =
  (payload, creditId, activeOuid) => async (dispatch) => {
    dispatch(update_credit_note_requested());
    console.log(`update_credit_note`);

    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/creditnotes/id/${creditId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("credit note Updation Success");
          dispatch(showSnackbar("credit note Updated Successfully", "success"));
          dispatch(update_credit_note_success());
        })
        .catch((err) => {
          console.log("credit note updation Failed");
          dispatch(showSnackbar("credit note updation Failed", "error"));
          dispatch(update_credit_note_errored(err));
        });
    }
    return null;
  };

export const delete_credit_note =
  (activeOuid, meetingId) => async (dispatch) => {
    dispatch(delete_credit_note_requested());
    console.log(`delete_note`);
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/creditnotes/id/${meetingId}`;
      const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Delete credit note Success");
          dispatch(showSnackbar("credit note deleted successfully", "success"));
          dispatch(delete_credit_note_success());
          dispatch(get_credit_note("", activeOuid));
        })
        .catch((err) => {
          if (err.response) {
            console.log(
              "Delete credit_note Failed :",
              JSON.stringify(err.response.data)
            );
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Delete credit note", "error"));
          }
          dispatch(delete_credit_note_errored(err));
        });
    }
    return null;
  };
