import React, { useEffect } from "react";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { get_quotation } from "./actions/action-quotation";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from "../../../reducers";
import AddIcon from "@material-ui/icons/Add";
import QuotationCard from "./common/QuotationCard";

const store = configureStore();

function Quotations() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { quotations, showGlobalLoader } = useSelector(
    (state) => state.quotations
  );
  console.log("quotations", quotations);
  function renderList(quotations) {
    if (quotations) {
      return quotations.map((item) => (
        <QuotationCard key={item.id} item={item} />
      ));
    } else {
      return <>No quotations found</>;
    }
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_quotation(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Quotation " />
      <div className=" HrmAppPage container--narrow mt FBAppPage">
        {showGlobalLoader ? <Loader /> : renderList(quotations)}

        <div className="AppFloatingButtonWrapper t-r">
          <button
            className="round_btn bg_blue "
            onClick={() => history.push(`/${activeOuid}/create-quote`)}
          >
            <AddIcon />
          </button>
        </div>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}

export default Quotations;
