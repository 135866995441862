import React from "react";
import { useEffect } from "react";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import Bill from "./common/Bill";
import { history } from "../../../reducers";
import Loader from "../../common/Loader";
import { get_orgunit_details } from "../../../actions/action-orgunit";
import { get_credit_note_details } from "./actions/action-creditNote";
import NewBill from "./common/NewBill";

const store = configureStore();

function CreditNoteDetails() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { creditNoteDetails, showGlobalLoader } = useSelector(
    (state) => state.creditNote
  );
  const { OrgunitDetails } = useSelector((state) => state.orgunit);

  useEffect(() => {
    let creditId = window.location.pathname.split("/");
    creditId = creditId[3];
    console.log("creditId", creditId);

    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_credit_note_details(creditId, activeOuid));
      await dispatch(get_orgunit_details(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Credit Note  Details" />
      <div className="AppPage container--narrow mt FBAppPage">
        <button
          className="bill_edit_btn mb"
          onClick={() =>
            history.push(
              `/${activeOuid}/edit-credit-note/${creditNoteDetails.id}`
            )
          }
        >
          Edit
        </button>
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <>
            <NewBill
              billDetails={creditNoteDetails}
              companyDetails={OrgunitDetails}
            />
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default CreditNoteDetails;
