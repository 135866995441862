import React, { useEffect } from "react";
import InvoiceCard from "./common/InvoiceCard";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { get_purchase_invoice } from "./actions/action-purchaseInvoice";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from "../../../reducers";
import AddIcon from "@material-ui/icons/Add";
import PurchaseInvoiceCard from "./common/PurchaseInvoiceCard";

const store = configureStore();

function PurchaseInvoiceList() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { purchaseInvoice, showGlobalLoader } = useSelector(
    (state) => state.purchaseInvoice
  );

  function renderInvoiceList(purchaseInvoice) {
    console.log("purchaseInvoice", purchaseInvoice);
    if (purchaseInvoice) {
      return purchaseInvoice.map((item) => (
        <PurchaseInvoiceCard key={item.id} item={item} />
      ));
    } else {
      return <>No Purchase Invoice found</>;
    }
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_purchase_invoice("", activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Purchase Invoices " />
      <div className=" HrmAppPage container--narrow mt FBAppPage">
        {showGlobalLoader ? <Loader /> : renderInvoiceList(purchaseInvoice)}
      </div>
      <div className="AppFloatingButtonWrapper t-r">
        <button
          className="round_btn bg_blue "
          onClick={() => history.push(`/${activeOuid}/create-purchase-invoice`)}
        >
          <AddIcon />
        </button>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}

export default PurchaseInvoiceList;
