import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import configureStore from "../../../store/configureStore";
import { Switch, Route } from "react-router-dom";
import Home from "./Home";
import Parties from "./Parties";
import Items from "./Items";
import CreateInvoice from "./CreateInvoice";
import CreateItem from "./CreateItem";
import CreateParty from "./CreateParty";
import CreateSalesReturn from "./CreateSalesReturn";
import CreateCreditNote from "./CreateCreditNote";
import CreateQuote from "./CreateQuote";
import Expense from "./Expense";
import DeliveryChalan from "./DeliveryChalan";
import PerformaInvoice from "./PerformaInvoice";
import CounterPOS from "./CounterPOS";
import PurchaseItems from "./PurchaseItems";
import InvoiceList from "./InvoiceList";
import PurchaseInvoiceList from "./PurchaseInvoiceList";
import Quotations from "./Quotations";
import PurchaseOrderList from "./PurchaseOrderList";
import CreditNoteList from "./CreditNoteList";
import DebitNoteList from "./DebitNoteList";
import SalesReturnList from "./SalesReturnList";
import PurchaseReturnList from "./PurchaseReturnList";
import CreatePurchaseReturn from "./CreatePurchaseReturn";
import CreatePurchaseInvoice from "./CreatePurchaseInvoice";
import CreateDebitNote from "./CreateDebitNote";
import CreatePurchaseOrder from "./CreatePurchaseOrder";
import CreatePaymentReceived from "./CreatePaymentReceived";
import PaymentReceivedList from "./PaymentReceivedList";
import PaymentOutList from "./PaymentOutList";
import CreatePaymentOut from "./CreatePaymentOut";
import SalesInvoiceInner from "./SalesInvoiceInner";
import EditSalesInvoice from "./EditSalesInvoice";
import EditPurchaseInvoice from "./EditPurchaseInvoice";
import PurchaseInvoiceDetails from "./PurchaseInvoiceDetails";
import PaymentReceivedDetails from "./PaymentReceivedDetails";
import PaymentOutDetails from "./PaymentOutDetails";
import SalesReturnDetails from "./SalesReturnDetails";
import EditSalesReturn from "./EditSalesReturn";
import QuotationDetails from "./QuotationDetails";
import EditQuotation from "./EditQuotation";
import EditCreditNote from "./EditCreditNote";
import CreditNoteDetails from "./CreditNoteDetails";
import PurchaseReturnDetails from "./PurchaseReturnDetails";
import EditPurchaseReturn from "./EditPurchaseReturn";
import DebitNoteDetails from "./DebitNoteDetails";
import EditDebitNote from "./EditDebitNote";
import PurchaseOrderDetails from "./PurchaseOrderDetails";
import EditPurchaseOrder from "./EditPurchaseOrder";
import PartiesDetails from "./PartiesDetails";
import EditParties from "./EditParties";
import ItemDetails from "./ItemDetails";
import EditItem from "./EditItem";

const store = configureStore();

export default function AccountingApp() {
  const dispatch = useDispatch();
  const activeOuid = store.getState().permission;

  useEffect(() => {
    console.log("inuseEffect");
  }, [dispatch]);

  const { permissions } = store.getState().permission;
  return (
    <div className="AccountingApp">
      <Switch>
        <Route exact path="/" render={(props) => <Home />} />
        <Route exact path="/:id/parties" render={(props) => <Parties />} />
        <Route exact path="/:id/items" render={(props) => <Items />} />
        <Route
          exact
          path="/:id/create-invoice"
          render={(props) => <CreateInvoice />}
        />
        <Route
          exact
          path="/:id/create-payment-received"
          render={(props) => <CreatePaymentReceived />}
        />
        <Route
          exact
          path="/:id/create-item"
          render={(props) => <CreateItem />}
        />
        <Route
          exact
          path="/:id/create-party"
          render={(props) => <CreateParty />}
        />
        <Route
          exact
          path="/:id/create-sales-return"
          render={(props) => <CreateSalesReturn />}
        />
        <Route
          exact
          path="/:id/create-credit-note"
          render={(props) => <CreateCreditNote />}
        />
        <Route
          exact
          path="/:id/create-quote"
          render={(props) => <CreateQuote />}
        />
        <Route
          exact
          path="/:id/create-purchase-invoice"
          render={(props) => <CreatePurchaseInvoice />}
        />
        <Route
          exact
          path="/:id/purchase-items"
          render={(props) => <PurchaseItems />}
        />
        <Route
          exact
          path="/:id/create-payment-out"
          render={(props) => <CreatePaymentOut />}
        />
        <Route
          exact
          path="/:id/create-purchase-return"
          render={(props) => <CreatePurchaseReturn />}
        />
        <Route
          exact
          path="/:id/create-debit-note"
          render={(props) => <CreateDebitNote />}
        />
        <Route
          exact
          path="/:id/create-purchase-order"
          render={(props) => <CreatePurchaseOrder />}
        />
        <Route exact path="/:id/expense" render={(props) => <Expense />} />
        <Route
          exact
          path="/:id/delivery-chalan"
          render={(props) => <DeliveryChalan />}
        />
        <Route
          exact
          path="/:id/performa-invoice"
          render={(props) => <PerformaInvoice />}
        />
        <Route
          exact
          path="/:id/counter-pos"
          render={(props) => <CounterPOS />}
        />
        <Route
          exact
          path="/:id/sales-invoices"
          render={(props) => <InvoiceList />}
        />
        <Route
          exact
          path="/:id/purchase-invoices"
          render={(props) => <PurchaseInvoiceList />}
        />
        <Route
          exact
          path="/:id/quotations"
          render={(props) => <Quotations />}
        />
        <Route
          exact
          path="/:id/purchase-orders"
          render={(props) => <PurchaseOrderList />}
        />
        <Route
          exact
          path="/:id/credit-notes"
          render={(props) => <CreditNoteList />}
        />
        <Route
          exact
          path="/:id/debit-notes"
          render={(props) => <DebitNoteList />}
        />
        <Route
          exact
          path="/:id/sales-returns"
          render={(props) => <SalesReturnList />}
        />
        <Route
          exact
          path="/:id/purchase-returns"
          render={(props) => <PurchaseReturnList />}
        />
        <Route
          exact
          path="/:id/payments-received"
          render={(props) => <PaymentReceivedList />}
        />
        <Route
          exact
          path="/:id/payments-out"
          render={(props) => <PaymentOutList />}
        />
        <Route
          exact
          path="/:id/sales-invoice-details/:id"
          render={(props) => <SalesInvoiceInner />}
        />
        <Route
          exact
          path="/:id/edit-sales-invoice/:id"
          render={(props) => <EditSalesInvoice />}
        />
        <Route
          exact
          path="/:id/purchase-invoice-details/:id"
          render={(props) => <PurchaseInvoiceDetails />}
        />
        <Route
          exact
          path="/:id/edit-purchase-invoice/:id"
          render={(props) => <EditPurchaseInvoice />}
        />
        <Route
          exact
          path="/:id/payment-received-details/:id"
          render={(props) => <PaymentReceivedDetails />}
        />

        <Route
          exact
          path="/:id/payment-out-details/:id"
          render={(props) => <PaymentOutDetails />}
        />
        <Route
          exact
          path="/:id/sales-return-details/:id"
          render={(props) => <SalesReturnDetails />}
        />
        <Route
          exact
          path="/:id/edit-sales-return/:id"
          render={(props) => <EditSalesReturn />}
        />
        <Route
          exact
          path="/:id/quotation-details/:id"
          render={(props) => <QuotationDetails />}
        />
        <Route
          exact
          path="/:id/edit-quotation/:id"
          render={(props) => <EditQuotation />}
        />
        <Route
          exact
          path="/:id/edit-credit-note/:id"
          render={(props) => <EditCreditNote />}
        />
        <Route
          exact
          path="/:id/credit-note-details/:id"
          render={(props) => <CreditNoteDetails />}
        />
        <Route
          exact
          path="/:id/purchase-return-details/:id"
          render={(props) => <PurchaseReturnDetails />}
        />
        <Route
          exact
          path="/:id/edit-purchase-return/:id"
          render={(props) => <EditPurchaseReturn />}
        />
        <Route
          exact
          path="/:id/debit-note-details/:id"
          render={(props) => <DebitNoteDetails />}
        />
        <Route
          exact
          path="/:id/edit-debit-note/:id"
          render={(props) => <EditDebitNote />}
        />
        <Route
          exact
          path="/:id/purchase-order-details/:id"
          render={(props) => <PurchaseOrderDetails />}
        />
        <Route
          exact
          path="/:id/edit-purchase-order/:id"
          render={(props) => <EditPurchaseOrder />}
        />
        <Route
          exact
          path="/:id/parties-details/:id"
          render={(props) => <PartiesDetails />}
        />
        <Route
          exact
          path="/:id/edit-parties/:id"
          render={(props) => <EditParties />}
        />
        <Route
          exact
          path="/:id/item-details/:id"
          render={(props) => <ItemDetails />}
        />
        <Route
          exact
          path="/:id/edit-item/:id"
          render={(props) => <EditItem />}
        />
      </Switch>
    </div>
  );
}
