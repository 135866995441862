import React from "react";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";

export default function Expense() {
  return (
    <div>
      <HeaderInner pageTitle="Expense" />
      <div className="AppPage container--narrow mt"></div>
      <Footer />
    </div>
  );
}
