import { createBrowserHistory } from "history";
import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";
import * as jwt from "./reducer-jwt";
import * as auth_state from "./reducer-auth";
import * as profile from "./reducer-myProfile";
import * as snackbar from "./reducer-snackbar";
import * as orgunit from "./reducer-orgunit";
import * as permission from "./reducer-permissions";
import * as userProfile from "./reducer-userProfiles";
import * as orgUserProfile from "./reducer-orguser";

import * as config from "./reducer-config";
import * as appUserProfile from "./reducer-appuser";

import * as parties from "../components/pages/Accounting/reducers/reducer-parties";
import * as document from "../components/pages/Accounting/reducers/reducer-document";
import * as items from "../components/pages/Accounting/reducers/reducer-items";
import * as salesInvoice from "../components/pages/Accounting/reducers/reducer-salesInvoice";
import * as purchaseInvoice from "../components/pages/Accounting/reducers/reducer-purchaseInvoice";
import * as quotation from "../components/pages/Accounting/reducers/reducer-quotation";
import * as purchaseOrder from "../components/pages/Accounting/reducers/reducer-purchaseOrder";
import * as salesReturn from "../components/pages/Accounting/reducers/reducer-salesReturn";
import * as creditNote from "../components/pages/Accounting/reducers/reducer-creditNote";
import * as debitNote from "../components/pages/Accounting/reducers/reducer-debitNote";
import * as purchaseReturn from "../components/pages/Accounting/reducers/reducer-purchaseReturn";
import * as transactions from "../components/pages/Accounting/reducers/reducer-transactions";

import { RESET_STATE } from "../constant/action-types";

import { cleanState } from "../utils";

export const history = createBrowserHistory();

const router = { router: connectRouter(history) };

const Reducers = Object.assign(
  router,
  jwt,
  auth_state,
  permission,
  config,
  orgunit,
  snackbar,
  profile,
  userProfile,
  orgUserProfile,
  appUserProfile,
  parties,
  document,
  items,
  salesInvoice,
  purchaseInvoice,
  quotation,
  purchaseOrder,
  salesReturn,
  creditNote,
  debitNote,
  purchaseReturn,
  transactions
);

const allReducers = combineReducers(Reducers);

const rootReducer = (state, action) => {
  let resultState = state;
  if (action.type === RESET_STATE) {
    console.log("Reset State");
    resultState = cleanState(resultState, [
      "router",
      "jwt",
      "auth_state",
      "permission",
      "config",
      "orgunit",
      "snackbar",
      "profile",
      "userProfile",
      "orgUserProfile",
      "appUserProfile",
      "parties",
      "document",
      "items",
      "salesInvoice",
      "purchaseInvoice",
      "quotation",
      "purchaseOrder",
      "salesReturn",
      "creditNote",
      "debitNote",
      "purchaseReturn",
      "transactions",
    ]);
  }
  return allReducers(resultState, action);
};

export default rootReducer;
