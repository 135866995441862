import {
  CREATE_ITEMS_REQUESTED,
  CREATE_ITEMS_SUCCESS,
  CREATE_ITEMS_ERRORED,
  GET_ITEMS_REQUESTED,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_ERRORED,
  GET_ITEM_DETAILS_REQUESTED,
  GET_ITEM_DETAILS_SUCCESS,
  GET_ITEM_DETAILS_ERRORED,
  UPDATE_ITEM_REQUESTED,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_ERRORED,
  DELETE_ITEM_REQUESTED,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_items_requested = () => ({
  type: CREATE_ITEMS_REQUESTED,
});

export const create_items_success = () => ({
  type: CREATE_ITEMS_SUCCESS,
});

export const create_items_errored = (error) => ({
  type: CREATE_ITEMS_ERRORED,
  error,
});

export const get_items_requested = () => ({
  type: GET_ITEMS_REQUESTED,
});

export const get_items_success = (payload) => ({
  type: GET_ITEMS_SUCCESS,
  payload,
});

export const get_items_errored = (error) => ({
  type: GET_ITEMS_ERRORED,
  error,
});

export const get_item_details_requested = () => ({
  type: GET_ITEM_DETAILS_REQUESTED,
});

export const get_item_details_success = (payload) => ({
  type: GET_ITEM_DETAILS_SUCCESS,
  payload,
});

export const get_item_details_errored = (error) => ({
  type: GET_ITEM_DETAILS_ERRORED,
  error,
});

export const update_item_requested = () => ({
  type: UPDATE_ITEM_REQUESTED,
});

export const update_item_success = (payload) => ({
  type: UPDATE_ITEM_SUCCESS,
  payload,
});

export const update_item_errored = (error) => ({
  type: UPDATE_ITEM_ERRORED,
  error,
});
export const delete_item_requested = () => ({
  type: DELETE_ITEM_REQUESTED,
});

export const delete_item_success = (payload) => ({
  type: DELETE_ITEM_SUCCESS,
  payload,
});

export const delete_item_errored = (error) => ({
  type: DELETE_ITEM_ERRORED,
  error,
});

export const create_items = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_items_requested());
  console.log(`Create items`);
  console.log("activeOuid", activeOuid);
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/items`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("items created successfully");
        dispatch(showSnackbar("items created successfully", "success"));
        dispatch(create_items_success(response));
        dispatch(get_items("", activeOuid));
      })
      .catch((err) => {
        console.log("items creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("items creation Failed", "error"));
        dispatch(create_items_errored(err));
      });
  }
  return null;
};

export const get_items = (query, activeOuid) => async (dispatch) => {
  dispatch(get_items_requested());
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`accountsApiEndpoint`, accountsApiEndpoint);
  console.log(`credentials`, credentials);

  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/items${query}`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get items Success", response.data);
        dispatch(get_items_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get items Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get items Failed", "error"));
        }
        dispatch(get_items_errored(err));
      });
  }
  return null;
};

export const get_item_details = (itemId, activeOuid) => async (dispatch) => {
  dispatch(get_item_details_requested());
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`accountsApiEndpoint`, accountsApiEndpoint);
  console.log(`credentials`, credentials);

  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/items/id/${itemId}`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get item Success", response.data);
        dispatch(get_item_details_success(response.data));
      })
      .catch((err) => {
        console.log("Get item Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get item Failed", "error"));
        }
        dispatch(get_item_details_errored(err));
      });
  }
  return null;
};

export const update_item =
  (payload, itemId, activeOuid) => async (dispatch) => {
    dispatch(update_item_requested());
    console.log(`update_item`);

    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/items/id/${itemId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("item Updation Success");
          dispatch(showSnackbar("item Updated Successfully", "success"));
          dispatch(update_item_success());
          dispatch(get_items("", activeOuid));
        })
        .catch((err) => {
          console.log("item updation Failed");
          dispatch(showSnackbar("item updation Failed", "error"));
          dispatch(update_item_errored(err));
        });
    }
    return null;
  };

export const delete_item = (activeOuid, itemId) => async (dispatch) => {
  dispatch(delete_item_requested());
  console.log(`delete_note`);
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/items/id/${itemId}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Delete item Success");
        dispatch(showSnackbar("item deleted successfully", "success"));
        dispatch(delete_item_success());
        dispatch(get_items("", activeOuid));
      })
      .catch((err) => {
        if (err.response) {
          console.log(
            "Delete item Failed :",
            JSON.stringify(err.response.data)
          );
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Delete item", "error"));
        }
        dispatch(delete_item_errored(err));
      });
  }
  return null;
};
