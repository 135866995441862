import React from "react";
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { Button, capitalize } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import dist from "@unicef/material-ui-currency-textfield";
import moment from "moment";
import { convertToRs } from "./moneyConverter";
import { Directions } from "@material-ui/icons";
import rupeesImg from "./rupeesImg.png"

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 10,
  },
  section: {
    marginBottom: 20,
   
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
  },
  bill_from_address_name: {
    fontSize: 12,
    fontWeight: 500,
    textTransform: "uppercase",
  },
  bill_enitiy_type: {
    fontSize: 11,
    fontWeight: 600,
    textTransform: "capitalize",
    marginBottom:"2px",
  },
  bill_top_box: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection:"row",
  },
  bill_from_address_mobile:{
    fontSize: "10px",
    marginBottom:"2px",
  },
  bill_from_address_details:{
    fontSize: "10px",
    marginBottom:"2px",
  },
  bill_details_top_wrap: {
    listStyle: "none",
    display: "flex",
    fontSize: "7px",
    justifyContent: "space-between",
    textTransform: "capitalize",
    backgroundColor: "#eee",
    padding: "5px",
    flexDirection:"row",
  },
  bill_details_top_item:{
    flexBasis:"100%",
    textAlign:"left",
  },
  bill_details_top_invoice_date:{
    flexBasis:"100%",
    textAlign:"center",
  },
  bill_details_top_due_date:{
    flexBasis:"100%",
    textAlign:"right",
  },
  bill_to_address_wrap:{
    marginBottom:10,
    marginTop:10,
  },
  bill_to_address_title: {
    fontSize: 12,
    fontWeight: 500,
    textTransform:"capitalize",
    marginBottom:"2px",
  },
  bill_to_address_name: {
    fontSize: 9,
    textTransform:"uppercase",
    fontWeight:500,
    marginBottom:"2px",
  },
  bill_to_address_mobile:{
    fontSize: 8,
    marginBottom:"2px",
  },
  bill_to_address_details: {
    fontSize: 8 ,
    marginBottom:"2px",
 },
 table:{
    width: "100%",
    bordercollapse: "collapse"
},
tabletitle:{
    fontSize:"8px",
    backgroundColor:"#eee",
    listStyle: "none",
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize",
    padding: 5,
    flexDirection:"row",
    fontWeight: 700,
  },
item:{
    flex:1,
    fontSize:"8px",
    fontWeight:600,
    textAlign:"left",
},
hours:{
    flex:1,
    fontSize:"8px",
    fontWeight:600,
    textAlign:"center",
},
rate:{
    flex:1,
    fontSize:"8px",
    fontWeight:600,
    textAlign:"right",
},
bill_tbody_row:{
    
    borderBottom:"1px solid #eee",
    fontSize:"8px",
    listStyle: "none",
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize",
    padding: "5px",
    flexDirection:"row",
},
bill_tbody_foot:{
    backgroundColor:"#eee",
    fontSize:"8px",
    listStyle: "none",
    display: "flex",
    justifyContent: "space-between",
    textTransform: "capitalize",
    padding: 5,
    flexDirection:"row",
},
billtTableItemName:{
    textAlign:"left",
    fontSize:"8px",
    flex:1,
},
billtTableItem:{
    textAlign:"center",
    fontSize:"8px", 
    flex:1,  
},
billtTableItemTotal:{
    textAlign:"right",
    fontSize:"8px",
    flex:1,
},
billBottomTableWrap:{
  paddingLeft: 0,
  listStyle:"none",
  width: "auto",
  marginLeft:"auto",
  marginTop:0,
},
billBottomTableItem:{
    display:"flex",
    fontSize:"9px",  
    textTransform:"capitalize",
    flexDirection:"row",
    marginBottom:"2px",
},
billBottomTableItemGrandTotal:{
  display:"flex",
  fontSize:"9px",  
  textTransform:"capitalize",
  flexDirection:"row",
  borderTop: "2px solid #eee",
  padding: "5px 0",
  fontWeight:600,
},
billBottomTableItemLabel:{
  textAlign:"right",
  width:"100px",
},
billBottomTableItemValue:{
  width: "100px",
  textAlign:"right",
},
bill_tbody_foot_subTotal_Title:{
  textAlign:"left",
  fontSize:"8px",
  fontWeight: 600,
  textTransform:"capitalize",
  flex:1
},
bill_tbody_foot_item:{
  textAlign:"center",
  fontSize:"8px",
  fontWeight: 600,
  textTransform:"capitalize",
  flex:1
},

bill_tbody_foot_subTotal:{
  textAlign:"right",
  fontSize:"8px",
  fontWeight: 600,
  textTransform:"capitalize",
  flex:1
},
rupeesImg:{
  width:"5px",
  height:"5px",
  padding: 5,
  margin:"10px"
}

});

const DownloadPdf = ({ billDetails, companyDetails }) => {
  const party = billDetails.party;
  const invoiceDate = moment(billDetails.createdAt).format("DD-MM-YYYY");
  const dueDate= moment(billDetails.dueDate).format("DD-MM-YYYY");
  function renderPurchaseItems(invLines) {
    if (invLines) {
      return invLines.map((item) => (
        <View  style={styles.bill_tbody_row}>
          <Text style={styles.billtTableItemName}>{item.itemName && item.itemName}</Text>
          <Text style={styles.billtTableItem}>{item.quantity && item.quantity}</Text>
          <Text style={styles.billtTableItem}>{item.rate && convertToRs(item.rate)}</Text>
          <Text style={styles.billtTableItem}>{item.tax && item.tax}%</Text>
          <Text style={styles.billtTableItemTotal}>
            <Image src={rupeesImg} style={styles.rupeesImg} />
            {item.total && convertToRs(`${item.total}`)}
          </Text>
        </View>
      ));
    } else {
      return <>No invoice items found</>;
    }
  }

  function renderGrandTotalSum(invLines) {
    let grandTotalSum = 0;
    invLines.map(
      ({ total }) => (grandTotalSum = grandTotalSum + parseFloat(total))
    );
    console.log("grandTotalSum", grandTotalSum);
    grandTotalSum = convertToRs(`${grandTotalSum}`);
    grandTotalSum = grandTotalSum - (grandTotalSum * billDetails.discount) / 100;
    console.log("grandTotalSum", grandTotalSum);
    return grandTotalSum;
  }

  function renderSubTotalSum(invLines) {
    let subTotalSum = 0;
    invLines.map(
      ({ total }) => (subTotalSum = subTotalSum + parseFloat(total))
    );
    console.log("subTotalSum", subTotalSum);
    subTotalSum = convertToRs(`${subTotalSum}`);
    return subTotalSum;
  }

  function renderSubTaxSum(invLines) {
    let subTaxSum = 0;
    invLines.map(
      ({ tax, rate, quantity }) =>
        (subTaxSum =
          subTaxSum +
          (parseFloat(tax) * parseFloat(rate) * parseFloat(quantity)) / 100)
    );
    console.log("subTaxSum", subTaxSum);
    subTaxSum = convertToRs(`${subTaxSum}`);
    return subTaxSum;
  }



  const Table = ({ items }) => (
    <View style={styles.table}>
        <View style={styles.tabletitle}>
            <Text style={styles.item}>Item</Text>
            <Text style={styles.hours}>Qty</Text>
            <Text style={styles.hours}>Rate</Text>
            <Text style={styles.hours}>Tax</Text>
            <Text style={styles.rate}>Sub Total</Text>
        </View>
        
        <View style={styles.bill_tbody}>
          {billDetails.invLines && renderPurchaseItems(billDetails.invLines)}
        </View>
           
      <View style={styles.bill_tbody}>
        <View style={styles.bill_tbody_foot}>
          <Text style={styles.bill_tbody_foot_subTotal_Title}>Sub total</Text>
          <Text style={styles.bill_tbody_foot_item}>
            {billDetails.invLines && billDetails.invLines.length}
          </Text>
          <Text style={styles.bill_tbody_foot_item}></Text>
          <Text style={styles.bill_tbody_foot_item}>
            <Image src={rupeesImg} style={styles.rupeesImg} />
            {billDetails.invLines && renderSubTaxSum(billDetails.invLines)}
          </Text>
          <Text style={styles.bill_tbody_foot_subTotal}>
            <Image src={rupeesImg} style={styles.rupeesImg} />
            {billDetails.invLines && renderSubTotalSum(billDetails.invLines)}
          </Text>
        </View>
      </View>
    </View>
  );

  const content = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.bill_top_box}>
            <View style={styles.section}>
                <Text style={styles.bill_from_address_name}>{companyDetails.data?.name}</Text>
                <Text style={styles.bill_from_address_mobile}>Mobile: {companyDetails.data?.phoneNumber}</Text>
                <Text style={styles.bill_from_address_details}>Address: {companyDetails.data?.address}</Text>
                <Text style={styles.bill_from_address_details}>GST: {companyDetails.data?.GSTNumber}</Text>
            </View>
            <View style={styles.bill_enitiy_type}>
                <Text>{billDetails.entity === "salesinvoice" && <>Sales Invoice</>}</Text>
                <Text>{billDetails.entity === "salesreturn" && <>Sales Return</>}</Text>
                <Text>{billDetails.entity === "quotation" && <>Quotation</>}</Text>
                <Text>{billDetails.entity === "creditnote" && <>Credit Note</>}</Text>
                <Text>{billDetails.entity === "purchaseinvoice" && <>Purchase Invoice</>}</Text>
                <Text>{billDetails.entity === "purchasereturn" && <>Purchase Return</>}</Text>
                <Text>{billDetails.entity === "debitnote" && <>Debit Note</>}</Text>
                <Text>{billDetails.entity === "purchaseorder" && <>Purchase Order</>}</Text>
            </View>
        </View>
        <View style={styles.bill_details_top_wrap}>
            <Text style={styles.bill_details_top_item}>{billDetails.idNumber}</Text>
            <Text style={styles.bill_details_top_invoice_date}>Invoice date: {invoiceDate}</Text>
            <Text style={styles.bill_details_top_due_date}>Due date: {dueDate}</Text>
        </View>

        <View style={styles.bill_to_address_wrap}>
            <Text style={styles.bill_to_address_title}>Bill to</Text>
            <Text style={styles.bill_to_address_name}>{party?.name}</Text>
            <Text style={styles.bill_to_address_mobile}>Mobile: {party?.phone}</Text>
            <Text style={styles.bill_to_address_details}>Address: {party?.businessInfo?.billingAddress}</Text>
            <Text style={styles.bill_to_address_details}>GST: {party?.businessInfo?.gstNumber}</Text>
        </View>
        <View style={styles.section}>
          <Table items={billDetails.invLines || []} />
          
        </View>

      <View style={styles.billBottomTableWrap}>
        <View style={styles.billBottomTableItem}>
          <Text style={styles.billBottomTableItemLabel}>
            Taxable amount
          </Text>
          <Text style={styles.billBottomTableItemValue}>
            <Image src={rupeesImg} style={styles.rupeesImg} />
            {billDetails.invLines && (
            <>
              {renderSubTotalSum(billDetails.invLines) -
                renderSubTaxSum(billDetails.invLines)}
            </>
            )}
          </Text>
        </View>
        <View style={styles.billBottomTableItem}>
          <Text style={styles.billBottomTableItemLabel}>CGST</Text>
          <Text style={styles.billBottomTableItemValue}>
            <Image src={rupeesImg} style={styles.rupeesImg} />
            {billDetails.invLines && (
              renderSubTaxSum(billDetails.invLines) / 2
            )}
          </Text>
        </View>
        <View style={styles.billBottomTableItem}>
          <Text style={styles.billBottomTableItemLabel}>SGST</Text>
          <Text style={styles.billBottomTableItemValue}>
            <Image src={rupeesImg} style={styles.rupeesImg} />
            {billDetails.invLines && (
              renderSubTaxSum(billDetails.invLines) / 2
            )}
          </Text>
        </View>
        <View style={styles.billBottomTableItem}>
          <Text style={styles.billBottomTableItemLabel}>
            Discount ({billDetails.discount && `${billDetails.discount}%`})
          </Text>
          <Text style={styles.billBottomTableItemValue}>
            <Image src={rupeesImg} style={styles.rupeesImg} />
            {billDetails.invLines &&
              (renderSubTotalSum(billDetails.invLines) * billDetails.discount) /
              100}
          </Text>
        </View>
        <View style={styles.billBottomTableItemGrandTotal}>
          <Text style={styles.billBottomTableItemLabel}>Grand total</Text>
          <Text style={styles.billBottomTableItemValue}>
            <Image src={rupeesImg} style={styles.rupeesImg} />
            {billDetails.invLines && renderGrandTotalSum(billDetails.invLines)}
          </Text>
        </View>
      </View>
      </Page>
    </Document>
  );

  return (
    <PDFDownloadLink document={content} fileName="bill.pdf">
      <Button
        variant="contained"
        color="primary"
        startIcon={<GetAppIcon />}
      >
        Download Invoice
      </Button>
    </PDFDownloadLink>
  );
};

export default DownloadPdf;
