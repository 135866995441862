import {
  CREATE_PURCHASE_INVOICE_REQUESTED,
  CREATE_PURCHASE_INVOICE_SUCCESS,
  CREATE_PURCHASE_INVOICE_ERRORED,
  GET_PURCHASE_INVOICE_REQUESTED,
  GET_PURCHASE_INVOICE_SUCCESS,
  GET_PURCHASE_INVOICE_ERRORED,
  GET_PURCHASE_INVOICE_DETAILS_REQUESTED,
  GET_PURCHASE_INVOICE_DETAILS_SUCCESS,
  GET_PURCHASE_INVOICE_DETAILS_ERRORED,
  UPDATE_PURCHASE_INVOICE_REQUESTED,
  UPDATE_PURCHASE_INVOICE_SUCCESS,
  UPDATE_PURCHASE_INVOICE_ERRORED,
  DELETE_PURCHASE_INVOICE_REQUESTED,
  DELETE_PURCHASE_INVOICE_SUCCESS,
  DELETE_PURCHASE_INVOICE_ERRORED,
  RESET_PURCHASE_INVOICE,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  purchaseInvoice: [],
  purchaseInvoiceDetails: {},
  error: {},
};

export function purchaseInvoice(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_PURCHASE_INVOICE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_PURCHASE_INVOICE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_PURCHASE_INVOICE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_PURCHASE_INVOICE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_PURCHASE_INVOICE_SUCCESS:
      return {
        ...state,
        purchaseInvoice: payload,
        showGlobalLoader: false,
      };

    case GET_PURCHASE_INVOICE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_PURCHASE_INVOICE_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_PURCHASE_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        purchaseInvoiceDetails: payload,
        showGlobalLoader: false,
      };

    case GET_PURCHASE_INVOICE_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_PURCHASE_INVOICE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_PURCHASE_INVOICE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        purchaseInvoiceDetails: payload,
      };

    case UPDATE_PURCHASE_INVOICE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_PURCHASE_INVOICE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_PURCHASE_INVOICE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_PURCHASE_INVOICE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case RESET_PURCHASE_INVOICE:
      return {
        ...state,
        showGlobalLoader: false,
        purchaseInvoice: [],
      };

    default:
      return state;
  }
}
