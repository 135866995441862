import React from "react";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";

export default function DeliveryChalan() {
  return (
    <div>
      <HeaderInner pageTitle="Delivery Chalan" />
      <div className="AppPage container--narrow mt"></div>
      <Footer />
    </div>
  );
}
