import {
  CREATE_SALES_RETURN_REQUESTED,
  CREATE_SALES_RETURN_SUCCESS,
  CREATE_SALES_RETURN_ERRORED,
  GET_SALES_RETURN_REQUESTED,
  GET_SALES_RETURN_SUCCESS,
  GET_SALES_RETURN_ERRORED,
  GET_SALES_RETURN_DETAILS_REQUESTED,
  GET_SALES_RETURN_DETAILS_SUCCESS,
  GET_SALES_RETURN_DETAILS_ERRORED,
  UPDATE_SALES_RETURN_REQUESTED,
  UPDATE_SALES_RETURN_SUCCESS,
  UPDATE_SALES_RETURN_ERRORED,
  DELETE_SALES_RETURN_REQUESTED,
  DELETE_SALES_RETURN_SUCCESS,
  DELETE_SALES_RETURN_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  salesReturn: [],
  salesReturnDetails: {},
  error: {},
};

export function salesReturn(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_SALES_RETURN_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_SALES_RETURN_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_SALES_RETURN_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_SALES_RETURN_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_SALES_RETURN_SUCCESS:
      return {
        ...state,
        salesReturn: payload,
        showGlobalLoader: false,
      };

    case GET_SALES_RETURN_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_SALES_RETURN_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_SALES_RETURN_DETAILS_SUCCESS:
      return {
        ...state,
        salesReturnDetails: payload,
        showGlobalLoader: false,
      };

    case GET_SALES_RETURN_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_SALES_RETURN_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_SALES_RETURN_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        salesReturn: payload,
      };

    case UPDATE_SALES_RETURN_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_SALES_RETURN_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_SALES_RETURN_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_SALES_RETURN_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    default:
      return state;
  }
}
