import React from "react";
import { Link } from "react-router-dom";
import configureStore from "../../../../store/configureStore";

const store = configureStore();

function PaymentReceivedCard(props) {
  const { activeOuid } = store.getState().permission;

  return (
    <>
      <Link
        to={`/${activeOuid}/payment-received-details/${props.item.id}`}
        className="transaction_card_wrap mb"
      >
        <div className="transaction_card_details_wrap">
          <div className="transaction_card_title">{props.item.party.name}</div>
          <div className="transaction_card_order">#{props.item.id}</div>
          <div className="transaction_card_date">7 June</div>
        </div>
        {props.item.type === "credit" && (
          <div className="transaction_card_amount clr_green">
            ₹ {props.item.totalAmount}
          </div>
        )}
        {props.item.type === "debit" && (
          <div className="transaction_card_amount clr_red">
            ₹ {props.item.totalAmount}
          </div>
        )}
      </Link>
    </>
  );
}

export default PaymentReceivedCard;
