import React from "react";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";

export default function CounterPOS() {
  return (
    <div>
      <HeaderInner pageTitle="Counter POS" />
      <div className="AppPage container--narrow mt"></div>
      <Footer />
    </div>
  );
}
