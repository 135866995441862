import {
  CREATE_DEBIT_NOTE_REQUESTED,
  CREATE_DEBIT_NOTE_SUCCESS,
  CREATE_DEBIT_NOTE_ERRORED,
  GET_DEBIT_NOTE_REQUESTED,
  GET_DEBIT_NOTE_SUCCESS,
  GET_DEBIT_NOTE_ERRORED,
  GET_DEBIT_NOTE_DETAILS_REQUESTED,
  GET_DEBIT_NOTE_DETAILS_SUCCESS,
  GET_DEBIT_NOTE_DETAILS_ERRORED,
  UPDATE_DEBIT_NOTE_REQUESTED,
  UPDATE_DEBIT_NOTE_SUCCESS,
  UPDATE_DEBIT_NOTE_ERRORED,
  DELETE_DEBIT_NOTE_REQUESTED,
  DELETE_DEBIT_NOTE_SUCCESS,
  DELETE_DEBIT_NOTE_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_debit_note_requested = () => ({
  type: CREATE_DEBIT_NOTE_REQUESTED,
});

export const create_debit_note_success = () => ({
  type: CREATE_DEBIT_NOTE_SUCCESS,
});

export const create_debit_note_errored = (error) => ({
  type: CREATE_DEBIT_NOTE_ERRORED,
  error,
});

export const get_debit_note_requested = () => ({
  type: GET_DEBIT_NOTE_REQUESTED,
});

export const get_debit_note_success = (payload) => ({
  type: GET_DEBIT_NOTE_SUCCESS,
  payload,
});

export const get_debit_note_errored = (error) => ({
  type: GET_DEBIT_NOTE_ERRORED,
  error,
});

export const get_debit_note_details_requested = () => ({
  type: GET_DEBIT_NOTE_DETAILS_REQUESTED,
});

export const get_debit_note_details_success = (payload) => ({
  type: GET_DEBIT_NOTE_DETAILS_SUCCESS,
  payload,
});

export const get_debit_note_details_errored = (error) => ({
  type: GET_DEBIT_NOTE_DETAILS_ERRORED,
  error,
});

export const update_debit_note_requested = () => ({
  type: UPDATE_DEBIT_NOTE_REQUESTED,
});

export const update_debit_note_success = (payload) => ({
  type: UPDATE_DEBIT_NOTE_SUCCESS,
  payload,
});

export const update_debit_note_errored = (error) => ({
  type: UPDATE_DEBIT_NOTE_ERRORED,
  error,
});
export const delete_debit_note_requested = () => ({
  type: DELETE_DEBIT_NOTE_REQUESTED,
});

export const delete_debit_note_success = (payload) => ({
  type: DELETE_DEBIT_NOTE_SUCCESS,
  payload,
});

export const delete_debit_note_errored = (error) => ({
  type: DELETE_DEBIT_NOTE_ERRORED,
  error,
});

export const create_debit_note = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_debit_note_requested());
  console.log(`Create debit note`);
  console.log("activeOuid", activeOuid);
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/debitnotes`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("debit note created successfully");
        dispatch(showSnackbar("debit note created successfully", "success"));
        dispatch(create_debit_note_success(response));
        dispatch(get_debit_note(activeOuid));
      })
      .catch((err) => {
        console.log("debit note creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("debit note creation Failed", "error"));
        dispatch(create_debit_note_errored(err));
      });
  }
  return null;
};

export const get_debit_note = (activeOuid) => async (dispatch) => {
  dispatch(get_debit_note_requested());
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`accountsApiEndpoint`, accountsApiEndpoint);
  console.log(`credentials`, credentials);

  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/debitnotes`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get debit note Success", response.data);
        dispatch(get_debit_note_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get debit note Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get debit note Failed", "error"));
        }
        dispatch(get_debit_note_errored(err));
      });
  }
  return null;
};

export const get_debit_note_details =
  (debitId, activeOuid) => async (dispatch) => {
    dispatch(get_debit_note_details_requested());
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    console.log(`accountsApiEndpoint`, accountsApiEndpoint);
    console.log(`credentials`, credentials);

    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/debitnotes/id/${debitId}`;

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get debit note Success", response.data);
          dispatch(get_debit_note_details_success(response.data));
        })
        .catch((err) => {
          console.log("Get debit note Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get debit note Failed", "error"));
          }
          dispatch(get_debit_note_details_errored(err));
        });
    }
    return null;
  };

export const update_debit_note =
  (payload, debitId, activeOuid) => async (dispatch) => {
    dispatch(update_debit_note_requested());
    console.log(`update_debit_note`);

    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/debitnotes/id/${debitId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("debit note Updation Success");
          dispatch(showSnackbar("debit note Updated Successfully", "success"));
          dispatch(update_debit_note_success());
          dispatch(get_debit_note(activeOuid));
        })
        .catch((err) => {
          console.log("debit note updation Failed");
          dispatch(showSnackbar("debit note updation Failed", "error"));
          dispatch(update_debit_note_errored(err));
        });
    }
    return null;
  };

export const delete_debit_note =
  (activeOuid, meetingId) => async (dispatch) => {
    dispatch(delete_debit_note_requested());
    console.log(`delete_note`);
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/debitnotes/id/${meetingId}`;
      const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Delete debit note Success");
          dispatch(showSnackbar("debit note deleted successfully", "success"));
          dispatch(delete_debit_note_success());
          dispatch(get_debit_note("", activeOuid));
        })
        .catch((err) => {
          if (err.response) {
            console.log(
              "Delete debit note Failed :",
              JSON.stringify(err.response.data)
            );
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Delete debit note", "error"));
          }
          dispatch(delete_debit_note_errored(err));
        });
    }
    return null;
  };
