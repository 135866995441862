import React, { Component } from 'react';
import { connect } from 'react-redux';
import { create_org_user } from "../../../actions/action-orguser";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from '../../../reducers';
import SidebarWrapper from '../../layout/SidebarWrapper';
import configureStore from '../../../store/configureStore';
import FormErrors from "../../FormErrors";
import FormButton from '../../common/FormButton';
import { create_user_profile, get_user_profile_details } from '../../../actions/action-userProfiles';
import { create_app_user } from '../../../actions/action-appuser';
import { Select, Paper, MenuItem, InputLabel, TextField, Button } from '@material-ui/core';
import { showSnackbar, hideSnackbar } from '../../../actions/action-snackbar';
import Loader from '../../common/Loader';
import { isEmptyJson } from '../../common/CommonFunctions';
import Validate from "../../utility/FormValidation";
import { Auth } from "aws-amplify";
import AppPolicyCard from '../../common/AppPolicyCard';

const store = configureStore();


class CreateOrgUser extends Component {

    constructor() {
        super();
        this.state = {
            userDetails: {
                "username": "",
                "name": "",
                "email": "",
                "phone_number": "",
            },
            userDetailsExist: {},
            errors: {},
            roles: [],
            application: '',
            applicationRole: [],
            appRoleMappings: [],
        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
    }

    componentWillUnmount() {
        store.dispatch(hideSnackbar());
    }

    clearErrorState = () => {
        this.setState({
            errors: {}
        });
    }

    searchUser = async event => {
        const username = this.state.userDetails.username;
        console.log("searchUser : ", username);
        if (username) {
            const { get_user_profile_details } = this.props;
            await get_user_profile_details(username, true);
            const { userProfileDetails } = store.getState().userProfile;
            if (!isEmptyJson(userProfileDetails)) {
                this.setState({
                    userDetailsExist: userProfileDetails,
                });
                if (userProfileDetails.userNameExists === "false") {
                    const newUserDetails = this.state.userDetails;
                    newUserDetails["password"] = "";
                    newUserDetails["confirmpassword"] = "";
                    this.setState({
                        userDetails: newUserDetails
                    });
                }
            }
        }
    }


    handleSubmit = async event => {
        console.log("handleSubmit");
        // Form validation
        this.clearErrorState();
        const error = Validate(event, this.state.userDetails);
        if (error) {
            this.setState({
                errors: { ...this.state.errors, ...error }
            });
        } else {
            const { activeOuid } = store.getState().permission;
            if (this.state.userDetailsExist.userNameExists === "true") {
                const { name, username, email, phone_number } = this.state.userDetails;
                const roles = this.state.roles;
                let userDetails = {};
                userDetails["name"] = name;
                userDetails["username"] = username;
                userDetails["email"] = email;
                userDetails["phone_number"] = phone_number;
                userDetails["roles"] = roles;
                const { create_org_user } = this.props;
                await create_org_user(userDetails, activeOuid);
                // app user creation
                if (this.state.appRoleMappings.length > 0) {
                    const { create_app_user } = this.props;
                    this.state.appRoleMappings.map(async (item, index) => {
                        delete userDetails["email"];
                        delete userDetails["phone_number"];
                        delete userDetails["name"];
                        userDetails["roles"] = item.roles;
                        await create_app_user(userDetails, activeOuid, item.appId);
                    });
                }

                this.props.history.push(`/${activeOuid}/create-org-user`);
            } else {
                // AWS Cognito integration here
                this.clearErrorState();
                const { name, username, email, phone_number, password } = this.state.userDetails;
                try {
                    const signUpResponse = await Auth.signUp({
                        username,
                        password,
                        attributes: {
                            email: email,
                            phone_number: phone_number,
                            name: name
                        }
                    });
                    // console.log("sign Up Response : ", signUpResponse);
                    let userDetails = this.state.userDetails;
                    delete userDetails["password"];
                    delete userDetails["confirmpassword"];
                    const { create_user_profile } = this.props;
                    await create_user_profile(userDetails);
                    const { activeOuid } = store.getState().permission;
                    const { create_org_user } = this.props;
                    await create_org_user(userDetails, activeOuid);
                    // app user creation
                    if (this.state.appRoleMappings.length > 0) {
                        const { create_app_user } = this.props;
                        this.state.appRoleMappings.map(async (item, index) => {
                            delete userDetails["email"];
                            delete userDetails["phone_number"];
                            delete userDetails["name"];
                            userDetails["roles"] = item.roles;
                            await create_app_user(userDetails, activeOuid, item.appId);
                        });
                    }
                    this.props.history.push(`/${activeOuid}/create-org-user`);
                } catch (error) {
                    let err = null;
                    !error.message ? err = { "message": error } : err = error;
                    this.setState({
                        errors: {
                            ...this.state.errors,
                            cognito: err
                        }
                    });
                }
            }
            this.props.history.push(`/${activeOuid}/orgusers`);
        }

    }

    onInputChange = event => {
        const newUserDetails = this.state.userDetails;
        newUserDetails[event.target.id] = event.target.value;
        this.setState({
            userDetails: newUserDetails
        });
    }

    orgRoleChange = event => {
        var newRoles = this.state.roles;
        newRoles[0] = event.target.value;
        this.setState({
            roles: newRoles
        });
    }

    appRoleChange = event => {
        var newRoles = this.state.applicationRole;
        newRoles[0] = event.target.value;
        this.setState({
            applicationRole: newRoles
        });
    }


    appChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    renderOrgUnitRoles = coreConfig => {
        if (coreConfig) {
            const config = coreConfig.data.config;
            return config.roles.map((item, index) => (
                <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
            ));
        }
        else {
            //let staticRoles = new Map([["Admin", "admin"], ["User", "user"]]);
            var staticRoles = JSON.parse('{"roles": [ {"value": "admin","name": "Admin"},{"value": "user", "name": "User" }]}');

            return staticRoles.roles.map((item, index) => (
                <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
            ));
        }
    }

    renderApplicationList = coreConfig => {
        if (coreConfig) {
            const config = coreConfig.data.config;
            return config.applications.map((item, index) => (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
            ));
        }
        else {
            return null
        }
    }


    renderApplicationRoles = application => {
        const { activeOuidConfigs } = store.getState().config;
        const applicationConfig = activeOuidConfigs.find(({ data }) => data.identifier === application)
        return applicationConfig.data.config.roles.map((item, index) => (
            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
        ));
    }



    addAppRole() {
        const application = this.state.application;
        const appRole = this.state.applicationRole;
        const newAppRoleMappings = this.state.appRoleMappings;
        if (application && appRole.length !== 0) {
            const appExist = newAppRoleMappings.find(({ appId }) => appId === application);
            if (appExist) {
                store.dispatch(showSnackbar("Application Alredy Exist In Policy", "error"));
            } else {
                let policy = {
                    "appId": application,
                    "roles": appRole,
                }
                newAppRoleMappings.push(policy);
                this.setState({
                    appRoleMappings: newAppRoleMappings,
                    application: '',
                    applicationRole: [],
                }, () => {

                });
            }
        } else {
            store.dispatch(showSnackbar("No Application Role Selected", "error"));
        }
    }

    deleteAppRole(index) {
        const newAppRoleMappings = this.state.appRoleMappings;
        newAppRoleMappings.splice(index, 1);
        this.setState({
            appRoleMappings: newAppRoleMappings,
        }, () => {

        });
    }

    renderApplicationPolicy() {
        const appRoleMappings = this.state.appRoleMappings;
        return appRoleMappings.map((item, index) => (
            <AppPolicyCard
                context={"createPage"}
                policy={item}
                index={index}
                editable
                deletePolicy={(index) => this.deleteAppRole(index)}
            />
        ));
    }

    render() {
        const { permissions } = store.getState().permission;
        const { showGlobalLoader } = store.getState().userProfile;
        const { activeOuidConfigs } = store.getState().config;
        const coreConfig = activeOuidConfigs.find(({ data }) => data.identifier === 'core')
        return (
            <div className="page">
                <SidebarWrapper
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    <div className="container--narrow">
                        <Paper component="div" >

                            <div className="formContainer">

                                <form>
                                    <h1 className="formName">Add Users to your organisation</h1>
                                    <TextField
                                        className="FormInput"
                                        id="name"
                                        label="Name"
                                        placeholder="Full name"
                                        variant="standard"
                                        value={this.state.userDetails.name || ''}
                                        onChange={this.onInputChange}
                                        fullWidth

                                    />
                                    <TextField
                                        className="FormInput"
                                        id="phone_number"
                                        label="Phone"
                                        placeholder="Phone number"
                                        variant="standard"
                                        value={this.state.userDetails.phone_number || ''}
                                        onChange={this.onInputChange}
                                        fullWidth
                                    />
                                    <div className="form-item-wrapper">
                                    <TextField
                                        className="FormInput"
                                        type="email"
                                        id="email"
                                        label="Email"
                                        placeholder="Email address"
                                        variant="standard"
                                        value={this.state.userDetails.email || ''}
                                        onChange={this.onInputChange}
                                        fullWidth
                                    />
                                    </div>
                                    <div className="form-item-wrapper">
                                        <InputLabel id="role-select-label">
                                            Org Role
                                        </InputLabel>
                                        <Select
                                            labelId="role-select-label"
                                            id="roles"
                                            name="roles"
                                            value={this.state.roles[0]}
                                            onChange={this.orgRoleChange}
                                            className="FormInput"
                                        >
                                            {this.renderOrgUnitRoles(coreConfig)}
                                        </Select>
                                    </div>
                                    <div className="searchUserBox">
                                        <h3>Check if username available.</h3>
                                        <div className="searchUserFormWrap split">
                                            <TextField
                                                className="textInput"
                                                id="username"
                                                label="Username"
                                                placeholder="Username"
                                                variant="standard"
                                                value={this.state.userDetails.username || ''}
                                                onChange={this.onInputChange}
                                                fullWidth
                                            />
                                            <FormButton
                                                onClick={() => this.searchUser()}
                                                text="Check"
                                            />
                                        </div>

                                        <div className="SearchUsernameResultWrapper">
                                            {showGlobalLoader ?
                                                <Loader />
                                                :
                                                <div>
                                                    {!isEmptyJson(this.state.userDetailsExist) ?
                                                        <div>
                                                            {this.state.userDetailsExist.userNameExists === "true" ?
                                                                <div>
                                                                    <div className="successText">User with this username exist.</div>
                                                                    <div className="formConfirmationMsg">Do you want to add the <span className="bold">{this.state.userDetails.username}</span> to your organisation with the above profile details?</div>
                                                                    <Button variant="contained" color="primary" onClick={() => this.handleSubmit()}>
                                                                        Yes
                                                                    </Button>
                                                                    <span className="verticalSpacer15" />
                                                                    <Button variant="outlined" color="primary">
                                                                        No
                                                                    </Button>

                                                                </div>
                                                                :
                                                                <div>
                                                                    <div className="successText">This username available for you.</div>
                                                                    <div className="formConfirmationMsg">Do you want to create new user with the username <span className="bold">{this.state.userDetails.username}</span> ?</div>
                                                                    <div className="form-item-wrapper">
                                                                        <TextField
                                                                            className="textInput"
                                                                            type="password"
                                                                            id="password"
                                                                            label="Password"
                                                                            placeholder="Password"
                                                                            variant="standard"
                                                                            value={this.state.userDetails.password || ''}
                                                                            onChange={this.onInputChange}
                                                                            fullWidth
                                                                        />
                                                                        <TextField
                                                                            type="password"
                                                                            id="confirmpassword"
                                                                            label="Confirm Password"
                                                                            placeholder="Confirm Password"
                                                                            variant="standard"
                                                                            value={this.state.userDetails.confirmpassword || ''}
                                                                            fullWidth
                                                                            onChange={this.onInputChange}
                                                                        />
                                                                    </div>
                                                                    <Button variant="contained" color="primary" onClick={() => this.handleSubmit()}>
                                                                        Add
                                                                    </Button>
                                                                    <span className="verticalSpacer15" />
                                                                    <Button variant="outlined" color="primary">
                                                                        No
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </div>
                                                        :
                                                        <div></div>
                                                    }
                                                    <div>
                                                        {!isEmptyJson(this.state.errors) ? <div>{this.state.errors.err}</div> : <div></div>}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                </form>

                                <FormErrors formerrors={this.state.errors} />
                            </div>
                        </Paper>
                        <Paper component="div" >
                            {this.state.appRoleMappings ?
                                this.renderApplicationPolicy()
                                :
                                <></>
                            }
                            <div className="formContainer">
                                <form>
                                    <div className="form-item-wrapper">
                                        <InputLabel id="application-select-label">
                                            Applications
                                        </InputLabel>
                                        <Select
                                            labelId="role-select-label"
                                            id="application"
                                            name="application"
                                            value={this.state.application || ''}
                                            onChange={this.appChange}
                                            className="FormInput"
                                        >
                                            {this.renderApplicationList(coreConfig)}
                                        </Select>


                                        {this.state.application && (
                                            <>
                                                <InputLabel id="application-select-label">
                                                    Application Role
                                                </InputLabel>
                                                <Select
                                                    labelId="role-select-label"
                                                    id="applicationRole"
                                                    name="applicationRole"
                                                    value={this.state.applicationRole[0] || ''}
                                                    onChange={this.appRoleChange}
                                                    className="FormInput"
                                                >
                                                    {this.renderApplicationRoles(this.state.application)}
                                                </Select>
                                            </>
                                        )}
                                    </div>
                                    <Button variant="contained" color="primary" onClick={() => this.addAppRole()}>
                                        Add Role
                                    </Button>
                                </form>
                            </div>
                        </Paper>
                    </div>
                    <CommonSnackBar />
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    permission: state.permission,
    userProfile: state.userProfile,
    config: state.config,
});


const mapDispatchToProps = (dispatch) => ({
    get_user_profile_details: (username, orgUserCreate) => dispatch(get_user_profile_details(username, orgUserCreate)),
    create_org_user: (payload, activeOuid) => dispatch(create_org_user(payload, activeOuid)),
    create_user_profile: (payload) => dispatch(create_user_profile(payload)),
    create_app_user: (payload, activeOuid, appId) => dispatch(create_app_user(payload, activeOuid, appId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrgUser);
