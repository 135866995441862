import {
  CREATE_TRANSACTION_REQUESTED,
  CREATE_TRANSACTION_SUCCESS,
  CREATE_TRANSACTION_ERRORED,
  GET_TRANSACTION_REQUESTED,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_ERRORED,
  GET_TRANSACTION_DETAILS_REQUESTED,
  GET_TRANSACTION_DETAILS_SUCCESS,
  GET_TRANSACTION_DETAILS_ERRORED,
  UPDATE_TRANSACTION_REQUESTED,
  UPDATE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_ERRORED,
  DELETE_TRANSACTION_REQUESTED,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_ERRORED,
  RESET_TRANSACTION,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  transactions: [],
  transactionDetails: {},
  error: {},
};

export function transactions(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_TRANSACTION_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_TRANSACTION_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_TRANSACTION_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: payload,
        showGlobalLoader: false,
      };

    case GET_TRANSACTION_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_TRANSACTION_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        transactionDetails: payload,
      };

    case GET_TRANSACTION_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_TRANSACTION_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        transactions: payload,
      };

    case UPDATE_TRANSACTION_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_TRANSACTION_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_TRANSACTION_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case RESET_TRANSACTION:
      return {
        ...state,
        showGlobalLoader: false,
        transactions: [],
      };

    default:
      return state;
  }
}
