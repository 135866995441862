export const RESET_STATE = "RESET_STATE";

export const SET_ALL_PERMISSIONS = "SET_ALL_PERMISSIONS";
export const PERMISSIONS_HAS_ERRORED = "PERMISSIONS_HAS_ERRORED";
export const SET_ACTIVE_TEAMID = "SET_ACTIVE_TEAMID";
export const SET_ACTIVE_OUID = "SET_ACTIVE_OUID";
export const SET_USER_ROLES = "SET_USER_ROLES";
export const SET_ACTIVE_OUID_CHAIN = "SET_ACTIVE_OUID_CHAIN";
export const CLEAR_ACTIVE_OUID_CHAIN = "CLEAR_ACTIVE_OUID_CHAIN";

export const LOGIN_REQUSTED = "LOGIN_REQUSTED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const JWT_SET = "JWT_SET";
export const UN_JWT_SET = "UN_JWT_SET";
export const JWT_ERRORED = "JWT_ERRORED";

export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const HIDE_SNACKBAR = "HIDE_SNACKBAR";

export const GET_MY_PROFILE_DETAILS_REQUESTED =
  "GET_MY_PROFILE_DETAILS_REQUESTED";
export const GET_MY_PROFILE_DETAILS_SUCCESS = "GET_MY_PROFILE_DETAILS_SUCCESS";
export const GET_MY_PROFILE_DETAILS_ERRORED = "GET_MY_PROFILE_DETAILS_ERRORED";

export const GET_ALL_ORGUSER_REQUESTED = "GET_ALL_ORGUSER_REQUESTED";
export const GET_ALL_ORGUSER_SUCCESS = "GET_ALL_ORGUSER_SUCCESS";
export const GET_ALL_ORGUSER_ERRORED = "GET_ALL_ORGUSER_ERRORED";

export const GET_ORG_USER_DETAILS_REQUESTED = "GET_ORG_USER_DETAILS_REQUESTED";
export const GET_ORG_USER_DETAILS_SUCCESS = "GET_ORG_USER_DETAILS_SUCCESS";
export const GET_ORG_USER_DETAILS_ERRORED = "GET_ORG_USER_DETAILS_ERRORED";

export const UPDATE_ORG_USER_REQUESTED = "UPDATE_ORG_USER_REQUESTED";
export const UPDATE_ORG_USER_SUCCESS = "UPDATE_ORG_USER_SUCCESS";
export const UPDATE_ORG_USER_ERRORED = "UPDATE_ORG_USER_ERRORED";

export const CREATE_ORG_USER_REQUESTED = "CREATE_ORG_USER_REQUESTED";
export const CREATE_ORG_USER_SUCCESS = "CREATE_ORG_USER_SUCCESS";
export const CREATE_ORG_USER_ERRORED = "CREATE_ORG_USER_ERRORED";

export const CREATE_USER_PROFILE_REQUESTED = "CREATE_USER_PROFILE_REQUESTED";
export const CREATE_USER_PROFILE_SUCCESS = "CREATE_USER_PROFILE_SUCCESS";
export const CREATE_USER_PROFILE_ERRORED = "CREATE_USER_PROFILE_ERRORED";

export const UPDATE_USER_PROFILE_REQUESTED = "UPDATE_USER_PROFILE_REQUESTED";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_ERRORED = "UPDATE_USER_PROFILE_ERRORED";

export const GET_USER_PROFILE_DETAILS_REQUESTED =
  "GET_USER_PROFILE_DETAILS_REQUESTED";
export const GET_USER_PROFILE_DETAILS_SUCCESS =
  "GET_USER_PROFILE_DETAILS_SUCCESS";
export const GET_USER_PROFILE_DETAILS_ERRORED =
  "GET_USER_PROFILE_DETAILS_ERRORED";

export const GET_ALL_USER_PROFILES_REQUESTED =
  "GET_ALL_USER_PROFILES_REQUESTED";
export const GET_ALL_USER_PROFILES_SUCCESS = "GET_ALL_USER_PROFILES_SUCCESS";
export const GET_ALL_USER_PROFILES_ERRORED = "GET_ALL_USER_PROFILES_ERRORED";

export const DELETE_USER_PROFILE_REQUESTED = "DELETE_USER_PROFILE_REQUESTED";
export const DELETE_USER_PROFILE_SUCCESS = "DELETE_USER_PROFILE_SUCCESS";
export const DELETE_USER_PROFILE_ERRORED = "DELETE_USER_PROFILE_ERRORED";

export const REGISTER_TENANT_REQUESTED = "REGISTER_CHANNEL_PARTNER_REQUESTED";
export const REGISTER_TENANT_SUCCESS = "REGISTER_CHANNEL_PARTNER_SUCCESS";
export const REGISTER_TENANT_ERRORED = "REGISTER_CHANNEL_PARTNER_ERRORED";

export const GET_ALL_TENANT_REQUESTED = "GET_ALL_CHANNEL_PARTNER_REQUESTED";
export const GET_ALL_TENANT_SUCCESS = "GET_ALL_CHANNEL_PARTNER_SUCCESS";
export const GET_ALL_TENANT_ERRORED = "GET_ALL_CHANNEL_PARTNER_ERRORED";

export const GET_TENANT_DETAILS_REQUESTED =
  "GET_CHANNEL_PARTNER_DETAILS_REQUESTED";
export const GET_TENANT_DETAILS_SUCCESS = "GET_CHANNEL_PARTNER_DETAILS_SUCCESS";
export const GET_TENANT_DETAILS_ERRORED = "GET_CHANNEL_PARTNER_DETAILS_ERRORED";

export const UPDATE_TENANT_DETAILS_REQUESTED =
  "UPDATE_CHANNEL_PARTNER_DETAILS_REQUESTED";
export const UPDATE_TENANT_DETAILS_SUCCESS =
  "UPDATE_CHANNEL_PARTNER_DETAILS_SUCCESS";
export const UPDATE_TENANT_DETAILS_ERRORED =
  "UPDATE_CHANNEL_PARTNER_DETAILS_ERRORED";

export const DELETE_TENANT_REQUESTED = "DELETE_CHANNEL_PARTNER_REQUESTED";
export const DELETE_TENANT_SUCCESS = "DELETE_CHANNEL_PARTNER_SUCCESS";
export const DELETE_TENANT_ERRORED = "DELETE_CHANNEL_PARTNER_ERRORED";

export const REGISTER_Orgunit_REQUESTED = "REGISTER_Orgunit_REQUESTED";
export const REGISTER_Orgunit_SUCCESS = "REGISTER_Orgunit_SUCCESS";
export const REGISTER_Orgunit_ERRORED = "REGISTER_Orgunit_ERRORED";

export const GET_ALL_Orgunit_REQUESTED = "GET_ALL_Orgunit_REQUESTED";
export const GET_ALL_Orgunit_SUCCESS = "GET_ALL_Orgunit_SUCCESS";
export const GET_ALL_Orgunit_ERRORED = "GET_ALL_Orgunit_ERRORED";

export const GET_Orgunit_DETAILS_REQUESTED = "GET_Orgunit_DETAILS_REQUESTED";
export const GET_Orgunit_DETAILS_SUCCESS = "GET_Orgunit_DETAILS_SUCCESS";
export const GET_Orgunit_DETAILS_ERRORED = "GET_Orgunit_DETAILS_ERRORED";

export const UPDATE_Orgunit_DETAILS_REQUESTED =
  "UPDATE_Orgunit_DETAILS_REQUESTED";
export const UPDATE_Orgunit_DETAILS_SUCCESS = "UPDATE_Orgunit_DETAILS_SUCCESS";
export const UPDATE_Orgunit_DETAILS_ERRORED = "UPDATE_Orgunit_DETAILS_ERRORED";

export const DELETE_Orgunit_REQUESTED = "DELETE_Orgunit_REQUESTED";
export const DELETE_Orgunit_SUCCESS = "DELETE_Orgunit_SUCCESS";
export const DELETE_Orgunit_ERRORED = "DELETE_Orgunit_ERRORED";

export const GET_ORG_PLAN_DETAILS_REQUESTED = "GET_ORG_PLAN_DETAILS_REQUESTED";
export const GET_ORG_PLAN_DETAILS_SUCCESS = "GET_ORG_PLAN_DETAILS_SUCCESS";
export const GET_ORG_PLAN_DETAILS_ERRORED = "GET_ORG_PLAN_DETAILS_ERRORED";

export const GET_ALL_NOTIFICATION_REQUESTED = "GET_ALL_NOTIFICATION_REQUESTED";
export const GET_ALL_NOTIFICATION_SUCCESS = "GET_ALL_NOTIFICATION_SUCCESS";
export const GET_ALL_NOTIFICATION_ERRORED = "GET_ALL_NOTIFICATION_ERRORED";
export const UPDATE_NOTIFICATION_ERRORED = "UPDATE_NOTIFICATION_ERRORED";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_REQUESTED = "UPDATE_NOTIFICATION_REQUESTED";

export const GET_ALL_WORK_REQUESTED = "GET_ALL_WORK_REQUESTED";
export const GET_ALL_WORK_SUCCESS = "GET_ALL_WORK_SUCCESS";
export const GET_ALL_WORK_ERRORED = "GET_ALL_WORK_ERRORED";

export const CREATE_OR_UPDATE_WORK_REQUESTED =
  "CREATE_OR_UPDATE_WORK_REQUESTED";
export const CREATE_OR_UPDATE_WORK_SUCCESS = "CREATE_OR_UPDATE_WORK_SUCCESS";
export const CREATE_OR_UPDATE_WORK_ERRORED = "CREATE_OR_UPDATE_WORK_ERRORED";

export const GET_WORK_DETAILS_REQUESTED = "GET_WORK_DETAILS_REQUESTED";
export const GET_WORK_DETAILS_SUCCESS = "GET_WORK_DETAILS_SUCCESS";
export const GET_WORK_DETAILS_ERRORED = "GET_WORK_DETAILS_ERRORED";

export const DELETE_WORK_REQUESTED = "DELETE_WORK_REQUESTED";
export const DELETE_WORK_SUCCESS = "DELETE_WORK_SUCCESS";
export const DELETE_WORK_ERRORED = "DELETE_WORK_ERRORED";

export const CREATE_OR_UPDATE_WORKLINE_REQUESTED =
  "CREATE_OR_UPDATE_WORKLINE_REQUESTED";
export const CREATE_OR_UPDATE_WORKLINE_SUCCESS =
  "CREATE_OR_UPDATE_WORKLINE_SUCCESS";
export const CREATE_OR_UPDATE_WORKLINE_ERRORED =
  "CREATE_OR_UPDATE_WORKLINE_ERRORED";

export const GET_ALL_WORKLINE_REQUESTED = "GET_ALL_WORKLINE_REQUESTED";
export const GET_ALL_WORKLINE_SUCCESS = "GET_ALL_WORKLINE_SUCCESS";
export const GET_ALL_WORKLINE_ERRORED = "GET_ALL_WORKLINE_ERRORED";

export const GET_ALL_TEAMS_REQUESTED = "GET_ALL_TEAMS_REQUESTED";
export const GET_ALL_TEAMS_SUCCESS = "GET_ALL_TEAMS_SUCCESS";
export const GET_ALL_TEAMS_ERRORED = "GET_ALL_TEAMS_ERRORED";

export const CREATE_DOCUMENT_REQUESTED = "CREATE_DOCUMENT_REQUESTED";
export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS";
export const CREATE_DOCUMENT_ERRORED = "CREATE_DOCUMENT_ERRORED";

export const GET_ALL_DOCUMENTS_REQUESTED = "GET_ALL_DOCUMENT_REQUESTED";
export const GET_ALL_DOCUMENTS_SUCCESS = "GET_ALL_DOCUMENT_SUCCESS";
export const GET_ALL_DOCUMENTS_ERRORED = "GET_ALL_DOCUMENT_ERRORED";

export const DOCUMENT_UPLOAD_REQUESTED = "DOCUMENT_UPLOAD_REQUESTED";
export const DOCUMENT_UPLOAD_SUCCESS = "DOCUMENT_UPLOAD_SUCCESS";
export const DOCUMENT_UPLOAD_ERRORED = "DOCUMENT_UPLOAD_ERRORED";

export const CREATE_OR_UPDATE_CONFIGS_REQUESTED =
  "CREATE_OR_UPDATE_CONFIGS_REQUESTED";
export const CREATE_OR_UPDATE_CONFIGS_SUCCESS =
  "CREATE_OR_UPDATE_CONFIGS_SUCCESS";
export const CREATE_OR_UPDATE_CONFIGS_ERRORED =
  "CREATE_OR_UPDATE_CONFIGS_ERRORED";

export const GET_ALL_ACTIVE_CONFIGS_REQUESTED =
  "GET_ALL_ACTIVE_CONFIGS_REQUESTED";
export const GET_ALL_ACTIVE_CONFIGS_SUCCESS = "GET_ALL_ACTIVE_CONFIGS_SUCCESS";
export const GET_ALL_ACTIVE_CONFIGS_ERRORED = "GET_ALL_ACTIVE_CONFIGS_ERRORED";

export const GET_ALL_DEPLOYED_CONFIGS_REQUESTED =
  "GET_ALL_DEPLOYED_CONFIGS_REQUESTED";
export const GET_ALL_DEPLOYED_CONFIGS_SUCCESS =
  "GET_ALL_DEPLOYED_CONFIGS_SUCCESS";
export const GET_ALL_DEPLOYED_CONFIGS_ERRORED =
  "GET_ALL_DEPLOYED_CONFIGS_ERRORED";

export const SET_ACTIVE_OUID_CONFIGS_REQUESTED =
  "SET_ACTIVE_OUID_CONFIGS_REQUESTED";
export const SET_ACTIVE_OUID_CONFIGS_SUCCESS =
  "SET_ACTIVE_OUID_CONFIGS_SUCCESS";
export const SET_ACTIVE_OUID_CONFIGS_ERRORED =
  "SET_ACTIVE_OUID_CONFIGS_ERRORED";

export const CREATE_APP_USER_REQUESTED = "CREATE_APP_USER_REQUESTED";
export const CREATE_APP_USER_SUCCESS = "CREATE_APP_USER_SUCCESS";
export const CREATE_APP_USER_ERRORED = "CREATE_APP_USER_ERRORED";

export const GET_APP_USER_DETAILS_REQUESTED = "GET_APP_USER_DETAILS_REQUESTED";
export const GET_APP_USER_DETAILS_SUCCESS = "GET_APP_USER_DETAILS_SUCCESS";
export const GET_APP_USER_DETAILS_ERRORED = "GET_APP_USER_DETAILS_ERRORED";

export const GET_ALL_APP_USER_REQUESTED = "GET_ALL_APP_USER_REQUESTED";
export const GET_ALL_APP_USER_SUCCESS = "GET_ALL_APP_USER_SUCCESS";
export const GET_ALL_APP_USER_ERRORED = "GET_ALL_APP_USER_ERRORED";

export const DELETE_APP_USER_REQUESTED = "DELETE_APP_USER_REQUESTED";
export const DELETE_APP_USER_SUCCESS = "DELETE_APP_USER_SUCCESS";
export const DELETE_APP_USER_ERRORED = "DELETE_APP_USER_ERRORED";

export const CREATE_WORKORDER_REQUESTED = "CREATE_WORKORDER_REQUESTED";
export const CREATE_WORKORDER_SUCCESS = "CREATE_WORKORDER_SUCCESS";
export const CREATE_WORKORDER_ERRORED = "CREATE_WORKORDER_ERRORED";
export const GET_ALL_WORKORDERS_REQUESTED = "GET_ALL_WORKORDERS_REQUESTED";
export const GET_ALL_WORKORDERS_SUCCESS = "GET_ALL_WORKORDERS_SUCCESS";
export const GET_ALL_WORKORDERS_ERRORED = "GET_ALL_WORKORDERS_ERRORED";
export const GET_WORKORDER_REQUESTED = "GET_WORKORDER_REQUESTED";
export const GET_WORKORDER_SUCCESS = "GET_WORKORDER_SUCCESS";
export const GET_WORKORDER_ERRORED = "GET_WORKORDER_ERRORED";
export const CREATE_COURSE_REQUESTED = "CREATE_COURSE_REQUESTED";

export const CREATE_COURSE_SUCCESS = "CREATE_COURSE_SUCCESS";
export const CREATE_COURSE_ERRORED = "CREATE_COURSE_ERRORED";
export const UPDATE_COURSE_REQUESTED = "UPDATE_COURSE_REQUESTED";

export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_ERRORED = "UPDATE_COURSE_ERRORED";
export const GET_COURSES_REQUESTED = "GET_COURSES_REQUESTED";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_ERRORED = "GET_COURSES_ERRORED";

export const GET_COURSE_REQUESTED = "GET_COURSE_REQUESTED";

export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_ERRORED = "GET_COURSE_ERRORED";

export const DELETE_COURSE_REQUESTED = "DELETE_COURSE_REQUESTED";
export const DELETE_COURSE_SUCCESS = "DELETE_COURSE_SUCCESS";
export const DELETE_COURSE_ERRORED = "DELETE_COURSE_ERRORED";

export const CREATE_COURSEUSER_SUCCESS = "CREATE_COURSEUSER_SUCCESS";
export const CREATE_COURSEUSER_ERRORED = "CREATE_COURSEUSER_ERRORED";
export const CREATE_COURSEUSER_REQUESTED = "CREATE_COURSEUSER_REQUESTED";

export const GET_COURSEUSERS_REQUESTED = "GET_COURSEUSERS_REQUESTED";
export const GET_COURSEUSERS_SUCCESS = "GET_COURSEUSERS_SUCCESS";
export const GET_COURSEUSERS_ERRORED = "GET_COURSEUSERS_ERRORED";

export const GET_COURSEUSER_REQUESTED = "GET_COURSEUSER_REQUESTED";
export const GET_COURSEUSER_SUCCESS = "GET_COURSEUSER_SUCCESS";
export const GET_COURSEUSER_ERRORED = "GET_COURSEUSER_ERRORED";

export const DELETE_COURSEUSER_REQUESTED = "DELETE_COURSEUSER_REQUESTED";
export const DELETE_COURSEUSER_SUCCESS = "DELETE_COURSEUSER_SUCCESS";
export const DELETE_COURSEUSER_ERRORED = "DELETE_COURSEUSER_ERRORED";

export const CREATE_OR_UPDATE_COURSELINE_REQUESTED =
  "CREATE_OR_UPDATE_COURSELINE_REQUESTED";
export const CREATE_OR_UPDATE_COURSELINE_SUCCESS =
  "CREATE_OR_UPDATE_COURSELINE_SUCCESS";
export const CREATE_OR_UPDATE_COURSELINE_ERRORED =
  "CREATE_OR_UPDATE_COURSELINE_ERRORED";
export const GET_COURSELINES_REQUESTED = "GET_COURSELINES_REQUESTED";
export const GET_COURSELINES_SUCCESS = "GET_COURSELINES_SUCCESS";
export const GET_COURSELINES_ERRORED = "GET_COURSELINES_ERRORED";

export const DELETE_DOCUMENT_REQUESTED = "DELETE_DOCUMENT_REQUESTED";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_ERRORED = "DELETE_DOCUMENT_ERRORED";

export const CREATE_TAG_REQUESTED = "CREATE_TAG_REQUESTED";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_ERRORED = "CREATE_TAG_ERRORED";

export const CREATE_CL_QUESTION_REQUESTED = "CREATE_CL_QUESTION_REQUESTED";
export const CREATE_CL_QUESTION_ERRORED = "CREATE_CL_QUESTION_ERRORED";
export const CREATE_CL_QUESTION_SUCCESS = "CREATE_CL_QUESTION_SUCCESS";

export const GET_CL_QUESTIONS_REQUESTED = "GET_CL_QUESTIONS_REQUESTED";
export const GET_CL_QUESTIONS_SUCCESS = "GET_CL_QUESTIONS_SUCCESS";
export const GET_CL_QUESTIONS_ERRORED = "GET_CL_QUESTIONS_ERRORED";

export const DELETE_CL_QUESTION_REQUESTED = "DELETE_CL_QUESTION_REQUESTED";
export const DELETE_CL_QUESTION_SUCCESS = "DELETE_CL_QUESTION_SUCCESS";
export const DELETE_CL_QUESTION_ERRORED = "DELETE_CL_QUESTION_ERRORED";
