import React from "react";
import { useEffect } from "react";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import Bill from "./common/Bill";
import { history } from "../../../reducers";
import Loader from "../../common/Loader";
import { get_orgunit_details } from "../../../actions/action-orgunit";
import { get_quotation_details } from "./actions/action-quotation";
import NewBill from "./common/NewBill";


const store = configureStore();

function QuotationDetails() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { quotationDetails, showGlobalLoader } = useSelector(
    (state) => state.quotations
  );
  const { OrgunitDetails } = useSelector((state) => state.orgunit);

  useEffect(() => {
    let quotationId = window.location.pathname.split("/");
    quotationId = quotationId[3];
    console.log("quotationId", quotationId);

    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_quotation_details(quotationId, activeOuid));
      await dispatch(get_orgunit_details(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Quotation  Details" />
      <div className="AppPage container--narrow mt FBAppPage">
        <button
          className="bill_edit_btn mb"
          onClick={() =>
            history.push(`/${activeOuid}/edit-quotation/${quotationDetails.id}`)
          }
        >
          Edit
        </button>
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <>
            <NewBill
              billDetails={quotationDetails}
              companyDetails={OrgunitDetails}
            />
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default QuotationDetails;
