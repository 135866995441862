import {
  CREATE_PURCHASE_INVOICE_REQUESTED,
  CREATE_PURCHASE_INVOICE_SUCCESS,
  CREATE_PURCHASE_INVOICE_ERRORED,
  GET_PURCHASE_INVOICE_REQUESTED,
  GET_PURCHASE_INVOICE_SUCCESS,
  GET_PURCHASE_INVOICE_ERRORED,
  GET_PURCHASE_INVOICE_DETAILS_REQUESTED,
  GET_PURCHASE_INVOICE_DETAILS_SUCCESS,
  GET_PURCHASE_INVOICE_DETAILS_ERRORED,
  UPDATE_PURCHASE_INVOICE_REQUESTED,
  UPDATE_PURCHASE_INVOICE_SUCCESS,
  UPDATE_PURCHASE_INVOICE_ERRORED,
  DELETE_PURCHASE_INVOICE_REQUESTED,
  DELETE_PURCHASE_INVOICE_SUCCESS,
  DELETE_PURCHASE_INVOICE_ERRORED,
  RESET_PURCHASE_INVOICE,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_purchase_invoice_requested = () => ({
  type: CREATE_PURCHASE_INVOICE_REQUESTED,
});

export const create_purchase_invoice_success = () => ({
  type: CREATE_PURCHASE_INVOICE_SUCCESS,
});

export const create_purchase_invoice_errored = (error) => ({
  type: CREATE_PURCHASE_INVOICE_ERRORED,
  error,
});

export const get_purchase_invoice_requested = () => ({
  type: GET_PURCHASE_INVOICE_REQUESTED,
});

export const get_purchase_invoice_success = (payload) => ({
  type: GET_PURCHASE_INVOICE_SUCCESS,
  payload,
});

export const get_purchase_invoice_errored = (error) => ({
  type: GET_PURCHASE_INVOICE_ERRORED,
  error,
});

export const get_purchase_invoice_details_requested = () => ({
  type: GET_PURCHASE_INVOICE_DETAILS_REQUESTED,
});

export const get_purchase_invoice_details_success = (payload) => ({
  type: GET_PURCHASE_INVOICE_DETAILS_SUCCESS,
  payload,
});

export const get_purchase_invoice_details_errored = (error) => ({
  type: GET_PURCHASE_INVOICE_DETAILS_ERRORED,
  error,
});

export const update_purchase_invoice_requested = () => ({
  type: UPDATE_PURCHASE_INVOICE_REQUESTED,
});

export const update_purchase_invoice_success = (payload) => ({
  type: UPDATE_PURCHASE_INVOICE_SUCCESS,
  payload,
});

export const update_purchase_invoice_errored = (error) => ({
  type: UPDATE_PURCHASE_INVOICE_ERRORED,
  error,
});
export const delete_purchase_invoice_requested = () => ({
  type: DELETE_PURCHASE_INVOICE_REQUESTED,
});

export const delete_purchase_invoice_success = (payload) => ({
  type: DELETE_PURCHASE_INVOICE_SUCCESS,
  payload,
});

export const delete_purchase_invoice_errored = (error) => ({
  type: DELETE_PURCHASE_INVOICE_ERRORED,
  error,
});

export const reset_purchase_invoice = () => ({
  type: RESET_PURCHASE_INVOICE,
});

export const create_purchase_invoice =
  (payload, activeOuid) => async (dispatch) => {
    dispatch(create_purchase_invoice_requested());
    console.log(`Create purchase invoice`);
    console.log("activeOuid", activeOuid);
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/purchaseinvoices`;
      const method = "post";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("purchase invoice created successfully");
          dispatch(
            showSnackbar("purchase invoice created successfully", "success")
          );
          dispatch(create_purchase_invoice_success(response));
          dispatch(get_purchase_invoice("", activeOuid));
        })
        .catch((err) => {
          console.log("purchase invoice creation Failed");
          // console.error('ErrorLog : ', JSON.stringify(err));
          dispatch(showSnackbar("purchase invoice creation Failed", "error"));
          dispatch(create_purchase_invoice_errored(err));
        });
    }
    return null;
  };

export const get_purchase_invoice = (query, activeOuid) => async (dispatch) => {
  dispatch(get_purchase_invoice_requested());
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`accountsApiEndpoint`, accountsApiEndpoint);
  console.log(`credentials`, credentials);

  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/purchaseinvoices${query}`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get purchase invoice Success", response.data);
        dispatch(get_purchase_invoice_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get purchase invoice Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get purchase invoice Failed", "error"));
        }
        dispatch(get_purchase_invoice_errored(err));
      });
  }
  return null;
};

export const get_purchase_invoice_details =
  (invoiceId, activeOuid) => async (dispatch) => {
    dispatch(get_purchase_invoice_details_requested());
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    console.log(`accountsApiEndpoint`, accountsApiEndpoint);
    console.log(`credentials`, credentials);

    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/purchaseinvoices/id/${invoiceId}`;

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get purchase invoice Success", response.data);
          dispatch(get_purchase_invoice_details_success(response.data));
        })
        .catch((err) => {
          console.log("Get purchase invoice Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get purchase invoice Failed", "error"));
          }
          dispatch(get_purchase_invoice_details_errored(err));
        });
    }
    return null;
  };

export const update_purchase_invoice =
  (payload, invoiceId, activeOuid) => async (dispatch) => {
    dispatch(update_purchase_invoice_requested());
    console.log(`update_purchase_invoice`);

    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/purchaseinvoices/id/${invoiceId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("purchase invoice Updation Success");
          dispatch(
            showSnackbar("purchase invoice Updated Successfully", "success")
          );
          dispatch(update_purchase_invoice_success());
          dispatch(get_purchase_invoice("", activeOuid));
        })
        .catch((err) => {
          console.log("purchase invoice updation Failed");
          dispatch(showSnackbar("purchase invoice updation Failed", "error"));
          dispatch(update_purchase_invoice_errored(err));
        });
    }
    return null;
  };

export const delete_purchase_invoice =
  (activeOuid, meetingId) => async (dispatch) => {
    dispatch(delete_purchase_invoice_requested());
    console.log(`delete_note`);
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/purchaseinvoices/id/${meetingId}`;
      const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Delete purchase invoice Success");
          dispatch(
            showSnackbar("purchase invoice deleted successfully", "success")
          );
          dispatch(delete_purchase_invoice_success());
          dispatch(get_purchase_invoice("", activeOuid));
        })
        .catch((err) => {
          if (err.response) {
            console.log(
              "Delete purchase invoice Failed :",
              JSON.stringify(err.response.data)
            );
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Delete purchase invoice", "error"));
          }
          dispatch(delete_purchase_invoice_errored(err));
        });
    }
    return null;
  };
