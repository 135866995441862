import React from "react";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";
import { history } from "../../reducers";

export default function Welcome() {
  return (
    <div className="fullPageImage">
      <Paper className="SignupWelcomePage">
        <h1>Welcome!</h1>
        <p>You have successfully registered a new account.</p>
        <p>
          We've sent you a email. Please click on the confirmation link to
          verify your account.
        </p>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          fullWidth
          onClick={() => {
            history.push(`/login`);
          }}
        >
          Login
        </Button>
      </Paper>
    </div>
  );
}
