import React from "react";
import HeaderInner from "./HeaderInner";
import { history } from "../../..//reducers";
import CommonSnackBar from "../../common/CommonSnackBar";
import Footer from "./Footer";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import configureStore from "../../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import {
  create_parties,
  get_parties_details,
  update_parties,
} from "./actions/action-parties";
import Loader from "../../common/Loader";
import { useEffect } from "react";

const store = configureStore();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  Select: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  formControl: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  attendanceButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  tabs: {
    background: "#fff",
    boxShadow: "none",
  },
  tab: {
    borderBottom: "1.98502px solid #4B4DED",
  },
  tabpanel: {
    padding: "0px",
  },
  tabHead: {
    boxShadow: "none",
    borderTop: "1px solid #eee",
  },
  toggleWrap: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "30px",
  },
  toggle: {
    marginRight: "10px",
    padding: "8px 10px",
    border: "2px solid #9D00C6 !important",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "100px !important",
    color: "#9D00C6",
    textAlign: "center",
    background: "#fff",
    minWidth: "50px",
    fontSize: " 10px",
    "&.Mui-selected": {
      backgroundColor: "#9d00c6 ",
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#9d00c6 !important",
      color: "#fff",
    },
  },
}));

function EditParties() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { activeOuid } = store.getState().permission;
  const { partiesDetails, showGlobalLoader } = useSelector(
    (state) => state.parties
  );

  console.log("activeOuid", activeOuid);
  const [value, setValue] = React.useState(0);
  const [parties, setParties] = React.useState("");
  const [credit, setCredit] = React.useState("");

  const [state, setState] = React.useState({
    name: "",
    phoneNumber: "",
    type: "",
    category: "",
    description: "",
    businessInfo: {
      gstNumber: "",
      panNumber: "",
      billingAddress: "",
      state: "",
    },
    creditInfo: {
      openingCredit: "",
      creditLimit: "",
      creditType: credit,
    },
  });

  console.log("state", state);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleFieldChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
    console.log("task", state);
  };

  const handleBusinessInfoChange = (event) => {
    event.preventDefault();
    setState({
      ...state,
      businessInfo: {
        ...state.businessInfo,
        [event.target.id]: event.target.value,
      },
    });
    console.log("task", state);
  };

  const handleCreditInfoChange = (event) => {
    setState({
      ...state,
      creditInfo: {
        ...state.creditInfo,
        [event.target.id]: event.target.value,
      },
    });
    console.log("task", state);
  };

  const handleOtherDetailsChange = (event) => {
    setState({
      ...state,
      businessInfo: {
        ...state.businessInfo,
        [event.target.id]: event.target.value,
      },
    });
    console.log("task", state);
  };

  const handlePartiesChange = (event, newParties) => {
    setParties(newParties);
    setState({ ...state, type: newParties });
    console.log("newParties", newParties);
  };
  const handleCreditChange = (event, newCredit) => {
    setCredit(newCredit);
    setState({
      ...state,
      creditInfo: {
        ...state.creditInfo,
        creditType: newCredit,
      },
    });
  };

  let partyId;
  partyId = window.location.pathname.split("/");
  partyId = partyId[3];

  const handleSubmit = () => {
    dispatch(update_parties(state, partyId, activeOuid));
    history.push(`/${activeOuid}/parties`);
  };

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_parties_details(partyId, activeOuid));

      setState({
        ...state,
        name: store.getState().parties.partiesDetails.name,
        phoneNumber: store.getState().parties.partiesDetails.phone,
        type: store.getState().parties.partiesDetails.type,
        category: store.getState().parties.partiesDetails.category,
        description: store.getState().parties.partiesDetails.description,
        businessInfo: store.getState().parties.partiesDetails.businessInfo,
        creditInfo: store.getState().parties.partiesDetails.creditInfo,
      });

      setParties(store.getState().parties.partiesDetails.type);
      setCredit(store.getState().parties.partiesDetails.creditInfo.creditType);
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Edit Parties" />
      <div className="AppPage container--narrow mt FBAppPage">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <>
            <div className="w_box mb">
              <TextField
                id="name"
                label="Party Name *"
                fullWidth
                variant="outlined"
                className={classes.input}
                onChange={handleFieldChange}
                value={state.name}
              />
              <div className="field_split_wrap">
                <div>
                  <TextField
                    id="phoneNumber"
                    label="Number"
                    fullWidth
                    variant="outlined"
                    className={classes.input}
                    onChange={handleFieldChange}
                    value={state.phoneNumber}
                  />
                </div>
                <div>
                  <div>Parties</div>

                  <ToggleButtonGroup
                    color="primary"
                    value={parties}
                    exclusive
                    onChange={handlePartiesChange}
                    className={classes.toggleWrap}
                  >
                    <ToggleButton
                      className={classes.toggle}
                      value="Customer"
                      aria-label="Customer"
                    >
                      Customer
                    </ToggleButton>
                    <ToggleButton
                      className={classes.toggle}
                      value="Supplier"
                      aria-label="Supplier"
                    >
                      Supplier
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            </div>
            <AppBar
              position="static"
              color="default"
              className={classes.tabHead}
            >
              <Tabs
                className={classes.tabs}
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  className={classes.tab}
                  label="Business Info"
                  {...a11yProps(0)}
                />
                <Tab
                  className={classes.tab}
                  label="Credit Info"
                  {...a11yProps(1)}
                />
                <Tab
                  className={classes.tab}
                  label="Other Details"
                  {...a11yProps(2)}
                />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                className={classes.tabpanel}
              >
                <div className="w_box mb">
                  <TextField
                    id="gstNumber"
                    label="GST Number"
                    fullWidth
                    variant="outlined"
                    className={classes.input}
                    onChange={handleBusinessInfoChange}
                    value={state.businessInfo.gstNumber}
                  />
                  <TextField
                    id="panNumber"
                    label="Pan Number"
                    fullWidth
                    variant="outlined"
                    className={classes.input}
                    onChange={handleBusinessInfoChange}
                    value={state.businessInfo.panNumber}
                  />
                </div>
                <div className="w_box mb">
                  <h6 className="field_box_heading">Billing Address</h6>
                  <TextField
                    id="state"
                    label="State"
                    fullWidth
                    variant="outlined"
                    className={classes.input}
                    onChange={handleBusinessInfoChange}
                    value={state.businessInfo.state}
                  />
                  <TextField
                    label="Billing Address"
                    id="billingAddress"
                    fullWidth
                    variant="outlined"
                    className={classes.input}
                    onChange={handleBusinessInfoChange}
                    value={state.businessInfo.billingAddress}
                    minRows="4"
                    multiline
                  />
                </div>
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
                dir={theme.direction}
                className={classes.tabpanel}
              >
                <div className="w_box mb">
                  <div className="field_split_wrap">
                    <div>
                      <TextField
                        id="openingCredit"
                        label="Opening Credit"
                        fullWidth
                        variant="outlined"
                        className={classes.input}
                        onChange={handleCreditInfoChange}
                        value={state.creditInfo.openingCredit}
                      />
                    </div>
                    <div>
                      <ToggleButtonGroup
                        color="primary"
                        value={credit}
                        exclusive
                        onChange={handleCreditChange}
                        className={classes.toggleWrap}
                      >
                        <ToggleButton className={classes.toggle} value="pay">
                          pay
                        </ToggleButton>
                        <ToggleButton
                          className={classes.toggle}
                          value="collect"
                        >
                          collect
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                  <TextField
                    id="creditLimit"
                    label="Credit Limit"
                    fullWidth
                    variant="outlined"
                    className={classes.input}
                    onChange={handleCreditInfoChange}
                    value={state.creditInfo.creditLimit}
                  />
                </div>
              </TabPanel>
              <TabPanel
                value={value}
                index={2}
                dir={theme.direction}
                className={classes.tabpanel}
              >
                <div className="w_box mb">
                  <TextField
                    id="description"
                    label="Description"
                    fullWidth
                    variant="outlined"
                    className={classes.input}
                    onChange={handleFieldChange}
                    value={state.description}
                    minRows="4"
                    multiline
                  />
                </div>
              </TabPanel>
            </SwipeableViews>
            <div className="AppFloatingButtonWrapper t-c ">
              <button
                className="btn bg_light_green"
                style={{ width: "80%", margin: "0 auto" }}
                onClick={() => handleSubmit()}
              >
                update party
              </button>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default EditParties;
