import {
  CREATE_ITEMS_REQUESTED,
  CREATE_ITEMS_SUCCESS,
  CREATE_ITEMS_ERRORED,
  GET_ITEMS_REQUESTED,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_ERRORED,
  GET_ITEM_DETAILS_REQUESTED,
  GET_ITEM_DETAILS_SUCCESS,
  GET_ITEM_DETAILS_ERRORED,
  UPDATE_ITEM_REQUESTED,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_ERRORED,
  DELETE_ITEM_REQUESTED,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  items: [],
  itemDetails: {},
  error: {},
};

export function items(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_ITEMS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_ITEMS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_ITEMS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_ITEMS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_ITEMS_SUCCESS:
      return {
        ...state,
        items: payload,
        showGlobalLoader: false,
      };

    case GET_ITEMS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_ITEM_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_ITEM_DETAILS_SUCCESS:
      return {
        ...state,
        itemDetails: payload,
        showGlobalLoader: false,
      };

    case GET_ITEM_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_ITEM_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        itemDetails: payload,
      };

    case UPDATE_ITEM_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_ITEM_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_ITEM_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_ITEM_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    default:
      return state;
  }
}
