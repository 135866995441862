import React from "react";
import Moment from "react-moment";
import { convertToRs } from "./moneyConverter";

export default function TransactionCard(props) {
  return (
    <>
      <div className="transaction_card_wrap mb">
        <div className="transaction_card_details_wrap">
          <div className="transaction_card_title">{props.item.party.name}</div>
          <div className="transaction_card_order">#{props.item.id}</div>
          <div className="transaction_card_date">
            <Moment format="D MMM YYYY">{props.item.date}</Moment>
          </div>
        </div>
        {props.item.type === "credit" && (
          <div className="transaction_card_amount clr_green">
            ₹ {convertToRs(props.item.totalAmount)}
          </div>
        )}
        {props.item.type === "debit" && (
          <div className="transaction_card_amount clr_red">
            ₹ {convertToRs(props.item.totalAmount)}
          </div>
        )}
      </div>
    </>
  );
}
