import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import configureStore from "../../../store/configureStore";
import Drawer from "@material-ui/core/Drawer";
import { history } from "../../../reducers";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Auth } from "aws-amplify";
import { resetState } from "../../../actions";
// import logo from "./images/ecomlogo.svg";

const store = configureStore();

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    display: "flex",
    flexDirection: "row",
    background: "#fff",
    color: "#200E32",
    borderRadius: "0 0 10px 10px",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "0px 4px 4px rgba(229, 229, 229, 0.51)",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    // marginRight: theme.spacing(2),
  },
  toolBar: {
    flexDirection: "row",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function Header(props) {
  const classes = useStyles();

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { activeOuid } = store.getState().permission;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogOut = async (event) => {
    event.preventDefault();
    try {
      Auth.signOut();
      if (props.auth) {
        props.auth.setAuthStatus(false);
        props.auth.setUser(null);
      }
      store.dispatch(resetState());
      history.push("/login");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.toolBar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
        {/* <img src={logo} /> */}
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          {/* <ListItem button>
            <ListItemText
              primary={"company-listing"}
              onClick={() => history.push(`/${activeOuid}/company-listing`)}
            />
          </ListItem>

          <ListItem button>
            <ListItemText
              primary={"chat-screen"}
              onClick={() => history.push(`/${activeOuid}/chat-screen`)}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary={"post-details"}
              onClick={() => history.push(`/${activeOuid}/post-details`)}
            />
          </ListItem>
          <ListItem button>
            <ListItemText
              primary={"incubator-profile"}
              onClick={() => history.push(`/${activeOuid}/incubator-profile`)}
            />
          </ListItem> */}

          <ListItem button>
            <ListItemText primary={"Logout"} onClick={handleLogOut} />
          </ListItem>
        </List>
        <Divider />
      </Drawer>
    </div>
  );
}
