import {
  CREATE_QUOTATION_REQUESTED,
  CREATE_QUOTATION_SUCCESS,
  CREATE_QUOTATION_ERRORED,
  GET_QUOTATION_REQUESTED,
  GET_QUOTATION_SUCCESS,
  GET_QUOTATION_ERRORED,
  GET_QUOTATION_DETAILS_REQUESTED,
  GET_QUOTATION_DETAILS_SUCCESS,
  GET_QUOTATION_DETAILS_ERRORED,
  UPDATE_QUOTATION_REQUESTED,
  UPDATE_QUOTATION_SUCCESS,
  UPDATE_QUOTATION_ERRORED,
  DELETE_QUOTATION_REQUESTED,
  DELETE_QUOTATION_SUCCESS,
  DELETE_QUOTATION_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  quotations: [],
  quotationDetails: {},
  error: {},
};

export function quotations(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_QUOTATION_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_QUOTATION_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_QUOTATION_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_QUOTATION_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_QUOTATION_SUCCESS:
      return {
        ...state,
        quotations: payload,
        showGlobalLoader: false,
      };

    case GET_QUOTATION_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_QUOTATION_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_QUOTATION_DETAILS_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        quotationDetails: payload,
      };

    case GET_QUOTATION_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_QUOTATION_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_QUOTATION_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        quotationDetails: payload,
      };

    case UPDATE_QUOTATION_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_QUOTATION_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_QUOTATION_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_QUOTATION_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    default:
      return state;
  }
}
