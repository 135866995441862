import {
    CREATE_DOCUMENT_REQUESTED,
    CREATE_DOCUMENT_SUCCESS,
    CREATE_DOCUMENT_ERRORED,
    GET_ALL_DOCUMENTS_REQUESTED,
    GET_ALL_DOCUMENTS_SUCCESS,
    GET_ALL_DOCUMENTS_ERRORED,
    DOCUMENT_UPLOAD_REQUESTED,
    DOCUMENT_UPLOAD_SUCCESS,
    DOCUMENT_UPLOAD_ERRORED,
  } from "../constant/action-types";
  
  const initialState = {
    showUploadLoader: false,
    documentUrl: "",
    error: {},
  };
  
  export function document(state = initialState, { type, payload }) {
    switch (type) {
      case DOCUMENT_UPLOAD_REQUESTED:
        console.log("DOCUMENT_UPLOAD_REQUESTED : ");
        return {
          ...state,
          showUploadLoader: true,
        };
  
      case DOCUMENT_UPLOAD_SUCCESS:
        console.log("DOCUMENT_UPLOAD_SUCCESS : ");
  
        return {
          ...state,
          showUploadLoader: false,
          documentUrl: payload
        };
  
      case DOCUMENT_UPLOAD_ERRORED:
        console.log("DOCUMENT_UPLOAD_ERRORED : ");
  
        return {
          ...state,
          showUploadLoader: false,
          error: payload,
        };
  
      case CREATE_DOCUMENT_REQUESTED:
        console.log("CREATE_DOCUMENT_REQUESTED : ");
        return {
          ...state,
          showGlobalLoader: true,
        };
  
      case CREATE_DOCUMENT_SUCCESS:
        return {
          ...state,
          showGlobalLoader: false,
        };
  
      case CREATE_DOCUMENT_ERRORED:
        return {
          ...state,
          showGlobalLoader: false,
          error: payload,
        };
  
    //   case GET_ALL_DOCUMENTS_REQUESTED:
    //     // console.log("GET_ALL_DOCUMENTS_REQUESTED : ");
    //     return {
    //       ...state,
    //       showGlobalLoader: true,
    //       documents: [],
    //     };
  
    //   case GET_ALL_DOCUMENTS_SUCCESS:
    //     // console.log("GET_ALL_DOCUMENTS_SUCCESS : ", JSON.stringify(payload));
    //     return {
    //       ...state,
    //       showGlobalLoader: false,
    //       documents: payload.items,
    //     };
  
    //   case GET_ALL_DOCUMENTS_ERRORED:
    //     return {
    //       ...state,
    //       showGlobalLoader: false,
    //       error: payload,
    //     };
  
      default:
        return state;
    }
  }
  