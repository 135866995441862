export const RESET_STATE = "RESET_STATE";

export const CREATE_PARTIES_REQUESTED = "CREATE_PARTIES_REQUESTED";
export const CREATE_PARTIES_SUCCESS = "CREATE_PARTIES_SUCCESS";
export const CREATE_PARTIES_ERRORED = "CREATE_PARTIES_ERRORED";
export const GET_PARTIES_REQUESTED = "GET_PARTIES_REQUESTED";
export const GET_PARTIES_SUCCESS = "GET_PARTIES_SUCCESS";
export const GET_PARTIES_ERRORED = "GET_PARTIES_ERRORED";
export const GET_PARTIES_DETAILS_REQUESTED = "GET_PARTIES_DETAILS_REQUESTED";
export const GET_PARTIES_DETAILS_SUCCESS = "GET_PARTIES_DETAILS_SUCCESS";
export const GET_PARTIES_DETAILS_ERRORED = "GET_PARTIES_DETAILS_ERRORED";
export const UPDATE_PARTIES_REQUESTED = "UPDATE_PARTIES_REQUESTED";
export const UPDATE_PARTIES_SUCCESS = "UPDATE_PARTIES_SUCCESS";
export const UPDATE_PARTIES_ERRORED = "UPDATE_PARTIES_ERRORED";
export const DELETE_PARTIES_REQUESTED = "DELETE_PARTIES_REQUESTED";
export const DELETE_PARTIES_SUCCESS = "DELETE_PARTIES_SUCCESS";
export const DELETE_PARTIES_ERRORED = "DELETE_PARTIES_ERRORED";

export const CREATE_ITEMS_REQUESTED = "CREATE_ITEMS_REQUESTED";
export const CREATE_ITEMS_SUCCESS = "CREATE_ITEMS_SUCCESS";
export const CREATE_ITEMS_ERRORED = "CREATE_ITEMS_ERRORED";
export const GET_ITEMS_REQUESTED = "GET_ITEMS_REQUESTED";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_ITEMS_ERRORED = "GET_ITEMS_ERRORED";
export const GET_ITEM_DETAILS_REQUESTED = "GET_ITEM_DETAILS_REQUESTED";
export const GET_ITEM_DETAILS_SUCCESS = "GET_ITEM_DETAILS_SUCCESS";
export const GET_ITEM_DETAILS_ERRORED = "GET_ITEM_DETAILS_ERRORED";
export const UPDATE_ITEM_REQUESTED = "UPDATE_ITEM_REQUESTED";
export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS";
export const UPDATE_ITEM_ERRORED = "UPDATE_ITEM_ERRORED";
export const DELETE_ITEM_REQUESTED = "DELETE_ITEM_REQUESTED";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";
export const DELETE_ITEM_ERRORED = "DELETE_ITEM_ERRORED";

export const CREATE_DOCUMENT_REQUESTED = "CREATE_DOCUMENT_REQUESTED";
export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS";
export const CREATE_DOCUMENT_ERRORED = "CREATE_DOCUMENT_ERRORED";
export const DOCUMENT_UPLOAD_REQUESTED = "DOCUMENT_UPLOAD_REQUESTED";
export const DOCUMENT_UPLOAD_SUCCESS = "DOCUMENT_UPLOAD_SUCCESS";
export const DOCUMENT_UPLOAD_ERRORED = "DOCUMENT_UPLOAD_ERRORED";
export const DELETE_DOCUMENT_REQUESTED = "DELETE_DOCUMENT_REQUESTED";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT_ERRORED = "DELETE_DOCUMENT_ERRORED";

export const CREATE_SALES_INVOICE_REQUESTED = "CREATE_SALES_INVOICE_REQUESTED";
export const CREATE_SALES_INVOICE_SUCCESS = "CREATE_SALES_INVOICE_SUCCESS";
export const CREATE_SALES_INVOICE_ERRORED = "CREATE_SALES_INVOICE_ERRORED";
export const GET_SALES_INVOICE_REQUESTED = "GET_SALES_INVOICE_REQUESTED";
export const GET_SALES_INVOICE_SUCCESS = "GET_SALES_INVOICE_SUCCESS";
export const GET_SALES_INVOICE_ERRORED = "GET_SALES_INVOICE_ERRORED";
export const GET_SALES_INVOICE_DETAILS_REQUESTED =
  "GET_SALES_INVOICE_DETAILS_REQUESTED";
export const GET_SALES_INVOICE_DETAILS_SUCCESS =
  "GET_SALES_INVOICE_DETAILS_SUCCESS";
export const GET_SALES_INVOICE_DETAILS_ERRORED =
  "GET_SALES_INVOICE_DETAILS_ERRORED";
export const UPDATE_SALES_INVOICE_REQUESTED = "UPDATE_SALES_INVOICE_REQUESTED";
export const UPDATE_SALES_INVOICE_SUCCESS = "UPDATE_SALES_INVOICE_SUCCESS";
export const UPDATE_SALES_INVOICE_ERRORED = "UPDATE_SALES_INVOICE_ERRORED";
export const DELETE_SALES_INVOICE_REQUESTED = "DELETE_SALES_INVOICE_REQUESTED";
export const DELETE_SALES_INVOICE_SUCCESS = "DELETE_SALES_INVOICE_SUCCESS";
export const DELETE_SALES_INVOICE_ERRORED = "DELETE_SALES_INVOICE_ERRORED";
export const RESET_SALES_INVOICE = "RESET_SALES_INVOICE";

export const CREATE_PURCHASE_INVOICE_REQUESTED =
  "CREATE_PURCHASE_INVOICE_REQUESTED";
export const CREATE_PURCHASE_INVOICE_SUCCESS =
  "CREATE_PURCHASE_INVOICE_SUCCESS";
export const CREATE_PURCHASE_INVOICE_ERRORED =
  "CREATE_PURCHASE_INVOICE_ERRORED";
export const GET_PURCHASE_INVOICE_REQUESTED = "GET_PURCHASE_INVOICE_REQUESTED";
export const GET_PURCHASE_INVOICE_SUCCESS = "GET_PURCHASE_INVOICE_SUCCESS";
export const GET_PURCHASE_INVOICE_ERRORED = "GET_PURCHASE_INVOICE_ERRORED";
export const GET_PURCHASE_INVOICE_DETAILS_REQUESTED =
  "GET_PURCHASE_INVOICE_DETAILS_REQUESTED";
export const GET_PURCHASE_INVOICE_DETAILS_SUCCESS =
  "GET_PURCHASE_INVOICE_DETAILS_SUCCESS";
export const GET_PURCHASE_INVOICE_DETAILS_ERRORED =
  "GET_SALES_INVOICE_DETAILS_ERRORED";
export const UPDATE_PURCHASE_INVOICE_REQUESTED =
  "UPDATE_PURCHASE_INVOICE_REQUESTED";
export const UPDATE_PURCHASE_INVOICE_SUCCESS =
  "UPDATE_PURCHASE_INVOICE_SUCCESS";
export const UPDATE_PURCHASE_INVOICE_ERRORED =
  "UPDATE_PURCHASE_INVOICE_ERRORED";
export const DELETE_PURCHASE_INVOICE_REQUESTED =
  "DELETE_PURCHASE_INVOICE_REQUESTED";
export const DELETE_PURCHASE_INVOICE_SUCCESS =
  "DELETE_PURCHASE_INVOICE_SUCCESS";
export const DELETE_PURCHASE_INVOICE_ERRORED =
  "DELETE_PURCHASE_INVOICE_ERRORED";
export const RESET_PURCHASE_INVOICE = "RESET_PURCHASE_INVOICE";

export const CREATE_QUOTATION_REQUESTED = "CREATE_QUOTATION_REQUESTED";
export const CREATE_QUOTATION_SUCCESS = "CREATE_QUOTATION_SUCCESS";
export const CREATE_QUOTATION_ERRORED = "CREATE_QUOTATION_ERRORED";
export const GET_QUOTATION_REQUESTED = "GET_QUOTATION_REQUESTED";
export const GET_QUOTATION_SUCCESS = "GET_QUOTATION_SUCCESS";
export const GET_QUOTATION_ERRORED = "GET_QUOTATION_ERRORED";
export const GET_QUOTATION_DETAILS_REQUESTED =
  "GET_QUOTATION_DETAILS_REQUESTED";
export const GET_QUOTATION_DETAILS_SUCCESS = "GET_QUOTATION_DETAILS_SUCCESS";
export const GET_QUOTATION_DETAILS_ERRORED = "GET_QUOTATION_DETAILS_ERRORED";
export const UPDATE_QUOTATION_REQUESTED = "UPDATE_QUOTATION_REQUESTED";
export const UPDATE_QUOTATION_SUCCESS = "UPDATE_QUOTATION_SUCCESS";
export const UPDATE_QUOTATION_ERRORED = "UPDATE_QUOTATION_ERRORED";
export const DELETE_QUOTATION_REQUESTED = "DELETE_QUOTATION_REQUESTED";
export const DELETE_QUOTATION_SUCCESS = "DELETE_QUOTATION_SUCCESS";
export const DELETE_QUOTATION_ERRORED = "DELETE_QUOTATION_ERRORED";

export const CREATE_TRANSACTION_REQUESTED = "CREATE_TRANSACTION_REQUESTED";
export const CREATE_TRANSACTION_SUCCESS = "CREATE_TRANSACTION_SUCCESS";
export const CREATE_TRANSACTION_ERRORED = "CREATE_TRANSACTION_ERRORED";
export const GET_TRANSACTION_DETAILS_REQUESTED =
  "GET_TRANSACTION_DETAILS_REQUESTED";
export const GET_TRANSACTION_DETAILS_SUCCESS =
  "GET_TRANSACTION_DETAILS_SUCCESS";
export const GET_TRANSACTION_DETAILS_ERRORED =
  "GET_TRANSACTION_DETAILS_ERRORED";
export const GET_TRANSACTION_REQUESTED = "GET_TRANSACTION_REQUESTED";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_ERRORED = "GET_TRANSACTION_ERRORED";
export const UPDATE_TRANSACTION_REQUESTED = "UPDATE_TRANSACTION_REQUESTED";
export const UPDATE_TRANSACTION_SUCCESS = "UPDATE_TRANSACTION_SUCCESS";
export const UPDATE_TRANSACTION_ERRORED = "UPDATE_TRANSACTION_ERRORED";
export const DELETE_TRANSACTION_REQUESTED = "DELETE_TRANSACTION_REQUESTED";
export const DELETE_TRANSACTION_SUCCESS = "DELETE_TRANSACTION_SUCCESS";
export const DELETE_TRANSACTION_ERRORED = "DELETE_TRANSACTION_ERRORED";
export const RESET_TRANSACTION = "RESET_TRANSACTION";

export const CREATE_SALES_RETURN_REQUESTED = "CREATE_SALES_RETURN_REQUESTED";
export const CREATE_SALES_RETURN_SUCCESS = "CREATE_SALES_RETURN_SUCCESS";
export const CREATE_SALES_RETURN_ERRORED = "CREATE_SALES_RETURN_ERRORED";
export const GET_SALES_RETURN_REQUESTED = "GET_SALES_RETURN_REQUESTED";
export const GET_SALES_RETURN_SUCCESS = "GET_SALES_RETURN_SUCCESS";
export const GET_SALES_RETURN_DETAILS_REQUESTED =
  "GET_SALES_RETURN_DETAILS_REQUESTED";
export const GET_SALES_RETURN_DETAILS_SUCCESS =
  "GET_SALES_RETURN_DETAILS_SUCCESS";
export const GET_SALES_RETURN_DETAILS_ERRORED =
  "GET_SALES_RETURN_DETAILS_ERRORED";

export const GET_SALES_RETURN_ERRORED = "GET_SALES_RETURN_ERRORED";
export const UPDATE_SALES_RETURN_REQUESTED = "UPDATE_SALES_RETURN_REQUESTED";
export const UPDATE_SALES_RETURN_SUCCESS = "UPDATE_SALES_RETURN_SUCCESS";
export const UPDATE_SALES_RETURN_ERRORED = "UPDATE_SALES_RETURN_ERRORED";
export const DELETE_SALES_RETURN_REQUESTED = "DELETE_SALES_RETURN_REQUESTED";
export const DELETE_SALES_RETURN_SUCCESS = "DELETE_SALES_RETURN_SUCCESS";
export const DELETE_SALES_RETURN_ERRORED = "DELETE_SALES_RETURN_ERRORED";

export const CREATE_PURCHASE_RETURN_REQUESTED =
  "CREATE_PURCHASE_RETURN_REQUESTED";
export const CREATE_PURCHASE_RETURN_SUCCESS = "CREATE_PURCHASE_RETURN_SUCCESS";
export const CREATE_PURCHASE_RETURN_ERRORED = "CREATE_PURCHASE_RETURN_ERRORED";
export const GET_PURCHASE_RETURN_REQUESTED = "GET_PURCHASE_RETURN_REQUESTED";
export const GET_PURCHASE_RETURN_SUCCESS = "GET_PURCHASE_RETURN_SUCCESS";
export const GET_PURCHASE_RETURN_ERRORED = "GET_PURCHASE_RETURN_ERRORED";
export const GET_PURCHASE_RETURN_DETAILS_ERRORED =
  "GET_PURCHASE_RETURN_DETAILS_ERRORED";
export const GET_PURCHASE_RETURN_DETAILS_REQUESTED =
  "GET_PURCHASE_RETURN_DETAILS_REQUESTED";
export const GET_PURCHASE_RETURN_DETAILS_SUCCESS =
  "GET_PURCHASE_RETURN_DETAILS_SUCCESS";
export const UPDATE_PURCHASE_RETURN_REQUESTED =
  "UPDATE_PURCHASE_RETURN_REQUESTED";
export const UPDATE_PURCHASE_RETURN_SUCCESS = "UPDATE_PURCHASE_RETURN_SUCCESS";
export const UPDATE_PURCHASE_RETURN_ERRORED = "UPDATE_PURCHASE_RETURN_ERRORED";
export const DELETE_PURCHASE_RETURN_REQUESTED =
  "DELETE_PURCHASE_RETURN_REQUESTED";
export const DELETE_PURCHASE_RETURN_SUCCESS = "DELETE_PURCHASE_RETURN_SUCCESS";
export const DELETE_PURCHASE_RETURN_ERRORED = "DELETE_PURCHASE_RETURN_ERRORED";

export const CREATE_CREDIT_NOTE_REQUESTED = "CREATE_CREDIT_NOTE_REQUESTED";
export const CREATE_CREDIT_NOTE_SUCCESS = "CREATE_CREDIT_NOTE_SUCCESS";
export const CREATE_CREDIT_NOTE_ERRORED = "CREATE_CREDIT_NOTE_ERRORED";
export const GET_CREDIT_NOTE_REQUESTED = "GET_CREDIT_NOTE_REQUESTED";
export const GET_CREDIT_NOTE_SUCCESS = "GET_CREDIT_NOTE_SUCCESS";
export const GET_CREDIT_NOTE_ERRORED = "GET_CREDIT_NOTE_ERRORED";
export const GET_CREDIT_NOTE_DETAILS_ERRORED =
  "GET_CREDIT_NOTE_DETAILS_ERRORED";
export const GET_CREDIT_NOTE_DETAILS_REQUESTED =
  "GET_CREDIT_NOTE_DETAILS_REQUESTED";
export const GET_CREDIT_NOTE_DETAILS_SUCCESS =
  "GET_CREDIT_NOTE_DETAILS_SUCCESS";
export const UPDATE_CREDIT_NOTE_REQUESTED = "UPDATE_CREDIT_NOTE_REQUESTED";
export const UPDATE_CREDIT_NOTE_SUCCESS = "UPDATE_CREDIT_NOTE_SUCCESS";
export const UPDATE_CREDIT_NOTE_ERRORED = "UPDATE_CREDIT_NOTE_ERRORED";
export const DELETE_CREDIT_NOTE_REQUESTED = "DELETE_CREDIT_NOTE_REQUESTED";
export const DELETE_CREDIT_NOTE_SUCCESS = "DELETE_CREDIT_NOTE_SUCCESS";
export const DELETE_CREDIT_NOTE_ERRORED = "DELETE_CREDIT_NOTE_ERRORED";

export const CREATE_DEBIT_NOTE_REQUESTED = "CREATE_DEBIT_NOTE_REQUESTED";
export const CREATE_DEBIT_NOTE_SUCCESS = "CREATE_DEBIT_NOTE_SUCCESS";
export const CREATE_DEBIT_NOTE_ERRORED = "CREATE_DEBIT_NOTE_ERRORED";
export const GET_DEBIT_NOTE_REQUESTED = "GET_DEBIT_NOTE_REQUESTED";
export const GET_DEBIT_NOTE_SUCCESS = "GET_DEBIT_NOTE_SUCCESS";
export const GET_DEBIT_NOTE_ERRORED = "GET_DEBIT_NOTE_ERRORED";
export const GET_DEBIT_NOTE_DETAILS_REQUESTED =
  "GET_DEBIT_NOTE_DETAILS_REQUESTED";
export const GET_DEBIT_NOTE_DETAILS_SUCCESS = "GET_DEBIT_NOTE_DETAILS_SUCCESS";
export const GET_DEBIT_NOTE_DETAILS_ERRORED = "GET_DEBIT_NOTE_DETAILS_ERRORED";
export const UPDATE_DEBIT_NOTE_REQUESTED = "UPDATE_DEBIT_NOTE_REQUESTED";
export const UPDATE_DEBIT_NOTE_SUCCESS = "UPDATE_DEBIT_NOTE_SUCCESS";
export const UPDATE_DEBIT_NOTE_ERRORED = "UPDATE_DEBIT_NOTE_ERRORED";
export const DELETE_DEBIT_NOTE_REQUESTED = "DELETE_DEBIT_NOTE_REQUESTED";
export const DELETE_DEBIT_NOTE_SUCCESS = "DELETE_DEBIT_NOTE_SUCCESS";
export const DELETE_DEBIT_NOTE_ERRORED = "DELETE_DEBIT_NOTE_ERRORED";

export const CREATE_PURCHASE_ORDER_REQUESTED =
  "CREATE_PURCHASE_ORDER_REQUESTED";
export const CREATE_PURCHASE_ORDER_SUCCESS = "CREATE_PURCHASE_ORDER_SUCCESS";
export const CREATE_PURCHASE_ORDER_ERRORED = "CREATE_PURCHASE_ORDER_ERRORED";
export const GET_PURCHASE_ORDER_REQUESTED = "GET_PURCHASE_ORDER_REQUESTED";
export const GET_PURCHASE_ORDER_SUCCESS = "GET_PURCHASE_ORDER_SUCCESS";
export const GET_PURCHASE_ORDER_ERRORED = "GET_PURCHASE_ORDER_ERRORED";
export const GET_PURCHASE_ORDER_DETAILS_REQUESTED =
  "GET_PURCHASE_ORDER_DETAILS_REQUESTED";
export const GET_PURCHASE_ORDER_DETAILS_SUCCESS =
  "GET_PURCHASE_ORDER_DETAILS_SUCCESS";
export const GET_PURCHASE_ORDER_DETAILS_ERRORED =
  "GET_PURCHASE_ORDER_DETAILS_ERRORED";
export const UPDATE_PURCHASE_ORDER_REQUESTED =
  "UPDATE_PURCHASE_ORDER_REQUESTED";
export const UPDATE_PURCHASE_ORDER_SUCCESS = "UPDATE_PURCHASE_ORDER_SUCCESS";
export const UPDATE_PURCHASE_ORDER_ERRORED = "UPDATE_PURCHASE_ORDER_ERRORED";
export const DELETE_PURCHASE_ORDER_REQUESTED =
  "DELETE_PURCHASE_ORDER_REQUESTED";
export const DELETE_PURCHASE_ORDER_SUCCESS = "DELETE_PURCHASE_ORDER_SUCCESS";
export const DELETE_PURCHASE_ORDER_ERRORED = "DELETE_PURCHASE_ORDER_ERRORED";
