import {
  CREATE_SALES_RETURN_REQUESTED,
  CREATE_SALES_RETURN_SUCCESS,
  CREATE_SALES_RETURN_ERRORED,
  GET_SALES_RETURN_REQUESTED,
  GET_SALES_RETURN_SUCCESS,
  GET_SALES_RETURN_ERRORED,
  GET_SALES_RETURN_DETAILS_REQUESTED,
  GET_SALES_RETURN_DETAILS_SUCCESS,
  GET_SALES_RETURN_DETAILS_ERRORED,
  UPDATE_SALES_RETURN_REQUESTED,
  UPDATE_SALES_RETURN_SUCCESS,
  UPDATE_SALES_RETURN_ERRORED,
  DELETE_SALES_RETURN_REQUESTED,
  DELETE_SALES_RETURN_SUCCESS,
  DELETE_SALES_RETURN_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_sales_return_requested = () => ({
  type: CREATE_SALES_RETURN_REQUESTED,
});

export const create_sales_return_success = () => ({
  type: CREATE_SALES_RETURN_SUCCESS,
});

export const create_sales_return_errored = (error) => ({
  type: CREATE_SALES_RETURN_ERRORED,
  error,
});

export const get_sales_return_requested = () => ({
  type: GET_SALES_RETURN_REQUESTED,
});

export const get_sales_return_success = (payload) => ({
  type: GET_SALES_RETURN_SUCCESS,
  payload,
});

export const get_sales_return_errored = (error) => ({
  type: GET_SALES_RETURN_ERRORED,
  error,
});

export const get_sales_return_details_requested = () => ({
  type: GET_SALES_RETURN_DETAILS_REQUESTED,
});

export const get_sales_return_details_success = (payload) => ({
  type: GET_SALES_RETURN_DETAILS_SUCCESS,
  payload,
});

export const get_sales_return_details_errored = (error) => ({
  type: GET_SALES_RETURN_DETAILS_ERRORED,
  error,
});

export const update_sales_return_requested = () => ({
  type: UPDATE_SALES_RETURN_REQUESTED,
});

export const update_sales_return_success = (payload) => ({
  type: UPDATE_SALES_RETURN_SUCCESS,
  payload,
});

export const update_sales_return_errored = (error) => ({
  type: UPDATE_SALES_RETURN_ERRORED,
  error,
});
export const delete_sales_return_requested = () => ({
  type: DELETE_SALES_RETURN_REQUESTED,
});

export const delete_sales_return_success = (payload) => ({
  type: DELETE_SALES_RETURN_SUCCESS,
  payload,
});

export const delete_sales_return_errored = (error) => ({
  type: DELETE_SALES_RETURN_ERRORED,
  error,
});

export const create_sales_return =
  (payload, activeOuid) => async (dispatch) => {
    dispatch(create_sales_return_requested());
    console.log(`Create sales return`);
    console.log("activeOuid", activeOuid);
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/salesreturns`;
      const method = "post";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("sales return created successfully");
          dispatch(
            showSnackbar("sales return created successfully", "success")
          );
          dispatch(create_sales_return_success(response));
          dispatch(get_sales_return(activeOuid));
        })
        .catch((err) => {
          console.log("sales return creation Failed");
          // console.error('ErrorLog : ', JSON.stringify(err));
          dispatch(showSnackbar("sales return creation Failed", "error"));
          dispatch(create_sales_return_errored(err));
        });
    }
    return null;
  };

export const get_sales_return = (activeOuid) => async (dispatch) => {
  dispatch(get_sales_return_requested());
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`accountsApiEndpoint`, accountsApiEndpoint);
  console.log(`credentials`, credentials);

  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/salesreturns`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get sales return Success", response.data);
        dispatch(get_sales_return_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get sales return Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get sales return Failed", "error"));
        }
        dispatch(get_sales_return_errored(err));
      });
  }
  return null;
};

export const get_sales_return_details =
  (salesReturnId, activeOuid) => async (dispatch) => {
    dispatch(get_sales_return_details_requested());
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    console.log(`accountsApiEndpoint`, accountsApiEndpoint);
    console.log(`credentials`, credentials);

    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/salesreturns/id/${salesReturnId}`;
      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get sales return Success", response.data);
          dispatch(get_sales_return_details_success(response.data));
        })
        .catch((err) => {
          console.log("Get sales return Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get sales return Failed", "error"));
          }
          dispatch(get_sales_return_details_errored(err));
        });
    }
    return null;
  };

export const update_sales_return =
  (payload, salesReturnId, activeOuid) => async (dispatch) => {
    dispatch(update_sales_return_requested());
    console.log(`update_sales_return`);

    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/salesreturns/id/${salesReturnId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("sales return Updation Success");
          dispatch(
            showSnackbar("sales return Updated Successfully", "success")
          );
          dispatch(update_sales_return_success());
          dispatch(get_sales_return(activeOuid));
        })
        .catch((err) => {
          console.log("sales return updation Failed");
          dispatch(showSnackbar("sales return updation Failed", "error"));
          dispatch(update_sales_return_errored(err));
        });
    }
    return null;
  };

export const delete_sales_return =
  (activeOuid, meetingId) => async (dispatch) => {
    dispatch(delete_sales_return_requested());
    console.log(`delete_note`);
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/salesreturns/id/${meetingId}`;
      const method = "delete";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Delete sales return Success");
          dispatch(
            showSnackbar("sales return deleted successfully", "success")
          );
          dispatch(delete_sales_return_success());
          dispatch(get_sales_return("", activeOuid));
        })
        .catch((err) => {
          if (err.response) {
            console.log(
              "Delete sales return Failed :",
              JSON.stringify(err.response.data)
            );
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Delete sales return", "error"));
          }
          dispatch(delete_sales_return_errored(err));
        });
    }
    return null;
  };
