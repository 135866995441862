import React from "react";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import { useEffect, useState } from "react";
import { history } from "../../../reducers";
import Loader from "../../common/Loader";
import { get_parties_details } from "./actions/action-parties";
import { Avatar, Drawer } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";

// import editicon from "./images/edit.svg";
// import deleteicon from "./images/delete.svg";
import EditIcon from "@material-ui/icons/Edit";

const store = configureStore();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
  },
  attendanceButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  tabs: {
    background: "#fff",
    boxShadow: "none",
  },
  tab: {
    borderBottom: "1.98502px solid #4B4DED",
  },
  tabpanel: {
    padding: "0px",
  },
  tabHead: {
    boxShadow: "none",
    borderTop: "1px solid #eee",
  },
  avatar: {
    textTransform: "uppercase",
  },
}));

function PartiesDetails() {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { partiesDetails, showGlobalLoader } = useSelector(
    (state) => state.parties
  );
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  useEffect(() => {
    let partyId = window.location.pathname.split("/");
    partyId = partyId[3];
    console.log("partyId", partyId);

    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_parties_details(partyId, activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle=" Parties Detail" />
      <div className="AppPage container--narrow mt FBAppPage">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <>
            <div className="w_box mb">
              <div className="profile_top">
                <div className="profile_left">
                  <div className="profile_img">
                    <Avatar
                      className={classes.avatar}
                      style={{
                        backgroundColor: "#214B87",
                      }}
                    >
                      {partiesDetails.name && partiesDetails.name.charAt(0)}
                    </Avatar>
                  </div>
                  <div className="profile_details">
                    <h6 className="profile_name">{partiesDetails.name}</h6>
                    <div className="profile_party_type">
                      {partiesDetails.type && partiesDetails.type}
                    </div>
                  </div>
                </div>
                <div className="profile_right">
                  <button
                    className="option_btn"
                    onClick={() => setIsDrawerOPen(true)}
                  >
                    <MoreVertIcon />
                  </button>
                  <Drawer
                    className="edit_option_btn_drawer"
                    anchor="bottom"
                    open={isDrawerOpen}
                    onClose={() => setIsDrawerOPen(false)}
                  >
                    <div className="edit_option_head">options</div>
                    <ul className="edit_option_tools_wrap">
                      <li
                        className="edit_option_tools_item"
                        onClick={() => {
                          setIsDrawerOPen(false);
                        }}
                      >
                        <EditIcon
                          onClick={() =>
                            history.push(
                              `/${activeOuid}/edit-parties/${partiesDetails.id}`
                            )
                          }
                        />
                        <span className="edit_option_tools_item_text">
                          Edit
                        </span>
                      </li>
                    </ul>
                  </Drawer>
                </div>
              </div>
              <div className="profile_party_desc">
                {partiesDetails.description && partiesDetails.description}
              </div>
            </div>
            <div>
              <AppBar
                position="static"
                color="default"
                className={classes.tabHead}
              >
                <Tabs
                  className={classes.tabs}
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  aria-label="full width tabs example"
                >
                  <Tab
                    className={classes.tab}
                    label="Bussiness Info"
                    {...a11yProps(0)}
                  />
                  <Tab
                    className={classes.tab}
                    label="Credit Info"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel
                  value={value}
                  index={0}
                  dir={theme.direction}
                  className={classes.tabpanel}
                >
                  <div className="w_box mb">
                    <div className="profile_party_info">
                      <div className="profile_party_info_label">Phone :</div>

                      {partiesDetails.phone && partiesDetails.phone}
                    </div>
                    <div className="profile_party_info">
                      <div className="profile_party_info_label">
                        GST Number :
                      </div>

                      {partiesDetails.businessInfo &&
                        partiesDetails.businessInfo.gstNumber}
                    </div>
                    <div className="profile_party_info">
                      <div className="profile_party_info_label">
                        PAN Number :
                      </div>

                      {partiesDetails.businessInfo &&
                        partiesDetails.businessInfo.panNumber}
                    </div>
                    <div className="profile_party_info">
                      <div className="profile_party_info_label">
                        Billing Address :
                      </div>

                      {partiesDetails.businessInfo &&
                        partiesDetails.businessInfo.billingAddress}
                    </div>
                    <div className="profile_party_info">
                      <div className="profile_party_info_label">State :</div>

                      {partiesDetails.businessInfo &&
                        partiesDetails.businessInfo.state}
                    </div>
                  </div>
                </TabPanel>
                <TabPanel
                  value={value}
                  index={1}
                  dir={theme.direction}
                  className={classes.tabpanel}
                >
                  <div className="w_box mb">
                    <div className="profile_party_info">
                      <div className="profile_party_info_label">
                        Credit Limit :
                      </div>
                      {partiesDetails.creditInfo &&
                        partiesDetails.creditInfo.creditLimit}
                    </div>
                    <div className="profile_party_info">
                      <div className="profile_party_info_label">
                        Credit Info :
                      </div>

                      {partiesDetails.creditInfo &&
                        partiesDetails.creditInfo.creditType}
                    </div>
                    <div className="profile_party_info">
                      <div className="profile_party_info_label">
                        Opening Credit :
                      </div>

                      {partiesDetails.creditInfo &&
                        partiesDetails.creditInfo.openingCredit}
                    </div>
                  </div>
                </TabPanel>
              </SwipeableViews>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PartiesDetails;
