import React from "react";
import Loader from "../../common/Loader";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import { useEffect, useState } from "react";
import { history } from "../../../reducers";
// import editicon from "./images/edit.svg";
// import deleteicon from "./images/delete.svg";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { delete_item, get_item_details } from "./actions/action-items";
import { Avatar, Drawer } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { convertToRs } from "./common/moneyConverter";
import DeleteIcon from "@material-ui/icons/Delete";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: "0 auto",
  },
  attendanceButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  tabs: {
    background: "#fff",
    boxShadow: "none",
  },
  tab: {
    borderBottom: "1.98502px solid #4B4DED",
  },
  tabpanel: {
    padding: "0px",
  },
  tabHead: {
    boxShadow: "none",
    borderTop: "1px solid #eee",
  },
  avatar: {
    textTransform: "uppercase",
  },
}));

function ItemDetails() {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { itemDetails, showGlobalLoader } = useSelector((state) => state.items);
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);

  useEffect(() => {
    let itemId = window.location.pathname.split("/");
    itemId = itemId[3];
    console.log("itemId", itemId);

    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_item_details(itemId, activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  const handleDetele = () => {
    console.log("item delete", itemDetails.id);
    dispatch(delete_item(activeOuid, itemDetails.id));
    history.push(`/${activeOuid}/items`);
  };

  return (
    <div>
      <HeaderInner pageTitle=" Item Detail" />
      <div className="AppPage container--narrow mt FBAppPage">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <>
            <div className="w_box mb">
              <div className="profile_top">
                <div className="profile_left">
                  <div className="profile_img">
                    <Avatar
                      className={classes.avatar}
                      style={{
                        backgroundColor: "#214B87",
                      }}
                    >
                      {itemDetails.name && itemDetails.name.charAt(0)}
                    </Avatar>
                  </div>
                  <div className="profile_details">
                    <h6 className="profile_name">{itemDetails.name}</h6>
                    <div className="profile_party_type">
                      {itemDetails.type && itemDetails.type}
                    </div>
                  </div>
                </div>
                <div className="profile_right">
                  <button
                    className="option_btn"
                    onClick={() => setIsDrawerOPen(true)}
                  >
                    <MoreVertIcon />
                  </button>
                  <Drawer
                    className="edit_option_btn_drawer"
                    anchor="bottom"
                    open={isDrawerOpen}
                    onClose={() => setIsDrawerOPen(false)}
                  >
                    <div className="edit_option_head">options</div>
                    <ul className="edit_option_tools_wrap">
                      <li
                        className="edit_option_tools_item"
                        onClick={() => {
                          setIsDrawerOPen(false);
                        }}
                      >
                        <EditIcon
                          onClick={() =>
                            history.push(
                              `/${activeOuid}/edit-item/${itemDetails.id}`
                            )
                          }
                        />
                        <span className="edit_option_tools_item_text">
                          Edit
                        </span>
                      </li>
                      <li
                        className="edit_option_tools_item"
                        onClick={() => setIsDrawerOPen(false)}
                      >
                        <DeleteIcon onClick={() => handleDetele()} />
                        <span className="edit_option_tools_item_text">
                          Delete
                        </span>
                      </li>
                    </ul>
                  </Drawer>
                </div>
              </div>
              <div className="profile_party_desc mt">
                Description :
                {itemDetails.description && itemDetails.description}
              </div>
            </div>
            <div className="w_box mb">
              <div className="item_sub_details_head">Pricing</div>
              <div className="profile_party_info">
                <div className="profile_party_info_label">GST :</div>
                {itemDetails.pricing && itemDetails.pricing.gst} %
              </div>
              <div className="profile_party_info">
                <div className="profile_party_info_label">Purchase Price :</div>
                ₹
                {itemDetails.pricing &&
                  convertToRs(itemDetails.pricing.purchasePrice)}
              </div>
              <div className="profile_party_info">
                <div className="profile_party_info_label">Sales Price :</div>₹
                {itemDetails.pricing &&
                  convertToRs(itemDetails.pricing.salesPrice)}
              </div>
              <div className="item_sub_details_head mt">Stock</div>
              <div className="profile_party_info">
                <div className="profile_party_info_label">Current Stock :</div>
                {itemDetails.stock && itemDetails.stock.currentStock}
              </div>
              <div className="profile_party_info">
                <div className="profile_party_info_label">Item Code :</div>
                {itemDetails.stock && itemDetails.stock.itemCode}
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default ItemDetails;
