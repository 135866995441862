import React, { useEffect } from "react";
import InvoiceCard from "./common/InvoiceCard";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { get_purchase_order } from "./actions/action-purchaseOrder";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from "../../../reducers";
import AddIcon from "@material-ui/icons/Add";
import PurchaseOrderCard from "./common/PurchaseOrderCard";

const store = configureStore();

function PurchaseOrderList() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { purchaseOrder, showGlobalLoader } = useSelector(
    (state) => state.purchaseOrder
  );

  function renderList(purchaseOrder) {
    if (purchaseOrder) {
      return purchaseOrder.map((item) => (
        <PurchaseOrderCard key={item.id} item={item} />
      ));
    } else {
      return <>No purchase Order found</>;
    }
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_purchase_order(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Purchase Orders " />
      <div className=" HrmAppPage container--narrow mt FBAppPage">
        {showGlobalLoader ? <Loader /> : renderList(purchaseOrder)}

        <div className="AppFloatingButtonWrapper t-r">
          <button
            className="round_btn bg_blue "
            onClick={() => history.push(`/${activeOuid}/create-purchase-order`)}
          >
            <AddIcon />
          </button>
        </div>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}

export default PurchaseOrderList;
