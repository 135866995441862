import React from "react";
import HeaderInner from "./HeaderInner";
import { history } from "../../..//reducers";
import CommonSnackBar from "../../common/CommonSnackBar";
import Footer from "./Footer";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import configureStore from "../../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import { Button, TextField } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { create_items } from "./actions/action-items";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import { convertToPaisa, convertToRs } from "./common/moneyConverter";

const store = configureStore();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  Select: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  formControl: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  attendanceButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  tabs: {
    background: "#fff",
    boxShadow: "none",
  },
  tab: {
    borderBottom: "1.98502px solid #4B4DED",
  },
  tabpanel: {
    padding: "0px",
  },
  tabHead: {
    boxShadow: "none",
    borderTop: "1px solid #eee",
  },
  toggleWrap: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "30px",
  },
  toggle: {
    marginRight: "10px",
    padding: "8px 10px",
    border: "2px solid #9D00C6 !important",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "100px !important",
    color: "#9D00C6",
    textAlign: "center",
    background: "#fff",
    minWidth: "50px",
    fontSize: " 10px",
    "&.Mui-selected": {
      backgroundColor: "#9d00c6 ",
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#9d00c6 !important",
      color: "#fff",
    },
  },
}));

export default function CreateItem() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { activeOuid, activeOuidChain } = store.getState().permission;
  const { documentUrl, showUploadLoader } = store.getState().document;

  const [value, setValue] = React.useState(0);
  const [item, setItem] = React.useState("Product");
  const [tax, setTax] = React.useState("with Tax");

  const [state, setState] = React.useState({
    name: "",
    type: item,
    pricing: {
      gst: "0",
      salesPrice: "0",
      purchasePrice: "0",
      priceWithTax: true,
    },
    stock: {
      openingStock: "",
      itemCode: "",
    },
    image: "",
    description: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleFieldChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
    console.log("State", state);
  };

  const handleItemTypeChange = (event, newitem) => {
    setItem(newitem);
    setState({ ...state, type: newitem });
    console.log("newitem", newitem);
  };

  const handlePricingChange = (event) => {
    setState({
      ...state,
      pricing: {
        ...state.pricing,
        [event.target.id]: `${event.target.value}`,
      },
    });
    console.log("state", state);
  };

  const handleStockChange = (event) => {
    setState({
      ...state,
      stock: {
        ...state.stock,
        [event.target.id]: `${event.target.value}`,
      },
    });
    console.log("state", state);
  };

  const handleOtherDetailsChange = (event) => {
    setState({
      ...state,
      businessInfo: {
        ...state.businessInfo,
        [event.target.id]: event.target.value,
      },
    });
    console.log("task", state);
  };

  const handleTaxChange = (event, newTax) => {
    setTax(newTax);
    setState({
      ...state,
      pricing: {
        ...state.pricing,
        priceWithTax: newTax,
      },
    });
  };

  const handleSubmit = () => {
    var payload = state;
    payload.pricing.salesPrice = convertToPaisa(payload.pricing.salesPrice);
    payload.pricing.purchasePrice = convertToPaisa(
      payload.pricing.purchasePrice
    );
    console.log("Item creation", payload);
    dispatch(create_items(state, activeOuid));
    history.push(`/${activeOuid}/items`);
  };

  return (
    <div>
      <HeaderInner pageTitle="Create Items" />
      <div className="AppPage container--narrow mt FBAppPage">
        <form className="add_wrap" onSubmit={handleSubmit}>
          <div className="w_box mb">
            <TextField
              id="name"
              label="Item name"
              fullWidth
              variant="outlined"
              className={classes.input}
              onChange={handleFieldChange}
              required
            />
            <div>
              <div>Item Type</div>

              <ToggleButtonGroup
                color="primary"
                value={item}
                exclusive
                onChange={handleItemTypeChange}
                className={classes.toggleWrap}
              >
                <ToggleButton
                  className={classes.toggle}
                  value="Product"
                  aria-label="Product"
                >
                  Product
                </ToggleButton>
                <ToggleButton
                  className={classes.toggle}
                  value="Services"
                  aria-label="Services"
                >
                  Services
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>

          <AppBar position="static" color="default" className={classes.tabHead}>
            <Tabs
              className={classes.tabs}
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab className={classes.tab} label="Pricing" {...a11yProps(0)} />
              <Tab className={classes.tab} label="Stock" {...a11yProps(1)} />
              <Tab
                className={classes.tab}
                label="Other Details"
                {...a11yProps(2)}
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel
              value={value}
              index={0}
              dir={theme.direction}
              className={classes.tabpanel}
            >
              <div className="w_box mb">
                <TextField
                  id="gst"
                  label="GST %"
                  fullWidth
                  variant="outlined"
                  className={classes.input}
                  onChange={handlePricingChange}
                  defaultValue={state.pricing.gst}
                  required
                />
                <CurrencyTextField
                  id="salesPrice"
                  label="Sales price"
                  variant="outlined"
                  fullWidth
                  className={classes.input}
                  // value={state.pricing && state.pricing.salesPrice}
                  currencySymbol="₹"
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  onChange={handlePricingChange}
                  defaultValue={state.pricing.salesPrice}
                  required
                />
                <CurrencyTextField
                  id="purchasePrice"
                  label="Purchase Price"
                  variant="outlined"
                  fullWidth
                  className={classes.input}
                  // value={state.pricing && state.pricing.purchasePrice}
                  currencySymbol="₹"
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter="."
                  digitGroupSeparator=","
                  onChange={handlePricingChange}
                  defaultValue={state.pricing.purchasePrice}
                  required
                />
                {/* <div>
                <ToggleButtonGroup
                  color="primary"
                  value={tax}
                  exclusive
                  onChange={handleTaxChange}
                  className={classes.toggleWrap}
                >
                  <ToggleButton className={classes.toggle} value="with Tax">
                    with Tax
                  </ToggleButton>
                  <ToggleButton className={classes.toggle} value="without">
                    without
                  </ToggleButton>
                </ToggleButtonGroup>
              </div> */}
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              index={1}
              dir={theme.direction}
              className={classes.tabpanel}
            >
              <div className="w_box mb">
                <TextField
                  id="openingStock"
                  label="Opening Stock"
                  fullWidth
                  variant="outlined"
                  type="number"
                  className={classes.input}
                  onChange={handleStockChange}
                  defaultValue={state.stock.openingStock}
                  required
                />
                <TextField
                  id="itemCode"
                  label="Item code"
                  fullWidth
                  variant="outlined"
                  className={classes.input}
                  onChange={handleStockChange}
                  defaultValue={state.stock.itemCode}
                />
              </div>
            </TabPanel>
            <TabPanel
              value={value}
              index={2}
              dir={theme.direction}
              className={classes.tabpanel}
            >
              <div className="w_box mb">
                <TextField
                  id="description"
                  label="Item Desctription"
                  fullWidth
                  variant="outlined"
                  className={classes.input}
                  onChange={handleFieldChange}
                  defaultValue={state.description}
                  minRows="4"
                  multiline
                  required
                />
              </div>
            </TabPanel>
          </SwipeableViews>
          <div className="AppFloatingButtonWrapper t-c ">
            <button
              className="btn bg_light_green"
              style={{ width: "80%", margin: "0 auto" }}
              // onClick={() => handleSubmit()}
            >
              save
            </button>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
}
