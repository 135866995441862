import {
  CREATE_PARTIES_REQUESTED,
  CREATE_PARTIES_SUCCESS,
  CREATE_PARTIES_ERRORED,
  GET_PARTIES_REQUESTED,
  GET_PARTIES_SUCCESS,
  GET_PARTIES_ERRORED,
  GET_PARTIES_DETAILS_REQUESTED,
  GET_PARTIES_DETAILS_SUCCESS,
  GET_PARTIES_DETAILS_ERRORED,
  UPDATE_PARTIES_REQUESTED,
  UPDATE_PARTIES_SUCCESS,
  UPDATE_PARTIES_ERRORED,
  DELETE_PARTIES_REQUESTED,
  DELETE_PARTIES_SUCCESS,
  DELETE_PARTIES_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  parties: [],
  partiesDetails: {},
  error: {},
};

export function parties(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_PARTIES_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_PARTIES_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_PARTIES_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_PARTIES_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_PARTIES_SUCCESS:
      return {
        ...state,
        parties: payload,
        showGlobalLoader: false,
      };

    case GET_PARTIES_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_PARTIES_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_PARTIES_DETAILS_SUCCESS:
      return {
        ...state,
        partiesDetails: payload,
        showGlobalLoader: false,
      };

    case GET_PARTIES_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_PARTIES_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_PARTIES_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        partiesDetails: payload,
      };

    case UPDATE_PARTIES_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_PARTIES_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_PARTIES_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_PARTIES_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    default:
      return state;
  }
}
