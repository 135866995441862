import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import EditIcon from "@material-ui/icons/Edit";
import { Button, Collapse, InputAdornment, MenuItem } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { history } from "../../..//reducers";
import configureStore from "../../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ItemsBillCard from "./common/ItemsBillCard";
import { Drawer, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ItemsCard from "./common/ItemsCard";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { get_parties } from "./actions/action-parties";
import { get_items } from "./actions/action-items";
import CommonSnackBar from "../../common/CommonSnackBar";
import {
  create_quotation,
  get_quotation,
  get_quotation_details,
  update_quotation,
} from "./actions/action-quotation";
import Loader from "../../common/Loader";
import InvoiceItemsCard from "./common/InvoiceItemsCard";
import { convertToPaisa, convertToRs } from "./common/moneyConverter";
import Moment from "react-moment";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  totalInput: {
    padding: "10px 14px",
  },
  Select: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  formControl: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  discountPriceTextField: {
    color: "#31d0aa",
    fontWeight: "600",
    fontSize: "12px",
    width: "70%",
    marginLeft: "auto",
  },
  button: {
    fontWeight: 700,
    fontSize: "16px",
    textAlign: "center",
    color: "#ffffff",
    boxShadow: "0px 6px 2px -4px rgba(14, 14, 44, 0.1)",
    borderRadius: "43px",
  },
  toggleWrap: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "30px",
  },
  toggle: {
    marginRight: "10px",
    padding: "8px 10px",
    border: "2px solid #9D00C6 !important",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "100px !important",
    color: "#9D00C6",
    textAlign: "center",
    background: "#fff",
    minWidth: "80px",
    "&.Mui-selected": {
      backgroundColor: "#9d00c6 !important",
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#9d00c6 !important",
      color: "#fff",
    },
  },
}));

function EditQuotation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);
  const [isEditDrawerOpen, setIsEditDrawerOPen] = useState(false);
  const { quotationDetails, showGlobalLoader } = useSelector(
    (state) => state.quotations
  );
  //   const { quotations } = useSelector((state) => state.quotations);

  const [expanded, setExpanded] = React.useState(true);
  const [received, setReceived] = React.useState(true);

  const [paymentMode, setPaymentMode] = React.useState();

  const { activeOuid } = store.getState().permission;
  const { parties } = useSelector((state) => state.parties);
  const { items } = useSelector((state) => state.items);

  const [state, setState] = React.useState({
    idNumber: "",
    dueDate: "",
    discount: `${0}`,
    totalAmount: "",
    paymentStatus: "",
    party: {
      id: "",
      name: "",
      type: "",
      category: "",
      businessInfo: {
        gstNumber: "",
        panNumber: "",
        billingAddress: "",
      },
    },
    invLines: [],
    payments: [],
  });

  //discount box open
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  //payment receiving box open & create a new payment item
  const handleReceivedClick = (event) => {
    setReceived(!received);
  };

  //onchnage for received payment

  const handlePayment = (event) => {
    let payments = [...state.payments];
    let payment = { ...payments[0] };
    payment.paidAmount = event.target.value;
    payments[0] = payment;

    setState({
      ...state,
      payments,
    });

    console.log("newPay", state);
  };

  //payment mode toggle

  const handlePaymentMode = (event, newPaymentMode) => {
    setPaymentMode(newPaymentMode);
    let payments = [...state.payments];
    let payment = { ...payments[0] };
    payment.paymentMode = newPaymentMode;
    payments[0] = payment;

    setState({
      ...state,
      payments,
    });
    console.log("newMode", state);
  };

  //Rendering parties for the select field

  const renderParties = (parties) => {
    if (parties) {
      return parties.map((item) => (
        <MenuItem
          className={classes.MenuItem}
          key={item.id}
          value={item.id}
          data-my-value={item.name}
        >
          {item.name}
        </MenuItem>
      ));
    }
  };

  //Party name select
  const onSelectChange = (event) => {
    console.log("event", event);
    const { myValue } = event.currentTarget.dataset;
    console.log("Menu click event", myValue);

    setState({
      ...state,
      party: {
        ...state.party,
        id: event.target.value,
        name: myValue,
      },
    });

    console.log("state", state);
  };

  //onchange for state
  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
    console.log("state", state);
  };

  //Items to render in the bill item list
  function renderPurchaseItems() {
    return state.invLines.map((item) => (
      <ItemsBillCard
        key={item.id}
        item={item}
        handleEditChange={handleEditChange}
        increment={increment}
        decrement={decrement}
        handleDeleteitem={handleDeleteitem}
      />
    ));
  }

  //Items to render inside Add item Drawer
  function renderAllItems(items) {
    console.log("itemslist", items);
    console.log("itemslist", state.invLines);

    var itemsFiltered = [];
    if (items != null) {
      const invlinesIds = new Set(state.invLines.map((el) => el.itemId));
      itemsFiltered = items.filter((el) => !invlinesIds.has(el.id));
      console.log("itemsFiltered", itemsFiltered);
    }

    if (items) {
      var listItem = itemsFiltered.map((item) => ({
        itemName: item.name,
        itemId: item.id,
        quantity: 0,
        rate: `${item.pricing.salesPrice}`,
        tax: item.pricing.gst,
      }));
      return listItem.map((item) => (
        <InvoiceItemsCard
          cartCountBtn={true}
          key={item.itemId}
          item={item}
          handleAdditem={handleAdditem}
          increment={increment}
          decrement={decrement}
        />
      ));
    } else {
      return <>No items found</>;
    }
  }

  function renderExistingItems(items) {
    // console.log("itemslist", items);
    if (items) {
      return items.map((item) => (
        <InvoiceItemsCard
          cartCountBtn={true}
          key={item.id}
          item={item}
          handleAdditem={handleAdditem}
          increment={increment}
          decrement={decrement}
        />
      ));
    } else {
      return <>No items found</>;
    }
  }

  //Item Edit Change
  const handleEditChange = (id, event) => {
    const updateditems = state.invLines.find((element) => {
      if (element.itemId === id) {
        var itemPrice = parseFloat(element.rate);
        var itemTax = parseFloat(element.tax);
        var itemTotalTax = (itemTax * itemPrice) / 100;
        var itemTotal = (itemTotalTax + itemPrice) * element.quantity;

        element.rate = event.target.value;
        element.total = itemTotal;

        console.log("element", element);
        console.log("element.rate", element.rate);

        return {
          ...element,
          rate: element.rate,
          total: itemTotal,
        };
      }
    });
    console.log("edit", state);
  };

  //Item Add
  const handleAdditem = (item, count) => {
    var itemPrice = parseFloat(item.rate);
    var itemTax = parseFloat(item.tax);
    var itemTotalTax = (itemTax * itemPrice) / 100;
    var itemTotal = (itemTotalTax + itemPrice) * 1;
    console.log("sumY", itemTotal);

    var newItem = {
      itemName: item.itemName,
      itemId: item.itemId,
      quantity: `${1}`,
      rate: `${item.rate}`,
      tax: `${item.tax}`,
      total: `${itemTotal}`,
    };
    console.log("Addtem", item);
    console.log("newItem", newItem);
    setState({
      ...state,
      invLines: [...state.invLines, newItem],
    });
    console.log("addesstate", state);
    console.log("total", state.items);
  };

  //Item Delete
  const handleDeleteitem = (id) => {
    console.log("id", id);

    const updateditems = state.invLines.filter((element) => {
      if (element.itemId !== id) {
        console.log("element", element);

        return {
          ...element,
        };
      }
    });

    setState({
      ...state,
      invLines: updateditems,
    });
    console.log("newList", updateditems);
    console.log("newListstate", state);
  };

  //Item increment update
  const increment = (id, count) => {
    console.log("id", id);
    console.log("count", count);

    const updateditems = state.invLines.find((element) => {
      if (element.itemId === id) {
        var itemPrice = parseFloat(element.rate);
        var itemTax = parseFloat(element.tax);
        var itemTotalTax = (itemTax * itemPrice) / 100;
        var itemTotal = (itemTotalTax + itemPrice) * (count + 1);

        element.quantity = `${parseFloat(count) + 1}`;
        element.total = `${itemTotal}`;

        console.log("element", element);
        console.log("total", itemTotal);

        return {
          ...element,
          total: `${itemTotal}`,
          quantity: parseFloat(count) + 1,
        };
      }
    });

    console.log("incrementUpdateditems", updateditems);
    console.log("state", state);
  };

  //Item decrement update

  const decrement = (id, count) => {
    console.log("id", id);
    console.log("count", count);

    const updateditems = state.invLines.find((element) => {
      if (element.itemId === id) {
        element.quantity = parseFloat(count) - 1;
        console.log(element);
        return { ...element, quantity: `${parseFloat(count) - 1}` };
      }
    });
    console.log("decrementUpdateditems", updateditems);
    console.log("state", state);
  };

  //Item total calculation

  let itemstotal = state.invLines.reduce(function (prev, current) {
    return parseFloat(prev) + parseFloat(current.total);
  }, 0);
  console.log("itemstotal", itemstotal);

  //Total amount calculation

  let totalAmount =
    parseFloat(itemstotal) -
    (itemstotal * (state.discount ? parseFloat(state.discount) : 0)) / 100;

  //Balance amount calculation

  let paymentlist = [...state.payments];
  let paymentItem = { ...paymentlist[0] };

  let balanceAmount =
    parseFloat(totalAmount) -
    convertToPaisa(parseFloat(paymentItem.paidAmount).toFixed(2));

  //Handle Submit

  const handleSubmit = () => {
    console.log("Handle submit");
    var payload = state;
    payload.totalAmount = `${totalAmount}`;
    console.log("payload", payload);
    dispatch(update_quotation(payload, state.id, activeOuid));
    history.push(`/${activeOuid}/quotations`);
  };

  useEffect(() => {
    let quotationId;
    quotationId = window.location.pathname.split("/");
    quotationId = quotationId[3];
    console.log("quotationId", quotationId);

    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_quotation_details(quotationId, activeOuid));

      await dispatch(get_parties("", activeOuid));
      await dispatch(get_items("", activeOuid));

      setState({
        ...state,
        id: store.getState().quotations.quotationDetails.id,
        totalAmount: store.getState().quotations.quotationDetails.totalAmount,
        invLines: store.getState().quotations.quotationDetails.invLines,
        payments: store.getState().quotations.quotationDetails.payments,
        discount: store.getState().quotations.quotationDetails.discount,
        dueDate: store.getState().quotations.quotationDetails.dueDate,
        idNumber: store.getState().quotations.quotationDetails.idNumber,
        party: store.getState().quotations.quotationDetails.party,
        paymentStatus:
          store.getState().quotations.quotationDetails.paymentStatus,
      });

      setPaymentMode(
        store.getState().quotations.quotationDetails.payments[0].paymentMode
      );
    };

    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Edit Quotation" />
      <div className="AppPage container--narrow mt FBAppPage">
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <>
            <div className="invoice_top_box">
              <div className="invoice_top_box_details">
                <div className="invoice_top_box_title">{state.idNumber}</div>
                <span className="invoice_top_box_desc">
                  <span className="d-inline invoice_top_box_desc_date">
                    <Moment format="D MMM YYYY">
                      {quotationDetails.createdAt && quotationDetails.createdAt}
                    </Moment>
                  </span>
                  7 days to Due
                </span>
              </div>
              <div className="invoice_top_box_edit">
                {/* <button
              className="edit_option_btn"
              onClick={() => setIsEditDrawerOPen(true)}
            >
              <EditIcon />
            </button> */}

                <Drawer
                  className="edit_option_btn_drawer"
                  anchor="bottom"
                  open={isEditDrawerOpen}
                  onClose={() => setIsEditDrawerOPen(false)}
                >
                  <div className="edit_option_head">
                    Edit Invoice
                    <button onClick={() => setIsEditDrawerOPen(false)}>
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="invoice_edit_drawer_wrap">
                    <TextField
                      fullWidth
                      id="date"
                      label="Purchase Order Date "
                      type="date"
                      variant="outlined"
                      defaultValue="2017-05-24"
                      className={classes.input}
                      // onChange={handleDatetimeChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      id="date"
                      label="Validity Date "
                      type="date"
                      variant="outlined"
                      defaultValue="2017-05-24"
                      className={classes.input}
                      // onChange={handleDatetimeChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      fullWidth
                      className={classes.input}
                      id="idNumber"
                      defaultValue="INV001"
                      label="Purchase Order Number "
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </div>
                </Drawer>
              </div>
            </div>
            <div className=" w_box mb " style={{ paddingTop: "20px" }}>
              <TextField
                fullWidth
                className={classes.input}
                id=""
                disabled
                value={state.party.name}
                label="Party Name *"
                onChange={onSelectChange}
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div className="input_icon">
                        <AccountCircle />
                      </div>
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: {
                    textTransform: "capitalize",
                  },
                }}
              >
                {parties && renderParties(parties)}
              </TextField>
            </div>
            <div className=" w_box mb ">
              <div className="add_item_wrap">
                <button
                  className="blue_btn w-100 d-inline mt mb"
                  onClick={() => setIsDrawerOPen(true)}
                >
                  ADD ITEM
                </button>
                <Drawer
                  className="edit_option_btn_drawer"
                  anchor="bottom"
                  open={isDrawerOpen}
                  onClose={() => setIsDrawerOPen(false)}
                >
                  <div className="edit_option_head">
                    Items
                    <button onClick={() => setIsDrawerOPen(false)}>
                      <CloseIcon />
                    </button>
                  </div>
                  <div className="purchase_invoice_item_drawer_wrap">
                    {state.invLines && renderExistingItems(state.invLines)}
                    {renderAllItems(items)}
                  </div>
                </Drawer>
              </div>
            </div>
            <div className="purchase_bill_details_wrap w_box mb ">
              <div className="after_add_item_wrap mb">
                <div className="after_add_item_wrap_label">
                  items ({state.invLines.length > 0 ? state.invLines.length : 0}
                  )
                </div>

                <button
                  className="after_add_item_wrap_btn  d-inline "
                  onClick={() => setIsDrawerOPen(true)}
                >
                  + Add items
                </button>
              </div>
              {renderPurchaseItems()}
              <div className="item_bill_card_bottom">
                <div className="item_bill_card_bottom_label">Item Subtotal</div>
                <div className="item_bill_card_total_price">
                  ₹
                  {convertToRs(`${itemstotal}`) > 0
                    ? convertToRs(`${itemstotal}`)
                    : "0.00"}
                </div>
              </div>
            </div>
            <div className="purchase_discount_btn_wrap mb">
              <button
                className="after_add_item_wrap_btn  d-inline "
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="Discount"
              >
                + Discount
              </button>
            </div>
            <Collapse in={expanded}>
              <div className=" w_box mb purchase_discount_wrap">
                <div className="purchase_discount_label">Discount</div>
                <div className="purchase_discount_values">
                  <div className="purchase_discount_percnt_value">
                    <TextField
                      id="discount"
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                      defaultValue={
                        quotationDetails.discount && quotationDetails.discount
                      }
                      onChange={handleChange}
                    />
                  </div>
                  {/* <div className="purchase_discount_price_value">
                <TextField
                  id="discount"
                  fullWidth
                  className={classes.discountPriceTextField}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                  onChange={handleChange}
                />
              </div> */}
                </div>
              </div>
            </Collapse>
            <div
              className="purchase_invoice_total_wrap w_box mb "
              style={{ paddingTop: "20px" }}
            >
              <div className="item_bill_card_bottom mb">
                <div className="item_bill_card_bottom_label">Total Amount</div>

                <div className="item_bill_card_total_price">
                  ₹
                  {convertToRs(`${totalAmount}`) > 0
                    ? convertToRs(`${totalAmount}`)
                    : "0.00"}
                </div>
              </div>
              <Collapse in={received}>
                <div className="item_bill_card_bottom mb">
                  <div className="item_bill_card_bottom_label">Paid Amount</div>

                  <div className="item_bill_card_total_price">
                    <TextField
                      fullWidth
                      id="paidAmount"
                      className={classes.discountPriceTextField}
                      onChange={handlePayment}
                      variant="outlined"
                      placeholder="0.0"
                      defaultValue={
                        quotationDetails.payments &&
                        quotationDetails.payments[0].paidAmount
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div className="input_icon">₹</div>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        className: classes.totalInput,
                      }}
                    />
                  </div>
                </div>
                <div className="item_bill_card_bottom mt">
                  <div className="item_bill_card_bottom_label">Balance</div>
                  <div className="item_bill_card_total_price">
                    ₹
                    {convertToRs(`${balanceAmount}`) > 0
                      ? convertToRs(`${balanceAmount}`)
                      : convertToRs(`${totalAmount}`)}
                  </div>
                </div>
                <div className="purchase_invoice_payment_mode_wrap mt">
                  <div className="purchase_invoice_payment_label">
                    Payment Mode
                  </div>

                  <ToggleButtonGroup
                    color="primary"
                    value={paymentMode}
                    exclusive
                    onChange={handlePaymentMode}
                    className={classes.toggleWrap}
                  >
                    <ToggleButton
                      className={classes.toggle}
                      value="cash"
                      aria-label="cash"
                    >
                      cash
                    </ToggleButton>
                    <ToggleButton
                      className={classes.toggle}
                      value="cheque"
                      aria-label="cheque"
                    >
                      cheque
                    </ToggleButton>
                    <ToggleButton
                      className={classes.toggle}
                      value="Online"
                      aria-label="Online"
                    >
                      Online
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </Collapse>
            </div>
            <div className="purchase_discount_btn_wrap mb-2">
              <button
                className="after_add_item_wrap_btn  d-inline "
                onClick={() => {
                  handleReceivedClick();
                }}
                aria-expanded={received}
                aria-label="received"
              >
                + Recieved
              </button>
            </div>
            <div className="AppFloatingButtonWrapper t-c">
              <div className="create_btns_wrap" style={{ gap: "5px" }}>
                <Button
                  className={classes.button}
                  style={{ background: "#31d0aa" }}
                  fullWidth
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  Save
                </Button>
                {/* <Button
                  className={classes.button}
                  style={{ background: "#4b4ded" }}
                  fullWidth
                  color="primary"
                  onClick={() => handleSubmit()}
                >
                  BiLL
                </Button> */}
              </div>
            </div>
          </>
        )}
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}

export default EditQuotation;
