import React, { Component } from "react";
import { connect } from "react-redux";
import configureStore from "../../../store/configureStore";

const store = configureStore();

class VirtualCommunityApp extends Component {
  render() {
    const { permissions } = store.getState().permission;
    return <div>Virtual Community App Home</div>;
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  // get_user_profile_details: (username) => dispatch(get_user_profile_details(username)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VirtualCommunityApp);
