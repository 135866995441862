import React from "react";

export function convertToRs(rs) {
  function splitAt(str, index) {
    const fst = [...str];    //convert string into char array
    const snd = fst.splice(fst.length - index); //calc snd element while changing fst
    return [fst.join(''), snd.join('')]; //join char arrays for each tuple item
 }
 rs = splitAt(rs, 2);
 rs = rs.map(e => e).join('.')
  return rs;
}

export function convertToPaisa(rs) {
  rs = rs.split(',').join("");
  rs = rs.split('.').join("");
  return rs;
}