import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { jsPDF } from "jspdf";
import { Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import html2canvas from "html2canvas";
import configureStore from "../../../../store/configureStore";
import { convertToRs } from "./moneyConverter";
import moment from "moment";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxWidth: 700,
    margin: "0 auto",
  },
  payslipHeading: {
    textAlign: "center",
    marginTop: 15,
  },
  payslipDes: {
    textTransform: "capitalize",
    paddingLeft: 15,
  },
  tableHeading: {
    background: "#dedede",
  },
  buttonRed: {
    marginTop: 15,
  },
}));

export default function Bill(props) {
  const details = props.billDetails;
  const companyDetails = props.companyDetails;
  console.log("companyDetails", companyDetails);

  const { activeOuid } = store.getState().permission;
  const party = details.party;
  console.log("props", props);
  console.log("details", details);
  console.log("party", party);
  const classes = useStyles();
  const doc = new jsPDF({
    orientation: "p",
    // unit: "px",
    unit: "pt",
    format: "letter",
  });
  let wantedTableWidth = 100;
  let pageWidth = doc.internal.pageSize.width;
  let margin = (pageWidth - wantedTableWidth) / 2;

  const pdfRef = useRef(null);
  var content = document.getElementById("billTable");
  console.log("content", content);
  window.html2canvas = html2canvas;

  function renderPurchaseItems(invLines) {
    if (invLines) {
      return invLines.map((item) => (
        <tr key={item.itemId}>
          <td className="tableitem">{item.itemName && item.itemName}</td>
          <td className="tableitem">{item.quantity && item.quantity}</td>
          <td className="tableitem">{item.rate && convertToRs(item.rate)}</td>
          <td className="tableitem">{item.tax && item.tax}%</td>
          <td className="tableitem">
            ₹{item.total && convertToRs(`${item.total}`)}
          </td>
        </tr>
      ));
    } else {
      return <>No invoice items found</>;
    }
  }

  function renderGrandTotalSum(invLines) {
    let grandTotalSum = 0;
    invLines.map(
      ({ total }) => (grandTotalSum = grandTotalSum + parseFloat(total))
    );
    console.log("grandTotalSum", grandTotalSum);
    grandTotalSum = convertToRs(`${grandTotalSum}`);
    grandTotalSum = grandTotalSum - (grandTotalSum * details.discount) / 100;
    console.log("grandTotalSum", grandTotalSum);
    return grandTotalSum;
  }

  function renderSubTotalSum(invLines) {
    let subTotalSum = 0;
    invLines.map(
      ({ total }) => (subTotalSum = subTotalSum + parseFloat(total))
    );
    console.log("subTotalSum", subTotalSum);
    subTotalSum = convertToRs(`${subTotalSum}`);
    return subTotalSum;
  }

  function renderSubTaxSum(invLines) {
    let subTaxSum = 0;
    invLines.map(
      ({ tax, rate, quantity }) =>
        (subTaxSum =
          subTaxSum +
          (parseFloat(tax) * parseFloat(rate) * parseFloat(quantity)) / 100)
    );
    console.log("subTaxSum", subTaxSum);
    subTaxSum = convertToRs(`${subTaxSum}`);
    return subTaxSum;
  }

  const handleDownload = () => {
    const content = pdfRef.current;
    doc.html(content, {
      callback: function (doc) {
        console.log("in callback");
        doc.save("bill.pdf");
      },
    });
  };
  return (
    <>
      <TableContainer
        component={Paper}
        id="billTable"
        className={classes.tableContainer}
        ref={pdfRef}
      >
        <div id="invoice-POS">
          <div className="bill_top_box">
            <div className="bill_from_address_wrap  mb">
              <div className="bill_from_address_name">{activeOuid}</div>
              <div className="bill_from_address_mobile">
                Mobile :{" "}
                {companyDetails.data && companyDetails.data.phoneNumber}
              </div>
              <div className="bill_from_address_details">
                Address : {companyDetails.data && companyDetails.data.address}
              </div>
              <div className="bill_from_address_details">
                GST :{companyDetails.data && companyDetails.data.GSTNumber}
              </div>
            </div>
            <div className="bill_enitiy_type">
              {details.entity === "salesinvoice" && <>Sales Invoice</>}

              {details.entity === "salesreturn" && <>Sales Return</>}
              {details.entity === "quotation" && <>Quotation</>}
              {details.entity === "creditnote" && <>Credit Note</>}
              {details.entity === "purchaseinvoice" && <>Purchase Invoice</>}

              {details.entity === "purchasereturn" && <>Purchase Return</>}
              {details.entity === "debitnote" && <>Debit Note </>}
              {details.entity === "purchaseorder" && <>Purchase Order </>}
            </div>
          </div>

          <ul className="bill_details_top_wrap">
            <li className="bill_details_top_item">
              {details && details.idNumber}
            </li>
            <li className="bill_details_top_item">
              invoice date:
              {moment(details && details.createdAt).format("DD-MM-YYYY")}
            </li>
            <li className="bill_details_top_item">
              due date:
              {moment(details && details.dueDate).format("DD-MM-YYYY")}
            </li>
          </ul>

          <div className="bill_to_address_wrap mt mb">
            <div className="bill_to_address_title">bill to</div>
            <div className="bill_to_address_name">{party && party.name}</div>
            <div className="bill_to_address_mobile">
              Mobile : {party && party.phone}
            </div>
            <div className="bill_to_address_details">
              Address :{party && party.businessInfo.billingAddress}
            </div>
            <div className="bill_to_address_details">
              GST :{party && party.businessInfo.gstNumber}
            </div>
          </div>
          <div id="table">
            <table>
              <thead className="bill_thead tabletitle">
                <tr>
                  <th className="item">Item</th>
                  <th className="Hours">Qty</th>
                  <th className="Hours">Rate</th>
                  <th className="Hours">Tax</th>
                  <th className="Rate">Sub Total</th>
                </tr>
              </thead>
              <tbody className="bill_tbody">
                {details.invLines && renderPurchaseItems(details.invLines)}
              </tbody>
              <tfoot className="bill_tfoot">
                <tr className="tabletitle">
                  <td>sub total</td>
                  <td>{details.invLines && details.invLines.length}</td>
                  <td></td>

                  <td className="Rate">
                    ₹{details.invLines && renderSubTaxSum(details.invLines)}
                  </td>
                  <td className="payment">
                    ₹{details.invLines && renderSubTotalSum(details.invLines)}
                  </td>
                </tr>
              </tfoot>
            </table>
            <ul className="bill_bottom_table_wrap">
              <li className="bill_bottom_table_item">
                <div className="bill_bottom_table_item_label">
                  taxable amount
                </div>
                <div className="bill_bottom_table_item_value">
                  ₹
                  {details.invLines && (
                    <>
                      {renderSubTotalSum(details.invLines) -
                        renderSubTaxSum(details.invLines)}
                    </>
                  )}
                </div>
              </li>
              <li className="bill_bottom_table_item">
                <div className="bill_bottom_table_item_label">CGST</div>
                <div className="bill_bottom_table_item_value">
                  {details.invLines && (
                    <>₹{renderSubTaxSum(details.invLines) / 2}</>
                  )}
                </div>
              </li>
              <li className="bill_bottom_table_item">
                <div className="bill_bottom_table_item_label">SGST</div>
                <div className="bill_bottom_table_item_value">
                  {details.invLines && (
                    <>₹{renderSubTaxSum(details.invLines) / 2}</>
                  )}
                </div>
              </li>
              <li className="bill_bottom_table_item">
                <div className="bill_bottom_table_item_label">
                  Discount ({details.discount && <>{details.discount}%</>})
                </div>
                <div className="bill_bottom_table_item_value">
                  ₹
                  {details.invLines &&
                    (renderSubTotalSum(details.invLines) * details.discount) /
                      100}
                </div>
              </li>
              <li className="bill_bottom_table_item">
                <div className="bill_bottom_table_item_label">grand total</div>
                <div className="bill_bottom_table_item_value">
                  ₹
                  {/* {details.invLines && renderSubTotalSum(details.invLines)} */}
                  {details.invLines && renderGrandTotalSum(details.invLines)}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </TableContainer>
      <div className="payslipDownloadContainer">
        {/* <img src={downloadImage} /> */}
        <Button
          // size="small"
          variant="contained"
          color="primary"
          className={classes.buttonRed}
          startIcon={<GetAppIcon />}
          onClick={() => handleDownload()}
        >
          Download Invoice
        </Button>
      </div>
    </>
    // <>{party && party.id}</>
  );
}
