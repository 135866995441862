import React, { useState } from "react";
import edit from "../images/edit.svg";
import dlt from "../images/delete.svg";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import configureStore from "../../../../store/configureStore";
import { Drawer, TextField } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { convertToRs } from "./moneyConverter";
import CurrencyTextField from "@unicef/material-ui-currency-textfield/dist/CurrencyTextField";
import { useEffect } from "react";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
  },
  input: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  Select: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
  formControl: {
    marginBottom: 10,
    background: "#fff",
    width: "100%",
  },
}));

function ItemsBillCard(props) {
  console.log("BillProps", props);
  console.log("bill.props.item.quantity", props.item.quantity);
  const classes = useStyles();
  const theme = useTheme();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [count, setCount] = React.useState(parseFloat(props.item.quantity));
  console.log("billcount", count);

  const itemIncrement = () => {
    setCount(count + 1);
  };

  const itemDecrement = () => {
    console.log("itemDecrement count ", count);
    if (count > 1) {
      setCount(count - 1);
    }
    if (count === 1) {
      setCount(1);
    }
  };

  function resetAllFilters() {
    setCount(parseFloat(props.item.quantity));
  }

  const handleDetele = () => {
    // console.log("props.item.username delete", props.item.id);
    // dispatch(delete_meeting(activeOuid, props.item.id));
  };
  var itemTotalTax = (props.item.tax * props.item.rate) / 100;
  console.log("itemTotalTax", itemTotalTax);
  var itemPrice = parseFloat(props.item.rate);
  var itemTotal = (itemTotalTax + itemPrice) * props.item.quantity;
  var itemTax = (props.item.tax * convertToRs(props.item.rate)) / 100;
  itemTax = itemTax * props.item.quantity;
  console.log("itemPrice", itemPrice);
  console.log("itemTotal", itemTotal);
  console.log("itemTax", itemTax);

  console.log("props.item.quantity", props.item.quantity);

  return (
    <div>
      <div className="item_bill_card_wrap mb">
        <div className="item_bill_card_top">
          <div className="item_bill_card_name">{props.item.itemName}</div>
          <div className="item_bill_card_price">
            ₹{convertToRs(`${itemTotal}`)}
          </div>
        </div>
        <div className="item_bill_card_details_wrapper">
          <div className="item_bill_card_details_wrap mb">
            <div className="item_bill_card_details_item">
              <div className="item_bill_card_details_item_label">
                Qty X Rate
              </div>
              <div className="item_bill_card_details_item_value">
                {props.item.quantity} X ₹
                {props.item.rate && convertToRs(props.item.rate)}
              </div>
            </div>
            <div className="item_bill_card_details_item">
              <div className="item_bill_card_details_item_label">TAX</div>
              <div className="item_bill_card_details_item_value">
                {props.item.tax && props.item.tax}% ={props.item.tax && itemTax}
              </div>
            </div>
          </div>
          <div className="item_bill_card_details_btns_wrap">
            <button
              onClick={() => {
                resetAllFilters();
                setIsDrawerOpen(true);
              }}
            >
              <img src={edit} />
            </button>
            <Drawer
              className="edit_option_btn_drawer"
              anchor="bottom"
              open={isDrawerOpen}
              onClose={() => {
                setIsDrawerOpen(false);
              }}
            >
              <div className="edit_option_head">
                Edit Item
                <button onClick={() => setIsDrawerOpen(false)}>
                  <CloseIcon />
                </button>
              </div>
              <div className="purchase_invoice_item_edit_wrap">
                <div className="purchase_invoice_item_edit_top mb">
                  <div>
                    <CurrencyTextField
                      id="rate"
                      label="Price"
                      variant="outlined"
                      className={classes.discountPriceTextField}
                      defaultValue={convertToRs(`${props.item.rate}`)}
                      // value={value}
                      currencySymbol="₹"
                      minimumValue="0"
                      outputFormat="string"
                      decimalCharacter="."
                      digitGroupSeparator=","
                      inputProps={{
                        className: classes.totalInput,
                      }}
                      onChange={(event) =>
                        props.handleEditChange(props.item.itemId, event)
                      }
                    />
                    {/* <TextField
                      id="rate"
                      label="Price"
                      type="number"
                      defaultValue={props.item.rate}
                      fullWidth
                      variant="outlined"
                      className={classes.input}
                      onChange={(event) =>
                        props.handleEditChange(props.item.itemId, event)
                      }
                    /> */}
                  </div>
                  <div>
                    <div className="purchase_invoice_item_edit_qty_label">
                      Qty
                    </div>
                    <div className="purchase_invoice_item_edit_qty_wrap">
                      <div className="purchase_invoice_item_edit_qty_value">
                        {/* {props.item.quantity} */}
                        {count}
                      </div>
                      <ButtonGroup>
                        <Button
                          disabled={props.item.quantity === 0}
                          aria-label="reduce"
                          onClick={() => {
                            itemDecrement();
                            props.decrement(props.item.itemId, count);
                          }}
                        >
                          <RemoveIcon fontSize="small" />
                        </Button>

                        <Button
                          aria-label="increase"
                          onClick={() => {
                            itemIncrement();
                            props.increment(props.item.itemId, count);
                          }}
                        >
                          <AddIcon fontSize="small" />
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
                {/* <TextField
                  id="name"
                  label="Discount"
                  de
                  fullWidth
                  variant="outlined"
                  className={classes.input}
                  onChange={props.handleEditChange}
                /> */}
                <button
                  className="blue_btn w-100"
                  onClick={() => setIsDrawerOpen(false)}
                >
                  SAVE
                </button>
              </div>
            </Drawer>
            <button onClick={() => props.handleDeleteitem(props.item.itemId)}>
              <img src={dlt} />
            </button>
          </div>
        </div>

        {/* <div className="item_bill_card_bottom">
          <div className="item_bill_card_bottom_label">Item Total</div>
          <div className="item_bill_card_total_price">₹ 1920.00</div>
        </div> */}
      </div>
    </div>
  );
}

export default ItemsBillCard;
