import React, { useEffect } from "react";
import InvoiceCard from "./common/InvoiceCard";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { get_debit_note } from "./actions/action-debitNote";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from "../../../reducers";
import AddIcon from "@material-ui/icons/Add";
import DebitNoteCard from "./common/DebitNoteCard";

const store = configureStore();

function DebitNoteList() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { debitNote, showGlobalLoader } = useSelector(
    (state) => state.debitNote
  );

  function renderList(debitNote) {
    if (debitNote) {
      return debitNote.map((item) => (
        <DebitNoteCard key={item.id} item={item} />
      ));
    } else {
      return <>No Debit Note Found</>;
    }
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_debit_note(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Debit Notes " />
      <div className=" HrmAppPage container--narrow mt FBAppPage">
        {showGlobalLoader ? <Loader /> : renderList(debitNote)}

        <div className="AppFloatingButtonWrapper t-r">
          <button
            className="round_btn bg_blue "
            onClick={() => history.push(`/${activeOuid}/create-debit-note`)}
          >
            <AddIcon />
          </button>
        </div>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}

export default DebitNoteList;
