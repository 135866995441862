import {
  CREATE_PARTIES_REQUESTED,
  CREATE_PARTIES_SUCCESS,
  CREATE_PARTIES_ERRORED,
  GET_PARTIES_REQUESTED,
  GET_PARTIES_SUCCESS,
  GET_PARTIES_ERRORED,
  GET_PARTIES_DETAILS_REQUESTED,
  GET_PARTIES_DETAILS_SUCCESS,
  GET_PARTIES_DETAILS_ERRORED,
  UPDATE_PARTIES_REQUESTED,
  UPDATE_PARTIES_SUCCESS,
  UPDATE_PARTIES_ERRORED,
  DELETE_PARTIES_REQUESTED,
  DELETE_PARTIES_SUCCESS,
  DELETE_PARTIES_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_parties_requested = () => ({
  type: CREATE_PARTIES_REQUESTED,
});

export const create_parties_success = () => ({
  type: CREATE_PARTIES_SUCCESS,
});

export const create_parties_errored = (error) => ({
  type: CREATE_PARTIES_ERRORED,
  error,
});

export const get_parties_requested = () => ({
  type: GET_PARTIES_REQUESTED,
});

export const get_parties_success = (payload) => ({
  type: GET_PARTIES_SUCCESS,
  payload,
});

export const get_parties_errored = (error) => ({
  type: GET_PARTIES_ERRORED,
  error,
});

export const get_parties_details_requested = () => ({
  type: GET_PARTIES_DETAILS_REQUESTED,
});

export const get_parties_details_success = (payload) => ({
  type: GET_PARTIES_DETAILS_SUCCESS,
  payload,
});

export const get_parties_details_errored = (error) => ({
  type: GET_PARTIES_DETAILS_ERRORED,
  error,
});

export const update_parties_requested = () => ({
  type: UPDATE_PARTIES_REQUESTED,
});

export const update_parties_success = (payload) => ({
  type: UPDATE_PARTIES_SUCCESS,
  payload,
});

export const update_parties_errored = (error) => ({
  type: UPDATE_PARTIES_ERRORED,
  error,
});
export const delete_parties_requested = () => ({
  type: DELETE_PARTIES_REQUESTED,
});

export const delete_parties_success = (payload) => ({
  type: DELETE_PARTIES_SUCCESS,
  payload,
});

export const delete_parties_errored = (error) => ({
  type: DELETE_PARTIES_ERRORED,
  error,
});

export const create_parties = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_parties_requested());
  console.log(`Create parties`);
  console.log("activeOuid", activeOuid);
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/parties`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("parties created successfully");
        dispatch(showSnackbar("parties created successfully", "success"));
        dispatch(create_parties_success(response));
        dispatch(get_parties("", activeOuid));
      })
      .catch((err) => {
        console.log("parties creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("parties creation Failed", "error"));
        dispatch(create_parties_errored(err));
      });
  }
  return null;
};

export const get_parties = (query, activeOuid) => async (dispatch) => {
  dispatch(get_parties_requested());
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`accountsApiEndpoint`, accountsApiEndpoint);
  console.log(`credentials`, credentials);

  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/parties${query}`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get parties Success", response.data);
        dispatch(get_parties_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get parties Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get parties Failed", "error"));
        }
        dispatch(get_parties_errored(err));
      });
  }
  return null;
};

export const get_parties_details =
  (partyId, activeOuid) => async (dispatch) => {
    dispatch(get_parties_details_requested());
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    console.log(`accountsApiEndpoint`, accountsApiEndpoint);
    console.log(`credentials`, credentials);

    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/parties/id/${partyId}`;

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get parties Success", response.data);
          dispatch(get_parties_details_success(response.data));
        })
        .catch((err) => {
          console.log("Get parties Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get parties Failed", "error"));
          }
          dispatch(get_parties_details_errored(err));
        });
    }
    return null;
  };

export const update_parties =
  (payload, partyId, activeOuid) => async (dispatch) => {
    dispatch(update_parties_requested());
    console.log(`update_parties`);

    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/parties/id/${partyId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("parties Updation Success");
          dispatch(showSnackbar("parties Updated Successfully", "success"));
          dispatch(update_parties_success());
        })
        .catch((err) => {
          console.log("parties updation Failed");
          dispatch(showSnackbar("parties updation Failed", "error"));
          dispatch(update_parties_errored(err));
        });
    }
    return null;
  };

export const delete_parties = (activeOuid, partyId) => async (dispatch) => {
  dispatch(delete_parties_requested());
  console.log(`delete_note`);
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/parties/id/${partyId}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Delete parties Success");
        dispatch(showSnackbar("parties deleted successfully", "success"));
        dispatch(delete_parties_success());
        dispatch(get_parties("", activeOuid));
      })
      .catch((err) => {
        if (err.response) {
          console.log(
            "Delete parties Failed :",
            JSON.stringify(err.response.data)
          );
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Delete parties", "error"));
        }
        dispatch(delete_parties_errored(err));
      });
  }
  return null;
};
