import React, { Component } from "react";
import { connect } from "react-redux";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import "./App.css";

//Orgunit
import Orgunit from "./components/pages/Orgunit/Orgunit";
import RegisterOrgunit from "./components/pages/Orgunit/RegisterOrgunit";
import EditOrgunitDetails from "./components/pages/Orgunit/EditOrgunitDetails";
import OrgunitDetails from "./components/pages/Orgunit/OrgunitDetails";

// Users
import OrgUsers from "./components/pages/UserManagement/OrgUsers";
import OrgUserDetails from "./components/pages/UserManagement/OrgUserDetails";
import Users from "./components/pages/UserManagement/Users";
import CreateUser from "./components/pages/UserManagement/CreateUser";
import UserDetails from "./components/pages/UserManagement/UserDetails";
import EditUser from "./components/pages/UserManagement/EditUser";

// Authentication
import { Auth } from "aws-amplify";
import LogIn from "./components/auth/LogIn";
import Register from "./components/auth/Register";

import ForgotPassword from "./components/auth/ForgotPassword";
import ForgotPasswordVerification from "./components/auth/ForgotPasswordVerification";
import ChangePassword from "./components/auth/ChangePassword";
import ChangePasswordConfirm from "./components/auth/ChangePasswordConfirm";

// Home
import VirtualCommunityApp from "./components/pages/VirtualCommunity";
import Dashboard from "./components/pages/Dashboard";

// Profile
import MyProfile from "./components/pages/MyProfile";
import Welcome from "./components/auth/Welcome";

import ThankYouPage from "./components/pages/ThankYouPage";
import { setJwt } from "../src/actions/action-jwt";
import { setAllPermissions } from "../src/actions/action-permissions";
import { history } from "./reducers";
import jwt from "jwt-decode";
import Help from "./components/pages/Help";

import PermissionError from "./components/pages/PermissionError";

import {
  userRoleCheck,
  permissionsCheck,
  getParentChain,
} from "../src/components/common/CommonFunctions";
import configureStore from "../src/store/configureStore";
import config from "../src/config";
import EditOrgUser from "./components/pages/UserManagement/EditOrgUser";

import {
  setActiveOuid,
  setActiveOuidChain,
} from "../src/actions/action-permissions";
import { setActiveOiudConfigs } from "../src/actions/action-config";
import CreateOrgUser from "./components/pages/UserManagement/CreateOrgUser";
import OrgPlan from "./components/pages/Orgunit/OrgPlan";

import ConfigDash from "./components/pages/Config/ConfigDash";

import { get_profile_details } from "../src/actions/action-myProfile";
import AccountingApp from "./components/pages/Accounting/Index";

const store = configureStore();

class App extends Component {
  state = {
    isAuthenticated: false,
    isAuthenticating: true,
    user: null,
  };

  setAuthStatus = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  setUser = (user) => {
    this.setState({ user: user });
  };

  async componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get("token")
    console.log("token : ", token);
    console.log("StageEnv : ", process.env);
    console.log("StageEnvConfig : ", config);
    var url = window.location.href;
    if (url.indexOf("?token=") !== -1 || url.indexOf("&token=") !== -1) {
      console.log("token exists");
      await this.setReduxUsingJwt();
      this.setState({ isAuthenticating: false });
      return;
    }
    const { setJwt, get_profile_details } = this.props;
    try {
      const session = await Auth.currentSession();
      this.setAuthStatus(true);
      console.log("currentSession", session);
      const user = await Auth.currentAuthenticatedUser();
      // console.log("currentSession user", JSON.stringify(user.username));
      this.setUser(user);
      const { setJwt, get_profile_details } = this.props;
      await setJwt(user);
      await get_profile_details(user.username);
      // jwt refresh intervel...........
      //this.refreshJwt();
      const idToken = user.signInUserSession.idToken;
      const decodedToken = jwt(idToken.jwtToken);
      const path = window.location.pathname.split("/");
      const urlOuid = path[1];
      await store.dispatch(setActiveOuid(urlOuid));
      if (decodedToken.policies) {
        const policies = decodedToken.policies.split(";");
        let teamPolicies = [];
        if (decodedToken.teamPolicies !== "")
          teamPolicies = decodedToken.teamPolicies.split(";");
        let appPolicies = [];
        if (decodedToken.appPolicies !== "")
          appPolicies = decodedToken.appPolicies.split(";");
        const allPolicy = {
          policies,
          teamPolicies,
          appPolicies,
        };
        const { setAllPermissions } = this.props;
        await setAllPermissions(allPolicy);
        const userRole = await userRoleCheck(policies);
        if (!urlOuid) {
          const { userRoles } = store.getState().permission;
          await store.dispatch(setActiveOuid(userRoles[0].ouid));
          await store.dispatch(setActiveOuidChain(userRoles[0].ouid));
          await store.dispatch(setActiveOiudConfigs(userRoles[0].ouid));
        } else {
          await store.dispatch(setActiveOuidChain(urlOuid));
          await store.dispatch(setActiveOiudConfigs(urlOuid));
        }
        if (this.state.redirection) {
          this.props.history.push(this.state.redirection);
        } else {
          let activeOuid = store.getState().permission.activeOuid;
          this.props.history.push(`/${activeOuid}/crm`);
        }
      } else {
        this.props.history.push(`/system/user-home`);
      }
    } catch (error) {
      console.log(error);
      if (error === "No current user") {
        history.push(`/login`);
      }
    }
    this.setState({ isAuthenticating: false });
  }

  componentWillUnmount() {
    console.log("APP unmounted");
    clearInterval(this.state.intervalId);
  }
  setReduxUsingJwt = async () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let jwtToken = params.token;
    const decodedToken = jwt(jwtToken);
    console.log("decodedToken", decodedToken);
    const username = decodedToken["cognito:username"];
    console.log("username : ", username);
    const { setJwt, get_profile_details } = this.props;

    if (username) {
      console.log("before my profile set.....................................");
      console.log("store.jwt", store.getState().jwt);
      var userSession = {
        signInUserSession: {
          idToken: {
            jwtToken: jwtToken,
          },
        },
      };
      await setJwt(userSession);
      await get_profile_details(username);

      if (!store.getState().myProfile.details.data) {
        console.log("my profile not set, so directing to login");
        history.push(`/login`);
        return;
      }
    }

    this.setAuthStatus(true);
    const path = window.location.pathname.split("/");
    const urlOuid = path[1];
    if (urlOuid) {
      console.log("urlOuid", urlOuid);

      await store.dispatch(setActiveOuid(urlOuid));
    }
    if (decodedToken.policies) {
      const policies = decodedToken.policies.split(";");
      let teamPolicies = [];
      if (decodedToken.teamPolicies !== "")
        teamPolicies = decodedToken.teamPolicies.split(";");
      let appPolicies = [];
      if (decodedToken.appPolicies !== "")
        appPolicies = decodedToken.appPolicies.split(";");
      const allPolicy = {
        policies,
        teamPolicies,
        appPolicies,
      };
      const {
        setAllPermissions,
        setActiveOuid,
        setActiveOuidChain,
        setActiveOiudConfigs,
      } = this.props;

      await setAllPermissions(allPolicy);
      console.log("policies", policies);
      console.log("this.props   ..........", this.props);
      await userRoleCheck(policies);
      if (!urlOuid) {
        console.log("no urlOuid");
        const { userRoles } = store.getState().permission;
        console.log("userRoles", userRoles);
        console.log("userRoles[0].ouid", userRoles[0].ouid);

        await store.dispatch(setActiveOuid(userRoles[0].ouid));
        console.log("setActiveOuid done");
      } else {
        await store.dispatch(setActiveOuidChain(urlOuid));
        await store.dispatch(setActiveOiudConfigs(urlOuid));
      }
      console.log("set setActiveOuid bits..");
      if (this.state.redirection) {
        this.props.history.push(this.state.redirection);
      } else if (urlOuid) {
        let activeOuid = store.getState().permission.activeOuid;
        this.props.history.push(`/${activeOuid}/ecom`);
      } else {
        console.log("push to /");
        let activeOuid = store.getState().permission.activeOuid;
        //history.push(`/${activeOuid}/hrm`);
        history.push(`/`);
      }
    }
  };
  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser,
    };
    const { userRoles } = store.getState().permission;
    return (
      !this.state.isAuthenticating && (
        <div className="App">
          <Router history={history}>
            {/* <Sidebar auth={authProps} /> */}
            <Switch>
              {/* Auth */}
              <Route
                exact
                path="/login"
                render={(props) => <LogIn {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/register"
                render={(props) => <Register {...props} auth={authProps} />}
              />

              <Route
                exact
                path="/forgotpassword"
                render={(props) => (
                  <ForgotPassword {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/forgotpasswordverification"
                render={(props) => (
                  <ForgotPasswordVerification {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/changepassword"
                render={(props) => (
                  <ChangePassword {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/changepasswordconfirmation"
                render={(props) => (
                  <ChangePasswordConfirm {...props} auth={authProps} />
                )}
              />
              <Route
                exact
                path="/welcome"
                render={(props) => <Welcome {...props} auth={authProps} />}
              />
              {/* Auth */}

              {/* Orgunit */}
              <Route
                exact
                path="/:id/register-orgunit"
                render={(props) =>
                  permissionsCheck(userRoles, "CreateOrgunit", "orgunit") ? (
                    <RegisterOrgunit {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/orgunits"
                render={(props) =>
                  permissionsCheck(userRoles, "ListOrgunit", "orgunit") ? (
                    <Orgunit {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />

              <Route
                exact
                path="/:id/orgunit/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "ViewOrgunit", "orgunit") ? (
                    <OrgunitDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/edit-orgunit/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "EditOrgunit", "orgunit") ? (
                    <EditOrgunitDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/orgplan"
                render={(props) =>
                  permissionsCheck(userRoles, "OrgPlan", "orgunit") ? (
                    <OrgPlan {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id"
                render={(props) => <VirtualCommunityApp {...props} />}
              />

              {/* Config */}
              <Route
                exact
                path="/:id/config-dash"
                render={(props) =>
                  permissionsCheck(userRoles, "ConfigDash", "config") ? (
                    <ConfigDash {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              {/* Config */}

              {/* User */}
              <Route
                exact
                path="/:id/orgusers"
                render={(props) =>
                  permissionsCheck(userRoles, "ListOrgUsers", "orgUser") ? (
                    <OrgUsers {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/orguser/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "ViewOrgUser", "orgUser") ? (
                    <OrgUserDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/edit-orguser/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "EditOrgUser", "orgUser") ? (
                    <EditOrgUser {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/users"
                render={(props) =>
                  permissionsCheck(userRoles, "ListUsers", "authUser") ? (
                    <Users {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/create-user"
                render={(props) =>
                  permissionsCheck(userRoles, "CreateUser", "authUser") ? (
                    <CreateUser {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/create-org-user"
                render={(props) =>
                  permissionsCheck(userRoles, "CreateUser", "authUser") ? (
                    <CreateOrgUser {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/user/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "ViewUser", "authUser") ? (
                    <UserDetails {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/edit-user/:id"
                render={(props) =>
                  permissionsCheck(userRoles, "EditUser", "authUser") ? (
                    <EditUser {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/parties"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/items"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/create-invoice"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/create-payment-received"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/create-item"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/create-party"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/create-sales-return"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/create-credit-note"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/create-quote"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/create-purchase-invoice"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/purchase-items"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/create-payment-out"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/create-purchase-return"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/create-debit-note"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/create-purchase-order"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/expense"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/delivery-chalan"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/performa-invoice"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/counter-pos"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/sales-invoices"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/purchase-invoices"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/quotations"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/purchase-orders"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/credit-notes"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/debit-notes"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/sales-returns"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/purchase-returns"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/payments-received"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/payments-out"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/sales-invoice-details/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/edit-sales-invoice/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/purchase-invoice-details/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/edit-purchase-invoice/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/payment-received-details/:id"
                render={(props) => <AccountingApp {...props} />}
              />

              <Route
                exact
                path="/:id/payment-out-details/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/sales-return-details/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/edit-sales-return/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/quotation-details/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/edit-quotation/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/edit-credit-note/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/credit-note-details/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/purchase-return-details/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/edit-purchase-return/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/debit-note-details/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/edit-debit-note/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/purchase-order-details/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/edit-purchase-order/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/parties-details/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/edit-parties/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/item-details/:id"
                render={(props) => <AccountingApp {...props} />}
              />
              <Route
                exact
                path="/:id/edit-item/:id"
                render={(props) => <AccountingApp {...props} />}
              />

              {/* User */}

              <Route
                exact
                path="/:id/dashboard"
                render={(props) =>
                  permissionsCheck(
                    userRoles,
                    "ViewDashboard",
                    "commonPages"
                  ) ? (
                    <Dashboard {...props} auth={authProps} />
                  ) : (
                    <PermissionError {...props} auth={authProps} />
                  )
                }
              />
              <Route
                exact
                path="/:id/my-profile"
                render={(props) => <MyProfile {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/help"
                render={(props) => <Help {...props} auth={authProps} />}
              />
              <Route
                exact
                path="/thankyou"
                render={(props) => <ThankYouPage {...props} auth={authProps} />}
              />
              {/* <Route exact path="/permission-error" render={(props) => <PermissionError {...props} auth={authProps} />} /> */}
              {/* <Route exact path="/404" render={(props) => <PageNotFound />} />
              <Redirect from="*" to={"/404"} /> */}
            </Switch>
          </Router>
        </div>
      )
    );
  }
}

const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = (dispatch) => ({
  setJwt: (user) => dispatch(setJwt(user)),
  setAllPermissions: (policies) => dispatch(setAllPermissions(policies)),
  get_profile_details: (username) => dispatch(get_profile_details(username)),
  setActiveOuid: (ouid) => dispatch(setActiveOuid(ouid)),
  setActiveOuidChain: (ouid) => dispatch(setActiveOuidChain(ouid)),
  setActiveOiudConfigs: (ouid) => dispatch(setActiveOiudConfigs(ouid))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
