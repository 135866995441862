import React, { useEffect } from "react";
import ItemsCard from "./common/ItemsCard";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import { history } from "../../../reducers";
import configureStore from "../../../store/configureStore";
import { get_items } from "./actions/action-items";
import { useDispatch, useSelector } from "react-redux";
import CommonSnackBar from "../../common/CommonSnackBar";
import Loader from "../../common/Loader";

const store = configureStore();

export default function Items() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { items, showGlobalLoader } = useSelector((state) => state.items);
  console.log("items", items);

  function renderAllItems(items) {
    console.log("itemslist", items);
    if (items) {
      return items.map((item) => <ItemsCard key={item.id} item={item} />);
    } else {
      return <>No items found</>;
    }
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_items("", activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Items " />
      <div className=" HrmAppPage container--narrow mt FBAppPage">
        {showGlobalLoader ? <Loader /> : renderAllItems(items)}

        <div className="AppFloatingButtonWrapper t-c">
          <button
            className="btn bg_light_green"
            style={{ width: "80%", margin: "0 auto" }}
            onClick={() => history.push(`/${activeOuid}/create-item`)}
          >
            add new item
          </button>
        </div>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}
