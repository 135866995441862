import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import item from "../images/item.png";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import configureStore from "../../../../store/configureStore";
import { Avatar } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { convertToRs } from "./moneyConverter";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: "uppercase",
  },
}));

export default function ItemsCard(props) {
  const classes = useStyles();
  const [count, setCount] = React.useState(0);
  console.log("count", count);
  console.log("props.item.quanity", props.item.quanity);
  const { activeOuid } = store.getState().permission;

  const itemIncrement = () => {
    setCount(count + 1);
  };

  const itemDecrement = () => {
    if (count > 0) setCount(count - 1);
    else {
      setCount(0);
      alert("min limit reached");
    }
  };
  return (
    <>
      <div className="item_card_wrap mb">
        <div className="item_card_img">
          {/* <img src={props.item.featuredImage} /> */}
          <Avatar
            className={classes.avatar}
            style={{
              backgroundColor: "#214B87",
            }}
            variant="square"
          >
            {props.item.name && props.item.name.charAt(0)}
          </Avatar>
        </div>
        <div className="item_card_content_wrap">
          {props.item.name && (
            <Link
              to={`/${activeOuid}/item-details/${props.item.id}`}
              className="item_card_name"
            >
              {props.item.name}
            </Link>
          )}

          <div className="item_card_content_child">
            <div className="item_card_details_wrap">
              <div className="item_card_details_item">
                Purchase Price :
                <span className="item_card_details_item_value">
                  ₹
                  {props.item.pricing &&
                    convertToRs(props.item.pricing.purchasePrice)}
                </span>
              </div>
              <div className="item_card_details_item">
                Sales Price :
                <span className="item_card_details_item_value">
                  ₹
                  {props.item.pricing &&
                    convertToRs(props.item.pricing.salesPrice)}
                </span>
              </div>
              {props.stock && (
                <div className="item_card_details_item">
                  Stock :
                  <span className="item_card_details_item_value">
                    {props.item.pricing && props.item.pricing.currentStock}
                  </span>
                </div>
              )}

              {props.cartCountBtn && (
                <div className="item_card_details_item">
                  Quantity :
                  <span className="item_card_details_item_value">{count}</span>
                </div>
              )}
            </div>
            {props.deleteBtn && (
              <div className="item_card_delete_wrap">
                <DeleteIcon />
              </div>
            )}
          </div>
          <div className="qty_btn_wrap">
            {props.cartCountBtn && (
              <>
                {count === 0 && (
                  // <button
                  //   className="qty_primary_add_btn"
                  //   onClick={() => {
                  //     cartIncrement();
                  //   }}
                  //   onChange={props.handleInvoiceItemChange}
                  // >
                  //   Add +
                  // </button>
                  <button
                    className="qty_primary_add_btn"
                    onClick={() => {
                      itemIncrement();
                      props.handleAdditem(props.item, count);
                      props.increment(props.item.id, count);
                    }}
                  >
                    Add +
                  </button>
                )}
                {count > 0 && (
                  <ButtonGroup>
                    <Button
                      disabled={count === 0}
                      aria-label="reduce"
                      onClick={() => {
                        itemDecrement();
                        props.decrement(props.item.id, count);
                      }}
                    >
                      <RemoveIcon fontSize="small" />
                    </Button>

                    <Button
                      aria-label="increase"
                      onClick={() => {
                        itemIncrement();
                        props.increment(props.item.id, count);
                      }}
                    >
                      <AddIcon fontSize="small" />
                    </Button>
                  </ButtonGroup>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
