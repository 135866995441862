import React, { useEffect } from "react";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import configureStore from "../../../store/configureStore";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from "../../../reducers";
import AddIcon from "@material-ui/icons/Add";
import {
  get_transaction,
  reset_transaction,
} from "./actions/action-transactions";
import TransactionCard from "./common/TransactionCard";

const store = configureStore();

function PaymentOutList() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { transactions, showGlobalLoader } = useSelector(
    (state) => state.transactions
  );

  function renderTransactionList(transactions) {
    if (transactions) {
      return transactions.map((item) => (
        <TransactionCard key={item.id} item={item} />
      ));
    } else {
      return <>No transactions found</>;
    }
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(reset_transaction());
      await dispatch(get_transaction("?type=debit", activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Payments Out " />
      <div className=" HrmAppPage container--narrow mt FBAppPage">
        {showGlobalLoader ? <Loader /> : renderTransactionList(transactions)}
      </div>
      <div className="AppFloatingButtonWrapper t-r">
        <button
          className="round_btn bg_blue "
          onClick={() => history.push(`/${activeOuid}/create-payment-out`)}
        >
          <AddIcon />
        </button>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}

export default PaymentOutList;
