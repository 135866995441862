import React from "react";
import { convertToRs } from "./moneyConverter";

function DrawerInvoiceCard(props) {
  return (
    <>
      <div
        className="parties_card_wrap mb"
        onClick={() => {
          props.setIsInvoiceDrawerOPen(false);

          props.selectInvoice(props.item);
        }}
      >
        <div className="parties_card_details">
          <div className="parties_card_name">{props.item.idNumber}</div>
          <div className="parties_card_label">29 Aug</div>
        </div>
        <div>
          <div className="parties_card_amount clr_green">
            ₹ {props.item.totalAmount && convertToRs(`${props.item.totalAmount}`)}
          </div>
          {props.item.paymentStatus === "PENDING" && (
            <div className="invoice_card_payment_status clr_red">
              {props.item.paymentStatus && props.item.paymentStatus}
            </div>
          )}
          {props.item.paymentStatus === "COMPLETED" && (
            <div className="invoice_card_payment_status clr_green">
              {props.item.paymentStatus && props.item.paymentStatus}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default DrawerInvoiceCard;
