import React from "react";
import ItemsCard from "./common/ItemsCard";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import { history } from "../../../reducers";
import configureStore from "../../../store/configureStore";
import CommonSnackBar from "../../common/CommonSnackBar";

const store = configureStore();

function PurchaseItems() {
  const { activeOuid } = store.getState().permission;

  function renderAllItems() {
    return <ItemsCard cartCountBtn={true} />;
  }

  return (
    <div>
      <HeaderInner pageTitle="ADD ITEMS " />
      <div className=" HrmAppPage container--narrow mt FBAppPage">
        {renderAllItems()}

        <div className="AppFloatingButtonWrapper t-c">
          <button
            className="blue_btn "
            style={{ width: "90%", margin: "0 auto" }}
            onClick={() => history.push(`/${activeOuid}/create-invoice`)}
          >
            Done
          </button>
        </div>
      </div>
      <Footer />
      <CommonSnackBar />
    </div>
  );
}

export default PurchaseItems;
