import React, { Component } from 'react';
import { connect } from 'react-redux';
import SidebarWrapper from '../layout/SidebarWrapper';
import configureStore from '../../store/configureStore';
import banner from '../assets/images/bannerimg.png';


const store = configureStore();

class Dashboard extends Component {
  render() {
    const { permission } = store.getState().permission;
    return (
      <div className="page">
        <SidebarWrapper
          auth={this.props.auth}
          permission={permission}
        />
        <section className="hero is-primary">
          <div className="hero-body">
            <div>
              <img src={banner} alt="banner" />
            </div>
            <div >
              
            </div>
          </div>
        </section>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  permission: state.permission,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);