import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import DownloadPdf from "./DownloadPdf";
import { convertToRs } from "./moneyConverter";


const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxWidth: 700,
    margin: "0 auto",
  },
  payslipHeading: {
    textAlign: "center",
    marginTop: 15,
  },
  payslipDes: {
    textTransform: "capitalize",
    paddingLeft: 15,
  },
  tableHeading: {
    background: "#dedede",
  },
  buttonRed: {
    marginTop: 15,
  },
}));

const NewBill = ({ billDetails, companyDetails }) => {
  const classes = useStyles();
  const details = billDetails;
  const party = details.party;

  function renderGrandTotalSum(invLines) {
    let grandTotalSum = 0;
    invLines.map(
      ({ total }) => (grandTotalSum = grandTotalSum + parseFloat(total))
    );
    console.log("grandTotalSum", grandTotalSum);
    grandTotalSum = convertToRs(`${grandTotalSum}`);
    grandTotalSum = grandTotalSum - (grandTotalSum * details.discount) / 100;
    console.log("grandTotalSum", grandTotalSum);
    return grandTotalSum;
  }

  function renderSubTotalSum(invLines) {
    let subTotalSum = 0;
    invLines.map(
      ({ total }) => (subTotalSum = subTotalSum + parseFloat(total))
    );
    console.log("subTotalSum", subTotalSum);
    subTotalSum = convertToRs(`${subTotalSum}`);
    return subTotalSum;
  }

  function renderSubTaxSum(invLines) {
    let subTaxSum = 0;
    invLines.map(
      ({ tax, rate, quantity }) =>
        (subTaxSum =
          subTaxSum +
          (parseFloat(tax) * parseFloat(rate) * parseFloat(quantity)) / 100)
    );
    console.log("subTaxSum", subTaxSum);
    subTaxSum = convertToRs(`${subTaxSum}`);
    return subTaxSum;
  }

  return (
    <>
      <TableContainer
        component={Paper}
        id="billTable"
        className={classes.tableContainer}
      >
        <div id="invoice-POS">
          <div className="bill_top_box">
            <div className="bill_from_address_wrap mb">
              <div className="bill_from_address_name">{companyDetails.data?.name}</div>
              <div className="bill_from_address_mobile">Mobile: {companyDetails.data?.phoneNumber}</div>
              <div className="bill_from_address_details">Address: {companyDetails.data?.address}</div>
              <div className="bill_from_address_details">GST: {companyDetails.data?.GSTNumber}</div>
            </div>
            <div className="bill_enitiy_type">
              {details.entity === "salesinvoice" && <>Sales Invoice</>}
              {details.entity === "salesreturn" && <>Sales Return</>}
              {details.entity === "quotation" && <>Quotation</>}
              {details.entity === "creditnote" && <>Credit Note</>}
              {details.entity === "purchaseinvoice" && <>Purchase Invoice</>}
              {details.entity === "purchasereturn" && <>Purchase Return</>}
              {details.entity === "debitnote" && <>Debit Note</>}
              {details.entity === "purchaseorder" && <>Purchase Order</>}
            </div>
          </div>

          <ul className="bill_details_top_wrap">
            <li className="bill_details_top_item">{details.idNumber}</li>
            <li className="bill_details_top_item">Invoice date: {moment(details.createdAt).format("DD-MM-YYYY")}</li>
            <li className="bill_details_top_item">Due date: {moment(details.dueDate).format("DD-MM-YYYY")}</li>
          </ul>

          <div className="bill_to_address_wrap mt mb">
            <div className="bill_to_address_title">Bill to</div>
            <div className="bill_to_address_name">{party?.name}</div>
            <div className="bill_to_address_mobile">Mobile: {party?.phone}</div>
            <div className="bill_to_address_details">Address: {party?.businessInfo?.billingAddress}</div>
            <div className="bill_to_address_details">GST: {party?.businessInfo?.gstNumber}</div>
          </div>

          <table>
            <thead className="bill_thead tabletitle">
              <tr>
                <th className="item">Item</th>
                <th className="Hours">Qty</th>
                <th className="Hours">Rate</th>
                <th className="Hours">Tax</th>
                <th className="Rate">Sub Total</th>
              </tr>
            </thead>
            <tbody className="bill_tbody">
              {details.invLines && details.invLines.map((item, index) => (
                <tr key={index}>
                  <td className="tableitem">{item.itemName && item.itemName}</td>
                  <td className="tableitem">{item.quantity && item.quantity}</td>
                  <td className="tableitem">{item.rate && convertToRs(item.rate)}</td>
                  <td className="tableitem">{item.tax && item.tax}%</td>
                  <td className="tableitem">₹{item.total && convertToRs(`${item.total}`)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot className="bill_tfoot">
                <tr className="tabletitle">
                  <td>sub total</td>
                  <td>{details.invLines && details.invLines.length}</td>
                  <td></td>

                  <td className="Rate">
                    ₹{details.invLines && renderSubTaxSum(details.invLines)}
                  </td>
                  <td className="payment">
                    ₹{details.invLines && renderSubTotalSum(details.invLines)}
                  </td>
                </tr>
              </tfoot>
          </table>

          <ul className="bill_bottom_table_wrap">
              <li className="bill_bottom_table_item">
                <div className="bill_bottom_table_item_label">
                  taxable amount
                </div>
                <div className="bill_bottom_table_item_value">
                  ₹
                  {details.invLines && (
                    <>
                      {renderSubTotalSum(details.invLines) -
                        renderSubTaxSum(details.invLines)}
                    </>
                  )}
                </div>
              </li>
              <li className="bill_bottom_table_item">
                <div className="bill_bottom_table_item_label">CGST</div>
                <div className="bill_bottom_table_item_value">
                  {details.invLines && (
                    <>₹{renderSubTaxSum(details.invLines) / 2}</>
                  )}
                </div>
              </li>
              <li className="bill_bottom_table_item">
                <div className="bill_bottom_table_item_label">SGST</div>
                <div className="bill_bottom_table_item_value">
                  {details.invLines && (
                    <>₹{renderSubTaxSum(details.invLines) / 2}</>
                  )}
                </div>
              </li>
              <li className="bill_bottom_table_item">
                <div className="bill_bottom_table_item_label">
                  Discount ({details.discount && <>{details.discount}%</>})
                </div>
                <div className="bill_bottom_table_item_value">
                  ₹
                  {details.invLines &&
                    (renderSubTotalSum(details.invLines) * details.discount) /
                      100}
                </div>
              </li>
              <li className="bill_bottom_table_item">
                <div className="bill_bottom_table_item_label">grand total</div>
                <div className="bill_bottom_table_item_value">
                  ₹
                  {/* {details.invLines && renderSubTotalSum(details.invLines)} */}
                  {details.invLines && renderGrandTotalSum(details.invLines)}
                </div>
              </li>
            </ul>
        </div>
      </TableContainer>
      <div style={{  marginTop: '20px' }}>
        <DownloadPdf billDetails={billDetails} companyDetails={companyDetails} />
      </div>
    </>
  );
};

export default NewBill;
