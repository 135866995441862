import {
  CREATE_PURCHASE_ORDER_REQUESTED,
  CREATE_PURCHASE_ORDER_SUCCESS,
  CREATE_PURCHASE_ORDER_ERRORED,
  GET_PURCHASE_ORDER_REQUESTED,
  GET_PURCHASE_ORDER_SUCCESS,
  GET_PURCHASE_ORDER_ERRORED,
  GET_PURCHASE_ORDER_DETAILS_REQUESTED,
  GET_PURCHASE_ORDER_DETAILS_SUCCESS,
  GET_PURCHASE_ORDER_DETAILS_ERRORED,
  UPDATE_PURCHASE_ORDER_REQUESTED,
  UPDATE_PURCHASE_ORDER_SUCCESS,
  UPDATE_PURCHASE_ORDER_ERRORED,
  DELETE_PURCHASE_ORDER_REQUESTED,
  DELETE_PURCHASE_ORDER_SUCCESS,
  DELETE_PURCHASE_ORDER_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  purchaseOrder: [],
  purchaseOrderDetails: {},
  error: {},
};

export function purchaseOrder(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_PURCHASE_ORDER_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_PURCHASE_ORDER_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_PURCHASE_ORDER_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        purchaseOrder: payload,
        showGlobalLoader: false,
      };

    case GET_PURCHASE_ORDER_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_PURCHASE_ORDER_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_PURCHASE_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        purchaseOrderDetails: payload,
        showGlobalLoader: false,
      };

    case GET_PURCHASE_ORDER_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_PURCHASE_ORDER_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        purchaseOrderDetails: payload,
      };

    case UPDATE_PURCHASE_ORDER_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_PURCHASE_ORDER_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_PURCHASE_ORDER_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    default:
      return state;
  }
}
