import React from "react";
import { Link } from "react-router-dom";
import configureStore from "../../../../store/configureStore";

const store = configureStore();

export default function PartiesCard(props) {
  const { activeOuid } = store.getState().permission;

  return (
    <>
      <Link
        to={`/${activeOuid}/parties-details/${props.item.id}`}
        className="parties_card_wrap mb"
      >
        <div className="parties_card_details">
          <div className="parties_card_name">{props.item.name}</div>
          <div className="parties_card_label">{props.item.type}</div>
        </div>
        {/* <div className="parties_card_amount clr_green">₹ 3000</div> */}
      </Link>
    </>
  );
}
