import {
  CREATE_QUOTATION_REQUESTED,
  CREATE_QUOTATION_SUCCESS,
  CREATE_QUOTATION_ERRORED,
  GET_QUOTATION_REQUESTED,
  GET_QUOTATION_SUCCESS,
  GET_QUOTATION_ERRORED,
  GET_QUOTATION_DETAILS_REQUESTED,
  GET_QUOTATION_DETAILS_SUCCESS,
  GET_QUOTATION_DETAILS_ERRORED,
  UPDATE_QUOTATION_REQUESTED,
  UPDATE_QUOTATION_SUCCESS,
  UPDATE_QUOTATION_ERRORED,
  DELETE_QUOTATION_REQUESTED,
  DELETE_QUOTATION_SUCCESS,
  DELETE_QUOTATION_ERRORED,
} from "../constant/action-types";

import axios from "axios";

import { showSnackbar } from "../../../../actions/action-snackbar";

import configureStore from "../../../../store/configureStore";

const store = configureStore();

export const create_quotation_requested = () => ({
  type: CREATE_QUOTATION_REQUESTED,
});

export const create_quotation_success = () => ({
  type: CREATE_QUOTATION_SUCCESS,
});

export const create_quotation_errored = (error) => ({
  type: CREATE_QUOTATION_ERRORED,
  error,
});

export const get_quotation_requested = () => ({
  type: GET_QUOTATION_REQUESTED,
});

export const get_quotation_success = (payload) => ({
  type: GET_QUOTATION_SUCCESS,
  payload,
});

export const get_quotation_errored = (error) => ({
  type: GET_QUOTATION_ERRORED,
  error,
});

export const get_quotation_details_requested = () => ({
  type: GET_QUOTATION_DETAILS_REQUESTED,
});

export const get_quotation_details_success = (payload) => ({
  type: GET_QUOTATION_DETAILS_SUCCESS,
  payload,
});

export const get_quotation_details_errored = (error) => ({
  type: GET_QUOTATION_DETAILS_ERRORED,
  error,
});

export const update_quotation_requested = () => ({
  type: UPDATE_QUOTATION_REQUESTED,
});

export const update_quotation_success = (payload) => ({
  type: UPDATE_QUOTATION_SUCCESS,
  payload,
});

export const update_quotation_errored = (error) => ({
  type: UPDATE_QUOTATION_ERRORED,
  error,
});
export const delete_quotation_requested = () => ({
  type: DELETE_QUOTATION_REQUESTED,
});

export const delete_quotation_success = (payload) => ({
  type: DELETE_QUOTATION_SUCCESS,
  payload,
});

export const delete_quotation_errored = (error) => ({
  type: DELETE_QUOTATION_ERRORED,
  error,
});

export const create_quotation = (payload, activeOuid) => async (dispatch) => {
  dispatch(create_quotation_requested());
  console.log(`Create quotation`);
  console.log("activeOuid", activeOuid);
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/quotations`;
    const method = "post";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, payload, axiosHeader)
      .then((response) => {
        console.log("quotation created successfully");
        dispatch(showSnackbar("quotation created successfully", "success"));
        dispatch(create_quotation_success(response));
        dispatch(get_quotation(activeOuid));
      })
      .catch((err) => {
        console.log("quotation creation Failed");
        // console.error('ErrorLog : ', JSON.stringify(err));
        dispatch(showSnackbar("quotation creation Failed", "error"));
        dispatch(create_quotation_errored(err));
      });
  }
  return null;
};

export const get_quotation = (activeOuid) => async (dispatch) => {
  dispatch(get_quotation_requested());
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  console.log(`accountsApiEndpoint`, accountsApiEndpoint);
  console.log(`credentials`, credentials);

  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/quotations`;

    const method = "get";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Get quotation Success", response.data);
        dispatch(get_quotation_success(response.data.items));
      })
      .catch((err) => {
        console.log("Get quotation Failed");
        if (err.response) {
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Get quotation Failed", "error"));
        }
        dispatch(get_quotation_errored(err));
      });
  }
  return null;
};

export const get_quotation_details =
  (quotationId, activeOuid) => async (dispatch) => {
    dispatch(get_quotation_details_requested());
    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    console.log(`accountsApiEndpoint`, accountsApiEndpoint);
    console.log(`credentials`, credentials);

    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/quotations/id/${quotationId}`;

      const method = "get";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
        },
      };
      return axios[method](url, axiosHeader)
        .then((response) => {
          console.log("Get quotation Success", response.data);
          dispatch(get_quotation_details_success(response.data));
        })
        .catch((err) => {
          console.log("Get quotation Failed");
          if (err.response) {
            var data = err.response.data;
            dispatch(showSnackbar(data.error, "error"));
          } else {
            dispatch(showSnackbar("Get quotation Failed", "error"));
          }
          dispatch(get_quotation_details_errored(err));
        });
    }
    return null;
  };

export const update_quotation =
  (payload, quotationId, activeOuid) => async (dispatch) => {
    dispatch(update_quotation_requested());
    console.log(`update_quotation`);

    const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
    if (accountsApiEndpoint && credentials) {
      const jwt = `${credentials.idToken.jwtToken}`;
      let url = `${accountsApiEndpoint}/quotations/id/${quotationId}`;
      const { activeOuid, activeOuidChain } = store.getState().permission;
      const method = "put";
      const axiosHeader = {
        headers: {
          Authorization: jwt,
          "x-ouid": activeOuid,
          "x-ouidchain": activeOuidChain,
        },
      };
      return axios[method](url, payload, axiosHeader)
        .then((response) => {
          console.log("quotation Updation Success");
          dispatch(showSnackbar("quotation Updated Successfully", "success"));
          dispatch(update_quotation_success());
        })
        .catch((err) => {
          console.log("quotation updation Failed");
          dispatch(showSnackbar("quotation updation Failed", "error"));
          dispatch(update_quotation_errored(err));
        });
    }
    return null;
  };

export const delete_quotation = (activeOuid, meetingId) => async (dispatch) => {
  dispatch(delete_quotation_requested());
  console.log(`delete_note`);
  const { accountsApiEndpoint, credentials } = store.getState().jwt.api;
  if (accountsApiEndpoint && credentials) {
    const jwt = `${credentials.idToken.jwtToken}`;
    let url = `${accountsApiEndpoint}/quotations/id/${meetingId}`;
    const method = "delete";
    const axiosHeader = {
      headers: {
        Authorization: jwt,
        "x-ouid": activeOuid,
      },
    };
    return axios[method](url, axiosHeader)
      .then((response) => {
        console.log("Delete quotation Success");
        dispatch(showSnackbar("quotation deleted successfully", "success"));
        dispatch(delete_quotation_success());
        dispatch(get_quotation("", activeOuid));
      })
      .catch((err) => {
        if (err.response) {
          console.log(
            "Delete quotation Failed :",
            JSON.stringify(err.response.data)
          );
          var data = err.response.data;
          dispatch(showSnackbar(data.error, "error"));
        } else {
          dispatch(showSnackbar("Delete quotation", "error"));
        }
        dispatch(delete_quotation_errored(err));
      });
  }
  return null;
};
