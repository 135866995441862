import {
  CREATE_CREDIT_NOTE_REQUESTED,
  CREATE_CREDIT_NOTE_SUCCESS,
  CREATE_CREDIT_NOTE_ERRORED,
  GET_CREDIT_NOTE_REQUESTED,
  GET_CREDIT_NOTE_SUCCESS,
  GET_CREDIT_NOTE_ERRORED,
  GET_CREDIT_NOTE_DETAILS_REQUESTED,
  GET_CREDIT_NOTE_DETAILS_SUCCESS,
  GET_CREDIT_NOTE_DETAILS_ERRORED,
  UPDATE_CREDIT_NOTE_REQUESTED,
  UPDATE_CREDIT_NOTE_SUCCESS,
  UPDATE_CREDIT_NOTE_ERRORED,
  DELETE_CREDIT_NOTE_REQUESTED,
  DELETE_CREDIT_NOTE_SUCCESS,
  DELETE_CREDIT_NOTE_ERRORED,
} from "../constant/action-types";

const defaultState = {
  showGlobalLoader: false,
  creditNote: [],
  creditNoteDetails: {},
  error: {},
};

export function creditNote(state = defaultState, { type, payload }) {
  switch (type) {
    case CREATE_CREDIT_NOTE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case CREATE_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case CREATE_CREDIT_NOTE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_CREDIT_NOTE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        creditNote: payload,
        showGlobalLoader: false,
      };

    case GET_CREDIT_NOTE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case GET_CREDIT_NOTE_DETAILS_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case GET_CREDIT_NOTE_DETAILS_SUCCESS:
      return {
        ...state,
        creditNoteDetails: payload,
        showGlobalLoader: false,
      };

    case GET_CREDIT_NOTE_DETAILS_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    case UPDATE_CREDIT_NOTE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case UPDATE_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
        creditNoteDetails: payload,
      };

    case UPDATE_CREDIT_NOTE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };
    case DELETE_CREDIT_NOTE_REQUESTED:
      return {
        ...state,
        showGlobalLoader: true,
      };

    case DELETE_CREDIT_NOTE_SUCCESS:
      return {
        ...state,
        showGlobalLoader: false,
      };

    case DELETE_CREDIT_NOTE_ERRORED:
      return {
        ...state,
        showGlobalLoader: false,
        error: payload,
      };

    default:
      return state;
  }
}
