import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import item from "../images/item.png";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import configureStore from "../../../../store/configureStore";
import { Avatar } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { convertToRs } from "./moneyConverter";

const store = configureStore();

const useStyles = makeStyles((theme) => ({
  avatar: {
    textTransform: "uppercase",
  },
}));

export default function InvoiceItemsCard(props) {
  console.log("props", props);
  const classes = useStyles();
  const [count, setCount] = React.useState(parseFloat(props.item.quantity));
  console.log("props.item.quantity", props.item.quantity);
  console.log("props.item.name", props.item.itemName);
  const { activeOuid } = store.getState().permission;

  const itemIncrement = () => {
    setCount(count + 1);
  };

  const itemDecrement = () => {
    console.log("itemDecrement count ", count);
    if (count > 1) {
      setCount(count - 1);
    }
    if (count === 1) {
      setCount(1);
    }
  };
  return (
    <>
      <div className="item_card_wrap mb">
        <div className="item_card_img">
          {/* <img src={props.item.featuredImage} /> */}
          <Avatar
            className={classes.avatar}
            style={{
              backgroundColor: "#214B87",
            }}
            variant="square"
          >
            {props.item.itemName && props.item.itemName.charAt(0)}
          </Avatar>
        </div>
        <div className="item_card_content_wrap">
          {props.item.itemName && (
            <div className="item_card_name">{props.item.itemName}</div>
          )}

          <div className="item_card_content_child">
            <div className="item_card_details_wrap">
              <div className="item_card_details_item">
                Price :
                <span className="item_card_details_item_value">
                  ₹{props.item.rate && convertToRs(props.item.rate)}
                </span>
              </div>

              {props.item.quantity > 0 && (
                <div className="item_card_details_item">
                  Quantity :
                  <span className="item_card_details_item_value">
                    {props.item.quantity}
                  </span>
                </div>
              )}
            </div>
            {props.deleteBtn && (
              <div className="item_card_delete_wrap">
                <DeleteIcon />
              </div>
            )}
          </div>
          <div className="qty_btn_wrap">
            {props.item.quantity === 0 && (
              // <button
              //   className="qty_primary_add_btn"
              //   onClick={() => {
              //     cartIncrement();
              //   }}
              //   onChange={props.handleInvoiceItemChange}
              // >
              //   Add +
              // </button>
              <button
                className="qty_primary_add_btn"
                onClick={() => {
                  itemIncrement();
                  props.handleAdditem(props.item, count);
                  // props.increment(props.item.itemId, count);
                }}
              >
                Add +
              </button>
            )}
            {props.item.quantity > 0 && (
              <ButtonGroup>
                <Button
                  disabled={props.item.quantity === 1}
                  aria-label="reduce"
                  onClick={() => {
                    itemDecrement();
                    props.decrement(props.item.itemId, count);
                  }}
                >
                  <RemoveIcon fontSize="small" />
                </Button>

                <Button
                  aria-label="increase"
                  onClick={() => {
                    itemIncrement();
                    props.increment(props.item.itemId, count);
                  }}
                >
                  <AddIcon fontSize="small" />
                </Button>
              </ButtonGroup>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
