import React from "react";
import { Link } from "react-router-dom";
import configureStore from "../../../../store/configureStore";
import { convertToRs } from "./moneyConverter";
import moment from "moment";

const store = configureStore();

function PurchaseOrderCard(props) {
  const { activeOuid } = store.getState().permission;

  return (
    <>
      <Link
        to={`/${activeOuid}/purchase-order-details/${props.item.id}`}
        className="parties_card_wrap mb"
      >
        <div className="parties_card_details">
          <div className="parties_card_name">{props.item.party.name}</div>
          <div className="parties_card_label">{props.item.idNumber}</div>
          {moment(props.item.createdAt).format("DD/MM/YYYY")}

          {props.item.paymentStatus === "PENDING" && (
            <div className="invoice_card_payment_status clr_red">
              {props.item.paymentStatus && props.item.paymentStatus}
            </div>
          )}
          {props.item.paymentStatus === "COMPLETED" && (
            <div className="invoice_card_payment_status clr_green">
              {props.item.paymentStatus && props.item.paymentStatus}
            </div>
          )}

          <div className="parties_card_label">{props.item.party.type}</div>
        </div>
        <div className="parties_card_amount clr_green">
          ₹ {convertToRs(props.item.totalAmount && props.item.totalAmount)}
        </div>
      </Link>
    </>
  );
}

export default PurchaseOrderCard;
