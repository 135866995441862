import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get_org_user_details, update_org_user_profile } from '../../../actions/action-orguser';
import CommonSnackBar from "../../common/CommonSnackBar";
import { history } from '../../../reducers';
import SidebarWrapper from '../../layout/SidebarWrapper';
import configureStore from '../../../store/configureStore';
import FormErrors from "../../FormErrors";
import Input from '@material-ui/core/Input';
import FormButton from '../../common/FormButton';
import Loader from '../../common/Loader';
import PolicyCard from '../../common/PolicyCard';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Paper, Button } from '@material-ui/core';
import { showSnackbar, hideSnackbar } from '../../../actions/action-snackbar';
import { get_all_app_user, delete_app_user } from '../../../actions/action-appuser';
import { setActiveOuid } from "../../../actions/action-permissions";
import AppPolicyCard from '../../common/AppPolicyCard';
import { create_app_user } from '../../../actions/action-appuser';

const store = configureStore();


class EditOrgUser extends Component {

    constructor() {
        super();
        this.state = {
            userDetails: {},
            roles: [],
            oldAppUserProfiles: [],
            application: '',
            applicationRole: [],
            appRoleMappings: [],
        };
    }

    componentDidMount() {
        if (!this.props.auth.isAuthenticated) {
            const path = window.location.pathname;
            history.push(`/login?redirect=${path}`);
            return
        }
        const { activeOuid } = store.getState().permission;
        if (activeOuid) {
            this.fetchDetails(activeOuid);
        } else {
            this.fetchDetailsBasedOnUrl();
        }
    }

    async fetchDetails(activeOuid) {
        const path = window.location.pathname.split('/');
        const username = path[path.length - 1];
        const { get_org_user_details } = this.props;
        await get_org_user_details(username, activeOuid);
        const { get_all_app_user } = this.props;
        await get_all_app_user(username, activeOuid);
        const { orgUserDetails } = store.getState().orgUserProfile;
        const { appUserProfiles } = store.getState().appUserProfile;
        this.setState({
            userDetails: orgUserDetails,
            roles: orgUserDetails.data.roles,
            oldAppUserProfiles: appUserProfiles
        });
    }

    async fetchDetailsBasedOnUrl() {
        console.log("fetchDetailsBasedOnUrl");
        const path = window.location.pathname.split('/');
        const urlOuid = path[1];
        console.log("urlOuid : ", urlOuid);
        const username = path[path.length - 1];
        const { get_org_user_details } = this.props;
        await get_org_user_details(username, urlOuid);
        const { get_all_app_user } = this.props;
        await get_all_app_user(username, urlOuid);
        const { orgUserDetails } = store.getState().orgUserProfile;
        const { oldAppUserProfiles } = store.getState().appUserProfile;
        this.setState({
            userDetails: orgUserDetails,
            roles: orgUserDetails.data.roles,
            oldAppUserProfiles: oldAppUserProfiles
        }, () => {
            store.dispatch(setActiveOuid(urlOuid));
        });
    }

    clearErrorState = () => {
        this.setState({
            errors: {}
        });
    }

    onInputChange = event => {
        const newUserDetails = this.state.userDetails;
        newUserDetails.data[event.target.id] = event.target.value;
        this.setState({
            userDetails: newUserDetails
        });
    }

    deletePolicy = async index => {
        console.log("deletePolicy : ", index);
        let roles = this.state.roles;
        roles.splice(index, 1);
        this.setState({
            roles: roles
        });
    }

    roleChange = event => {
        var newRoles = this.state.roles;
        if (newRoles.includes(event.target.value)) {
            store.dispatch(showSnackbar('Role Already Added', 'error'));
        } else {
            newRoles.push(event.target.value);
            this.setState({
                roles: newRoles
            });
        }
    }

    renderOrgUnitRoles = coreConfig => {
        const config = coreConfig.data.config;
        return config.roles.map((item, index) => (
            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
        ));
    }

    appChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    appRoleChange = event => {
        var newRoles = this.state.applicationRole;
        newRoles[0] = event.target.value;
        this.setState({
            applicationRole: newRoles
        });
    }

    renderApplicationList = coreConfig => {
        const config = coreConfig.data.config;
        return config.applications.map((item, index) => (
            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
        ));
    }


    renderApplicationRoles = application => {
        const { activeOuidConfigs } = store.getState().config;
        const applicationConfig = activeOuidConfigs.find(({ data }) => data.identifier === application)
        return applicationConfig.data.config.roles.map((item, index) => (
            <MenuItem key={item.value} value={item.value}>{item.name}</MenuItem>
        ));
    }


    addAppRole() {
        const oldAppUserProfiles = this.state.oldAppUserProfiles;
        const application = this.state.application;
        const appRole = this.state.applicationRole;
        const newAppRoleMappings = this.state.appRoleMappings;
        if (application && appRole.length !== 0) {
            const appExist = newAppRoleMappings.find(({ appId }) => appId === application);
            const appPolicyExistInOldData = oldAppUserProfiles.find(({ data }) => data.appId === application);
            if (appExist) {
                store.dispatch(showSnackbar("Application Alredy Exist In Policy", "error"));
            } else if (appPolicyExistInOldData) {
                store.dispatch(showSnackbar("Application Alredy Exist In Old Profile", "error"));
            }
            else {
                let policy = {
                    "appId": application,
                    "roles": appRole,
                }
                newAppRoleMappings.push(policy);
                this.setState({
                    appRoleMappings: newAppRoleMappings,
                    application: '',
                    applicationRole: [],
                }, () => {

                });
            }
        } else {
            store.dispatch(showSnackbar("No Application Role Selected", "error"));
        }
    }

    async deleteAppRole(index) {
        const path = window.location.pathname.split('/');
        const ouid = path[1];
        const applicationDetails = this.state.oldAppUserProfiles[index];
        console.log("deleteAppRole  ::: ", applicationDetails);
        const appId = applicationDetails.data.appId
        const username = applicationDetails.data.username
        const { delete_app_user } = this.props;
        await delete_app_user(username, ouid, appId);
        window.location.reload();
    }

    deleteAppRoleFormMapping(index) {
        const newAppRoleMappings = this.state.appRoleMappings;
        newAppRoleMappings.splice(index, 1);
        this.setState({
            appRoleMappings: newAppRoleMappings,
        }, () => {

        });
    }


    renderApplicationPolicy(context, data) {
        return data.map((item, index) => (
            <AppPolicyCard
                context={context}
                policy={item}
                index={index}
                editable
                deletePolicy={(index) => context === "detailsPage" ? this.deleteAppRole(index) : this.deleteAppRoleFormMapping(index)}
            />
        ));
    }


    async handleSubmit() {
        const path = window.location.pathname.split('/');
        const username = path[path.length - 1];
        const { update_org_user_profile } = this.props;
        let userDetails = this.state.userDetails;
        const { activeOuid } = store.getState().permission;
        // app user creation
        if (this.state.appRoleMappings.length > 0) {
            const { create_app_user } = this.props;
            this.state.appRoleMappings.map(async (item, index) => {
                let appUserDetails = {};
                appUserDetails["username"] = this.state.userDetails.data.username;
                appUserDetails["roles"] = item.roles;
                await create_app_user(appUserDetails, activeOuid, item.appId);
            });
        }
        await update_org_user_profile(userDetails, username, activeOuid);
        // this.props.history.push(`/${activeOuid}/orgusers`);
        window.location.reload();
    }


    render() {
        const { showGlobalLoader } = store.getState().orgUserProfile;
        const { permissions } = store.getState().permission;
        const { activeOuidConfigs } = store.getState().config;
        const coreConfig = activeOuidConfigs.find(({ data }) => data.identifier === 'core')
        return (
            <div className="page">
                <SidebarWrapper
                    auth={this.props.auth}
                    permissions={permissions}
                />
                <section className="section">
                    {showGlobalLoader ?
                        <Loader />
                        :
                        <div className="container">
                            {this.state.userDetails.data && (
                                <div className="formContainer">
                                    <FormErrors formerrors={this.state.errors} />
                                    <form>
                                        <h1 className="formName">Edit Org User Details</h1>
                                        <div className="form-item-wrapper">
                                            <Input
                                                className="input"
                                                id="name"
                                                placeholder="Full Name"
                                                value={this.state.userDetails.data.name}
                                                onChange={this.onInputChange}
                                            />
                                        </div>
                                        <div className="form-item-wrapper">
                                            <Input
                                                className="input"
                                                id="username"
                                                placeholder="User Name"
                                                value={this.state.userDetails.data.username}
                                                onChange={this.onInputChange}
                                                readOnly
                                            />
                                        </div>
                                        <div className="form-item-wrapper">
                                            <Input
                                                className="input"
                                                placeholder="Email"
                                                id="email"
                                                value={this.state.userDetails.data.email}
                                                onChange={this.onInputChange}
                                                readOnly
                                            />
                                        </div>
                                        <div className="form-item-wrapper">
                                            <Input
                                                className="input"
                                                id="phone_number"
                                                placeholder="Phone Number"
                                                value={this.state.userDetails.data.phone_number}
                                                onChange={this.onInputChange}
                                                readOnly
                                            />
                                        </div>
                                        {this.state.roles && (
                                            <div>
                                                {this.state.roles.map((item, index) => (
                                                    <PolicyCard key={index}
                                                        policy={item}
                                                        index={index}
                                                        deletePolicy={(index) => this.deletePolicy(index)}
                                                        editable
                                                    />
                                                ))
                                                }
                                            </div>
                                        )}
                                        {this.state.roles.length === 0 && (
                                            <div>
                                                <FormControl style={{ minWidth: 120 }}>
                                                    <InputLabel id="demo-simple-select-label">Role</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={this.state.orgUnitRole}
                                                        onChange={this.roleChange}
                                                    >
                                                        {this.renderOrgUnitRoles(coreConfig)}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        )}

                                        {this.state.oldAppUserProfiles ?
                                            this.renderApplicationPolicy("detailsPage", this.state.oldAppUserProfiles)
                                            :
                                            <></>
                                        }

                                    </form>


                                    <Paper component="div" >
                                        {this.state.appRoleMappings.length !== 0 ?
                                            this.renderApplicationPolicy("createPage", this.state.appRoleMappings)
                                            :
                                            <></>
                                        }
                                        <div className="formContainer">
                                            <form>
                                                <div className="form-item-wrapper">
                                                    <InputLabel id="application-select-label">
                                                        Applications
                                                    </InputLabel>
                                                    <Select
                                                        labelId="role-select-label"
                                                        id="application"
                                                        name="application"
                                                        value={this.state.application || ''}
                                                        onChange={this.appChange}
                                                        className="FormInput"
                                                    >
                                                        {this.renderApplicationList(coreConfig)}
                                                    </Select>


                                                    {this.state.application && (
                                                        <>
                                                            <InputLabel id="application-select-label">
                                                                Application Role
                                                            </InputLabel>
                                                            <Select
                                                                labelId="role-select-label"
                                                                id="applicationRole"
                                                                name="applicationRole"
                                                                value={this.state.applicationRole[0] || ''}
                                                                onChange={this.appRoleChange}
                                                                className="FormInput"
                                                            >
                                                                {this.renderApplicationRoles(this.state.application)}
                                                            </Select>
                                                        </>
                                                    )}
                                                </div>
                                                <Button variant="contained" color="primary" onClick={() => this.addAppRole()}>
                                                    Add Role
                                                </Button>
                                            </form>
                                        </div>
                                    </Paper>

                                    <div className="field">
                                        <p className="control">
                                            <FormButton
                                                onClick={() => this.handleSubmit()}
                                                text="Update"
                                            />
                                        </p>
                                    </div>

                                </div>

                            )}

                        </div>
                    }
                    <CommonSnackBar />
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    orgUserProfile: state.orgUserProfile,
    appUserProfile: state.appUserProfile,
    permission: state.permission,
});


const mapDispatchToProps = (dispatch) => ({
    get_org_user_details: (username, activeOuid) => dispatch(get_org_user_details(username, activeOuid)),
    get_all_app_user: (username, activeOuid) => dispatch(get_all_app_user(username, activeOuid)),
    delete_app_user: (username, activeOuid, appid) => dispatch(delete_app_user(username, activeOuid, appid)),
    update_org_user_profile: (payload, username, activeOuid) => dispatch(update_org_user_profile(payload, username, activeOuid)),
    create_app_user: (payload, activeOuid, appId) => dispatch(create_app_user(payload, activeOuid, appId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditOrgUser);


