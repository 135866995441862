import React from "react";
import { useEffect } from "react";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import Bill from "./common/Bill";
import { history } from "../../../reducers";
import Loader from "../../common/Loader";
import { get_orgunit_details } from "../../../actions/action-orgunit";
import { get_purchase_order_details } from "./actions/action-purchaseOrder";
import NewBill from "./common/NewBill";

const store = configureStore();

function PurchaseOrderDetails() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { purchaseOrderDetails, showGlobalLoader } = useSelector(
    (state) => state.purchaseOrder
  );
  const { OrgunitDetails } = useSelector((state) => state.orgunit);

  useEffect(() => {
    let purchaseOrderId = window.location.pathname.split("/");
    purchaseOrderId = purchaseOrderId[3];
    console.log("purchaseOrderId", purchaseOrderId);

    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_purchase_order_details(purchaseOrderId, activeOuid));
      await dispatch(get_orgunit_details(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Purchase Order Details" />
      <div className="AppPage container--narrow mt FBAppPage">
        <button
          className="bill_edit_btn mb"
          onClick={() =>
            history.push(
              `/${activeOuid}/edit-purchase-order/${purchaseOrderDetails.id}`
            )
          }
        >
          Edit
        </button>
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <>
            <NewBill
              billDetails={purchaseOrderDetails}
              companyDetails={OrgunitDetails}
            />
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default PurchaseOrderDetails;
