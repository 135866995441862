import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import HeaderInner from "./HeaderInner";
import { useDispatch, useSelector } from "react-redux";
import configureStore from "../../../store/configureStore";
import Bill from "./common/Bill";
import { get_sales_invoice_details } from "./actions/action-salesInvoice";
import { history } from "../../../reducers";
import Loader from "../../common/Loader";
import { get_orgunit_details } from "../../../actions/action-orgunit";
import DownloadPdf from "./common/DownloadPdf";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { IconButton } from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import NewBill from "./common/NewBill";


const store = configureStore();


export default function SalesInvoiceInner() {
  const dispatch = useDispatch();
  const { activeOuid } = store.getState().permission;
  const { salesInvoiceDetails, showGlobalLoader } = useSelector(
    (state) => state.salesInvoice
  );
  const { OrgunitDetails } = useSelector((state) => state.orgunit);
  const [downloadClicked, setDownloadClicked] = useState(false);

  const handleDownloadPDF = () => {
    setDownloadClicked(true);
    console.log("is hadleDownload clicked");
  };

  useEffect(() => {
    console.log("inuseEffect");
    let invoiceId = window.location.pathname.split("/");
    invoiceId = invoiceId[3];
    console.log("invoiceId", invoiceId);
    const fetchData = async (dispatch) => {
      await dispatch(get_sales_invoice_details(invoiceId, activeOuid));
      await dispatch(get_orgunit_details(activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <HeaderInner pageTitle="Sales Invoice Details" />
      <div className="AppPage container--narrow mt FBAppPage">
        <button
          className="bill_edit_btn mb"
          onClick={() =>
            history.push(
              `/${activeOuid}/edit-sales-invoice/${salesInvoiceDetails.id}`
            )
          }
        >
          Edit
        </button>
        {showGlobalLoader ? (
          <Loader />
        ) : (
          <>
            
    <NewBill
    billDetails={salesInvoiceDetails}
    companyDetails={OrgunitDetails}/>
              
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}
