import React, { useEffect, useState } from "react";
import Header from "./Header";
import "./App.css";
import TransactionCard from "./common/TransactionCard";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { Drawer } from "@material-ui/core";
import invoice from "./images/invoice.svg";
import PaymentRecieved from "./images/recive.svg";
import salesReturn from "./images/sales-return.svg";
import creditNote from "./images/credit-note.svg";
import estimate from "./images/estimate.svg";
import deliveryChalan from "./images/delivery-chalan.svg";
import performaInvoice from "./images/performa-invoice.svg";
import counterPos from "./images/counter-pos.svg";
import Purchase from "./images/purchase.svg";
import paymentOut from "./images/payment-out.svg";
import purchaseReturn from "./images/purchase-return.svg";
import debitNote from "./images/debit-note.svg";
import purchaseOrder from "./images/purchase-order.svg";
import expense from "./images/expense.svg";
import configureStore from "../../../store/configureStore";
import { get_transaction } from "./actions/action-transactions";
import { useDispatch, useSelector } from "react-redux";

const store = configureStore();

export default function Home() {
  const dispatch = useDispatch();
  const [isDrawerOpen, setIsDrawerOPen] = useState(false);
  const { activeOuid, activeOuidChain } = store.getState().permission;
  const { transactions, showGlobalLoader } = useSelector(
    (state) => state.transactions
  );

  function renderTransactionList(transactions) {
    if (transactions) {
      return transactions.map((item) => (
        <TransactionCard key={item.id} item={item} />
      ));
    } else {
      return <>No transactions found</>;
    }
  }

  useEffect(() => {
    console.log("inuseEffect");
    const fetchData = async (dispatch) => {
      await dispatch(get_transaction("", activeOuid));
    };
    fetchData(dispatch);
  }, [dispatch]);

  return (
    <div>
      <Header />
      <div className="AppHomePage AppPage container--narrow FBAppPage">
        <div className="w_box mb">
          <div className="option_head">Income / Expense</div>
          <ul className="list_pages_wrap">
            <li className="list_pages_item">
              <Link to={`/${activeOuid}/payments-received`}>
                <img src={PaymentRecieved} />
              </Link>
              <p className="AppLabel">Receive</p>
            </li>
            <li className="list_pages_item">
              <Link to={`/${activeOuid}/payments-out`}>
                <img src={paymentOut} />
              </Link>
              <p className="AppLabel">Pay</p>
            </li>
          </ul>
        </div>
        <div className="w_box mb">
          <div className="option_head">Sales Transaction</div>
          <ul className="list_pages_wrap">
            <li className="list_pages_item">
              <Link to={`/${activeOuid}/sales-invoices`}>
                <img src={invoice} />
              </Link>
              <p className="AppLabel">Invoices</p>
            </li>

            <li className="list_pages_item">
              <Link to={`/${activeOuid}/sales-returns`}>
                <img src={salesReturn} />
              </Link>
              <p className="AppLabel">sales Returns</p>
            </li>
            <li className="list_pages_item">
              <Link to={`/${activeOuid}/credit-notes`}>
                <img src={creditNote} />
              </Link>
              <p className="AppLabel">credit Notes</p>
            </li>
            <li className="list_pages_item">
              <Link to={`/${activeOuid}/quotations`}>
                <img src={estimate} />
              </Link>
              <p className="AppLabel">Quotations</p>
            </li>
            {/* <li
              className="list_pages_item"
              
            >
              <Link to={`/${activeOuid}/delivery-chalan`}>
                <img src={deliveryChalan} />
              </Link>
              <p className="AppLabel">Delivery Chalan</p>
            </li>
            <li
              className="list_pages_item"
              
            >
              <Link to={`/${activeOuid}/performa-invoice`}>
                <img src={performaInvoice} />
              </Link>
              <p className="AppLabel">performa Invoice</p>
            </li>
            <li
              className="list_pages_item"
             
            >
              <Link to={`/${activeOuid}/counter-pos`}>
                <img src={counterPos} />
              </Link>
              <p className="AppLabel">counter POS</p>
            </li> */}
          </ul>
        </div>
        <div className="w_box mb">
          <div className="option_head">Purchase Transaction</div>
          <ul className="list_pages_wrap">
            <li className="list_pages_item">
              <Link to={`/${activeOuid}/purchase-invoices`}>
                <img src={Purchase} />
              </Link>
              <p className="AppLabel">Purchase Invoices</p>
            </li>
            <li className="list_pages_item">
              <Link to={`/${activeOuid}/purchase-returns`}>
                <img src={purchaseReturn} />
              </Link>
              <p className="AppLabel">purchase Returns</p>
            </li>
            <li className="list_pages_item">
              <Link to={`/${activeOuid}/debit-notes`}>
                <img src={debitNote} />
              </Link>
              <p className="AppLabel">debit Notes</p>
            </li>
            <li className="list_pages_item">
              <Link to={`/${activeOuid}/purchase-orders`}>
                <img src={purchaseOrder} />
              </Link>
              <p className="AppLabel">purchase Orders</p>
            </li>
          </ul>
        </div>

        {/* <ul className="accounting_status_report_wrap mb">
          <li className="accounting_status_report_item mb">
            <Link to="/">
              <div className="accounting_status_main">₹ 79,85,664</div>
              <div className="clr_green accounting_status_sub ">To Pay</div>
            </Link>
          </li>
          <li className="accounting_status_report_item mb">
            <Link to="/">
              <div className="accounting_status_main">₹ 79,85,664</div>
              <div className="clr_red accounting_status_sub ">To Collect</div>
            </Link>
          </li>
          <li className="accounting_status_report_item mb">
            <Link to="/">
              <div className="accounting_status_main">₹ 79,85,664</div>
              <div className="clr_violet accounting_status_sub ">
                Total Balance
              </div>
            </Link>
          </li>
          <li className="accounting_status_report_item  ">
            <Link to="/">
              <div className="accounting_status_main">Stock Value</div>
              <div className="clr_grey accounting_status_sub ">Items Value</div>
            </Link>
          </li>
          <li className="accounting_status_report_item  ">
            <Link to="/">
              <div className="accounting_status_main">₹ 0</div>
              <div className="clr_grey accounting_status_sub ">
                This week Sales
              </div>
            </Link>
          </li>
          <li className="accounting_status_report_item  ">
            <Link to="/">
              <div className="accounting_status_main">reports</div>
              <div className="clr_grey accounting_status_sub ">
                Sales, Party, GS
              </div>
            </Link>
          </li>
        </ul> */}

        <div className="primary_title">Transaction</div>
        {renderTransactionList(transactions)}
      </div>
      <div className="AppFloatingButtonWrapper">
        <button
          className="round_btn bg_blue float-r"
          onClick={() => setIsDrawerOPen(true)}
        >
          <AddIcon />
        </button>
        <Drawer
          className="create_option_drawer"
          anchor="bottom"
          open={isDrawerOpen}
          onClose={() => setIsDrawerOPen(false)}
        >
          <div className="create_option_drawer_child">
            <div className="create_drawer_top">
              <button
                className="round_btn bg_blue "
                onClick={() => setIsDrawerOPen(false)}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="create_option_drawer_items_wrapper">
              <div className="option_head">Income / Expense</div>
              <ul className="edit_option_tools_wrap">
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <Link to={`/${activeOuid}/create-payment-received`}>
                    <img src={PaymentRecieved} />
                  </Link>
                  <p className="AppLabel">Receive</p>
                </li>
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <Link to={`/${activeOuid}/create-payment-out`}>
                    <img src={paymentOut} />
                  </Link>
                  <p className="AppLabel">Pay</p>
                </li>
              </ul>
              <div className="option_head">Sales Transaction</div>
              <ul className="edit_option_tools_wrap">
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <Link to={`/${activeOuid}/create-invoice`}>
                    <img src={invoice} />
                  </Link>
                  <p className="AppLabel">Invoice</p>
                </li>

                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <Link to={`/${activeOuid}/create-sales-return`}>
                    <img src={salesReturn} />
                  </Link>
                  <p className="AppLabel">sales Return</p>
                </li>
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <Link to={`/${activeOuid}/create-credit-note`}>
                    <img src={creditNote} />
                  </Link>
                  <p className="AppLabel">credit Note</p>
                </li>
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <Link to={`/${activeOuid}/create-quote`}>
                    <img src={estimate} />
                  </Link>
                  <p className="AppLabel">Quotation</p>
                </li>
                {/* <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <Link to={`/${activeOuid}/delivery-chalan`}>
                    <img src={deliveryChalan} />
                  </Link>
                  <p className="AppLabel">Delivery Chalan</p>
                </li>
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <Link to={`/${activeOuid}/performa-invoice`}>
                    <img src={performaInvoice} />
                  </Link>
                  <p className="AppLabel">performa Invoice</p>
                </li>
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <Link to={`/${activeOuid}/counter-pos`}>
                    <img src={counterPos} />
                  </Link>
                  <p className="AppLabel">counter POS</p>
                </li> */}
              </ul>
              <div className="option_head">Purchase Transaction</div>
              <ul className="edit_option_tools_wrap">
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <Link to={`/${activeOuid}/create-purchase-invoice`}>
                    <img src={Purchase} />
                  </Link>
                  <p className="AppLabel">Purchase Invoice</p>
                </li>

                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <Link to={`/${activeOuid}/create-purchase-return`}>
                    <img src={purchaseReturn} />
                  </Link>
                  <p className="AppLabel">purchase Return</p>
                </li>
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <Link to={`/${activeOuid}/create-debit-note`}>
                    <img src={debitNote} />
                  </Link>
                  <p className="AppLabel">debit Note</p>
                </li>
                <li
                  className="edit_option_tools_item"
                  onClick={() => setIsDrawerOPen(false)}
                >
                  <Link to={`/${activeOuid}/create-purchase-order`}>
                    <img src={purchaseOrder} />
                  </Link>
                  <p className="AppLabel">purchase Order</p>
                </li>
              </ul>
            </div>
          </div>
        </Drawer>
      </div>
      <Footer />
    </div>
  );
}
